import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import './fileDetailSidebar.css';

const FileDetailSidebar = ({ visible, onClose, fileDetails }) => {
  
  const topicMapping = {
      "overview": [
          "script_purpose", "domain", "recommendation", "summary_all",
          "summary_code_quality", "summary_functionality", "summary_perf_scal", "summary_security",
          "summary_compatibility", "summary_documentation", "summary_standards", "is_script", "is_script_explanation"
      ],
      "code_quality": [
          "readability", "consistency", "modularity", "maintainability", "reusability", 
          "redundancy", "technical_debt", "code_smells",
      ],
      "functionality": [
          "completeness", "edge_cases", "error_handling"
      ],
      "performance_scalability": [
          "efficiency", "scalability", "resource_utilization", "load_handling", 
          "parallel_processing", "database_interaction_efficiency", "concurrency_management", 
          "state_management_efficiency", "modularity_decoupling", "configuration_customization_ease"
      ],
      "security": [
          "input_validation", "data_handling", "authentication",
          "package_dependencies", "flag_color", "reasons_of_flag"
      ],
      "compatibility": [
          "independence", "integration",
      ],
      "documentation": [
          "inline_comments"
      ],
      "standards": [
          "standards", "design_patterns", "code_complexity", "refactoring_opportunities"
      ]
  };

  // Chapter and key mapping
  const chapterNameMapping = {
    "overview": "Overview",
    "code_quality": "Code Quality",
    "functionality": "Functionality",
    "performance_scalability": "Performance & Scalability",
    "security": "Security",
    "compatibility": "Compatibility",
    "documentation": "Documentation",
    "standards": "Code Standards and Best Practices"
  };

  const keyNameMapping = {
    // Overview
    "script_purpose": "Script Purpose",
    "domain": "Domain",
    "recommendation": "Recommendations",
    "summary_all": "Summary All",
    "summary_code_quality": "Summary Code Quality",
    "summary_functionality": "Summary Functionality",
    "summary_perf_scal": "Summary Performance & Scalability",
    "summary_security": "Summary Security",
    "summary_compatibility": "Summary Compatibility",
    "summary_documentation": "Summary Documentation",
    "summary_standards": "Summary Standards",
    "is_script": "Is Script",
    "is_script_explanation": "Is Script Explanation",

    // Code Quality
    "readability": "Readability",
    "consistency": "Consistency",
    "modularity": "Modularity",
    "maintainability": "Maintainability",
    "reusability": "Reusability",
    "redundancy": "Redundancy",
    "technical_debt": "Technical Debt",
    "code_smells": "Code Smells",
    "flag_color": "Flag Color",
    "reasons_of_flag": "Reasons of Flag",

    // Functionality
    "completeness": "Completeness",
    "edge_cases": "Edge Cases",
    "error_handling": "Error Handling",

    // Performance & Scalability
    "efficiency": "Efficiency",
    "scalability": "Scalability",
    "resource_utilization": "Resource Utilization",
    "load_handling": "Load Handling",
    "parallel_processing": "Parallel Processing",
    "database_interaction_efficiency": "Database Interaction Efficiency",
    "concurrency_management": "Concurrency Management",
    "state_management_efficiency": "State Management Efficiency",
    "modularity_decoupling": "Modularity Decoupling",
    "configuration_customization_ease": "Configuration & Customization Ease",

    // Security
    "input_validation": "Input Validation",
    "data_handling": "Data Handling",
    "authentication": "Authentication",
    "dependencies": "Dependencies",

    // Compatibility
    "independence": "Independence",
    "integration": "Integration",

    // Documentation
    "inline_comments": "Inline Comments",

    // Code Standards and Best Practices
    "standards": "Standards Compliance",
    "design_patterns": "Design Patterns",
    "code_complexity": "Code Complexity",
    "refactoring_opportunities": "Refactoring Opportunities"
};

  const score_map = {

      "readability": {"highly readable": 100, "moderately readable": 50, "low readability": 0},
      "consistency": {"highly consistent": 100, "somewhat inconsistent": 50, "not consistent": 0},
      "modularity": {"excellent": 100, "average": 50, "poor": 0},
      "maintainability": {"high": 100, "moderate": 50, "low": 0},
      "reusability": {"high": 100, "moderate": 50, "low": 0},
      "redundancy": {"no redundancies": 100, "some redundancies": 50, "high redundancy": 0},
      "technical_debt": {"none": 100, "low": 66, "moderate": 33, "high": 0},
      "code_smells": {"none": 100, "low": 66, "moderate": 33, "high": 0},

      "completeness": {"fully functional": 100, "partially functional": 50, "not functional": 0},
      "edge_cases": {"excellently covered": 100, "partially covered": 66, "poorly covered": 33, "none covered": 0},
      "error_handling": {"robust": 100, "adequate": 50, "poor": 0},

      "efficiency": {"high": 100, "average": 50, "poor": 0},
      "scalability": {"high": 100, "moderate": 50, "not scalable": 0},
      "resource_utilization": {"optimal": 100, "acceptable": 50, "excessive": 0},
      "load_handling": {"excellent": 100, "good": 66, "average": 33, "poor": 0},
      "parallel_processing": {"fully supported": 100, "partially supported": 50, "not supported": 0},
      "database_interaction_efficiency": {"optimized": 100, "sufficient": 50, "inefficient": 0},
      "concurrency_management": {"robust": 100, "adequate": 50, "poor": 0},
      "state_management_efficiency": {"optimal": 100, "adequate": 50, "problematic": 0},
      "modularity_decoupling": {"highly modular": 100, "somewhat modular": 50, "monolithic": 0},
      "configuration_customization_ease": {"flexible": 100, "moderate": 50, "rigid": 0},

      "input_validation": {"strong": 100, "adequate": 50, "weak": 0},
      "data_handling": {"secure": 100, "moderately secure": 50, "insecure": 0},
      "authentication": {"robust": 100, "adequate": 50, "non-existent": 0},
      "dependencies": {"updated & secure": 100, "some vulnerabilities": 50, "outdated & vulnerable": 0},

      "independence": {"multi-platform": 100, "limited platforms": 50, "single platform": 0},
      "integration": {"seamless": 100, "requires workarounds": 50, "incompatible": 0},

      "inline_comments": {"comprehensive": 100, "adequate": 66, "sparse": 33, "none": 0},

      "standards": {"fully compliant": 100, "partially compliant": 50, "non-compliant": 0},
      "design_patterns": {"extensive": 100, "moderate": 66, "rare": 33, "none": 0},
      "code_complexity": {"low": 100, "moderate": 50, "high": 0},
      "refactoring_opportunities": {"many": 100, "some": 66, "few": 33, "none": 0},
  }


   // Function to determine color based on score value from score_map
   const getColorForValue = (key, value) => {
    if (!value) return '#8D8D8D';  // Handle undefined or null values
  
    const scores = score_map[key];
    if (!scores) return '#8D8D8D';  // Handle if no scoring map exists for the key
  
    const score = scores[value.toLowerCase()];  // Convert value to lower case and get the score
    if (score !== undefined) {
      if (score >= 100) return '#2CB392';  // Green for scores of 100
      else if (score >= 66) return '#2CB392';  // Green for scores from 66 to 99
      else if (score >= 33) return '#DE7539';  // Orange for scores from 33 to 65
      else return '#E83636';  // Red for scores below 33
    }
  
    return '#8D8D8D';  // Default gray color for unmatched or non-required cases
  };
  


  const isDataRelevant = () => {
    return Object.keys(fileDetails).some(key => 
      Object.values(topicMapping).flat().includes(key)
    );
  };

  const renderNoDataAvailableMessage = () => (
    <div className="file-not-audited-container">
      <h2 className="file-not-audited-title">This file has not been audited.</h2>
      <p className="file-not-audited-text">
        You can see if a file has been audited by an <i className="pi pi-eye" style={{padding: "5px", color: "#2CB392"}}></i> icon next to its file name.
      </p>
    </div>
  );

  const renderChapter = (chapterName, keys) => (
    <div key={chapterName}>
      <h3 className='filesdetail_chaptername' style={{ color: '#C049DE' }}>{chapterNameMapping[chapterName] || chapterName}</h3>
      <ul className='filesdetail_attribute_key'>
        {keys.map(key => {
          const value = fileDetails[key];
          const color = getColorForValue(key, value); // Get color based on the value

          return (
            <li key={`${chapterName}-${key}`}>
              <b>{keyNameMapping[key] || key}:</b> <span style={{ color }}>{value}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );


  const renderFileDetails = () => {
    if (!isDataRelevant()) {
      return renderNoDataAvailableMessage();
    }

    return Object.entries(topicMapping).map(([chapter, keys]) => {
      // Ensure only keys present in fileDetails are included
      const relevantKeys = keys.filter(key => key in fileDetails);
      return relevantKeys.length > 0 ? renderChapter(chapter, relevantKeys) : null;
    });
  };

  return (
    <Sidebar visible={visible} position="right" onHide={onClose} className="sidebar-custom">
      {renderFileDetails()}
    </Sidebar>
  );
};

export default FileDetailSidebar;
