import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import axiosInstance from '../../axiosConfig';
import { utcToZonedTime } from 'date-fns-tz';
import { differenceInMinutes, differenceInHours, differenceInDays, 
         differenceInWeeks, differenceInMonths, differenceInYears } from 'date-fns';
import AuditScoreBar from './AuditScoreBar';

// Utility Functions 
const getDomainLogo = (repo_url) => {
    const domain = new URL(repo_url).hostname;

    const domainLogos = {
        "github.com": '/images/GitHub.png',
        "gitlab.com": '/images/GitLab.png',
        "bitbucket.org": "/images/Bitbucket.png",
        "dev.azure.com": "/images/Azure.png",
        "azure.com": "/images/Azure.png",
        "SourceForge.net": "/images/SourceForge.png",
        "code.google.com": "/images/GoogleCode.png",
        "codeplex.com": "/images/CodePlex.png",
        "launchpad.net": "/images/Launchpad.png",
        "savannah.gnu.org": "/images/Savannah.png",
        "freecode.com": "/images/Freecode.png",
        "gitkraken.com": "/images/GitKraken.png",
        "beanstalkapp.com": "/images/Beanstalk.png",
        "assembla.com": "/images/Assembla.png",
        "phabricator.com": "/images/Phabricator.png",
        "gogs.io": "/images/Gogs.png",
        "gitea.com": "/images/Gitea.png",
        "gitbucket.com": "/images/GitBucket.png",
        "codeberg.org": "/images/Codeberg.png",
            // ... other domains if necessary
        };
    
    const logo = domainLogos[domain];
    return logo || '/images/default-logo.png';

};

const getElapsedTime = (utcTimeString, userTimeZone) => {
    const utcDate = new Date(utcTimeString);
    const userTime = utcToZonedTime(utcDate, userTimeZone);
    const currentTime = new Date();
    
    const diffYears = differenceInYears(currentTime, userTime);
    if (diffYears > 0) return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`;
    
    const diffMonths = differenceInMonths(currentTime, userTime);
    if (diffMonths > 0) return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
    
    const diffWeeks = differenceInWeeks(currentTime, userTime);
    if (diffWeeks > 0) return `${diffWeeks} week${diffWeeks > 1 ? 's' : ''} ago`;
    
    const diffDays = differenceInDays(currentTime, userTime);
    if (diffDays > 0) return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    
    const diffHours = differenceInHours(currentTime, userTime);
    if (diffHours > 0) return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    
    const diffMinutes = differenceInMinutes(currentTime, userTime);
    if (diffMinutes > 0) return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
    
    return 'Just now';
};

const getRemainingTimeParts = (utcTimeString, userTimeZone) => {
    const utcDate = new Date(utcTimeString);
    const endTime = new Date(utcDate.getTime() + 14 * 24 * 60 * 60 * 1000);
    const userEndTime = utcToZonedTime(endTime, userTimeZone);
    
    const currentTime = new Date();
    const diff = userEndTime - currentTime;

    if (diff <= 0) {
        return { timeLeft: "Session expired", suffix: "" };
    }

    const diffDays = differenceInDays(userEndTime, currentTime);
    const diffHours = differenceInHours(userEndTime, currentTime) % 24;

    let timeLeft = diffDays > 0 
        ? `${diffDays} day${diffDays > 1 ? 's' : ''}${diffHours > 0 ? ` & ${diffHours} hour${diffHours > 1 ? 's' : ''}` : ''}`
        : `${diffHours} hour${diffHours > 1 ? 's' : ''}`;

    return { timeLeft, suffix: "left before audit expires" };
};

// Card Components
const RepoCard = ({ audit, userTimeZone, accountUuid }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isNavigating, setIsNavigating] = useState(false);

    const targetStatuses = ["Opening", "Audit session created", "Repository structure imported", "File information imported"];
    const isTargetStatus = targetStatuses.includes(audit.audit_status);
    const auditUrl = isTargetStatus ? `/${audit.audit_uuid}/audit-scope-selection` : `/${audit.audit_uuid}/audit_summary`;
    const { timeLeft, suffix } = getRemainingTimeParts(audit.ai_synthesis, userTimeZone);

    const scoreMetrics = [
        { key: 'overall_quality_score', score: audit.overall_quality_score },
        { key: 'overall_functionality_score', score: audit.overall_functionality_score },
        { key: 'overall_security_score', score: audit.overall_security_score },
        { key: 'overall_architecture_score', score: audit.overall_architecture_score },
        { key: 'overall_documentation_score', score: audit.overall_documentation_score },
        { key: 'overall_standards_score', score: audit.overall_standards_score }
    ];

    // Function to truncate URL while maintaining readability
    const truncateUrl = (url) => {
        const maxLength = 30; // Adjust based on your needs
        if (url.length <= maxLength) return url;
        
        try {
            const urlObj = new URL(url);
            const domain = urlObj.hostname;
            const path = urlObj.pathname;
            
            // Show domain and truncated path
            const pathMaxLength = maxLength - domain.length - 5; // 5 for "..." and some buffer
            const truncatedPath = path.length > pathMaxLength 
                ? path.substring(0, pathMaxLength) + '...'
                : path;
            
            return `${domain}${truncatedPath}`;
        } catch (e) {
            // Fallback if URL parsing fails
            return url.substring(0, maxLength - 3) + '...';
        }
    };

    const handleClick = async (e) => {
        e.preventDefault();
        if (isNavigating) return;

        try {
            setIsNavigating(true);
            const response = await axiosInstance.get(
                'is_uuid_path_public/',
                {
                    params: {
                        audit_uuid: audit.audit_uuid,
                        account_uuid: accountUuid
                    }
                }
            );

            dispatch({
                type: 'SET_AUDIT_STATUS',
                payload: {
                    uuid: audit.audit_uuid,
                    isPublic: response.data.publicity === 'public',
                    auditStatus: response.data.auditStatus,
                    hasAccess: response.data.hasAccess,
                    isManager: response.data.isManager
                }
            });

            if (response.data.hasAccess) {
                navigate(auditUrl);
            } else {
                console.error('Access denied to audit');
            }
        } catch (error) {
            console.error('Error checking audit access:', error);
        } finally {
            setIsNavigating(false);
        }
    };

    return (
        <div onClick={handleClick} className="audit-item-link">
            <div className="audit-item">
                <img src={getDomainLogo(audit.repo_url)} className="repo-logo" alt="Repo Logo" />
                <div className="audit-details">
                    <span className={`repo-name ${isTargetStatus ? 'highlighted' : ''}`}>
                        {audit.audit_name}
                    </span>
                    <div className="repo-url-container" style={{ position: 'relative' }}>
                        <span 
                            className="repo-url"
                            data-pr-position="top"
                            style={{ 
                                display: 'inline-block',
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {truncateUrl(audit.repo_url)}
                        </span>
                    </div>
                </div>
                <div className="audit-info">
                    <span className="ai-synthesis">
                        {getElapsedTime(audit.ai_synthesis, userTimeZone)}
                    </span>
                    <span className={`file-count ${isTargetStatus ? 'highlighted' : ''}`}>
                        {isTargetStatus ? "Select files to audit" : `${audit.file_count} files audited`}
                    </span>
                    <span className="audit-status">
                        {isTargetStatus ? (
                            <>
                                <strong style={{fontSize: '0.9rem'}}>{timeLeft}</strong> {suffix}
                            </>
                        ) : audit.audit_status}
                    </span>
                </div>
                {/* Add score visualization if audit is completed */}
                {audit.audit_status === "Audit completed" && (
                    <div className="audit-scores-grid">
                        {scoreMetrics.map(({ key, score }) => {
                            return (
                                <AuditScoreBar
                                    key={`${audit.audit_uuid}-${key}`}
                                    title={key}
                                    score={score}
                                    auditId={audit.audit_uuid}
                                />
                            );
                        })}
                    </div>
                )}
                {isNavigating && <ProgressSpinner style={{width: '20px', height: '20px'}} />}
            </div>
        </div>
    );
};

export default RepoCard;