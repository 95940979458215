// RouteConfig.js
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, logout, setUserAuthInfo } from './features/user/userSlice';
import { getAuditUuidFromPath, PUBLIC_ENDPOINTS } from './utils/routeUtils';
import { checkAuditStatus } from './utils/Actions';
import axiosInstance from './axiosConfig';
import axios from 'axios';

export const PrivateRoute = () => {
    // All state hooks first
    const [isChecking, setIsChecking] = useState(true);
    const [isRehydrated, setIsRehydrated] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    // All selector hooks next
    const auth = useSelector(selectAuth);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const isPublicEndpoint = (path) => PUBLIC_ENDPOINTS.some(endpoint => path.includes(endpoint));
    const auditUuid = getAuditUuidFromPath(location.pathname);
    const auditAccessDetails = useSelector(state => state.audits?.[auditUuid]);

    // Early check for authentication
    useEffect(() => {
        if (isRehydrated && !auth.isLoggedIn && !isPublicEndpoint(location.pathname)) {
            navigate('/login', { state: { from: location.pathname } });
        }
    }, [auth.isLoggedIn, location.pathname, isRehydrated, navigate]);

    // Token verification function
    const verifyToken = async (accessToken) => {
        try {
            if (!accessToken) {
                return false;
            }

            // Create a new axios instance for verify request to avoid interceptors
            const verifyAxios = axios.create({
                baseURL: process.env.REACT_APP_API_URL,
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            
            try {
                const response = await verifyAxios.get('verify_auth/');
                return response.data.authenticated === true;
            } catch (verifyError) {
                // If verification fails, try to refresh the token
                const refreshToken = localStorage.getItem('refreshToken');
                if (refreshToken) {
                    try {
                        const refreshResponse = await verifyAxios.post('refresh_token/', {
                            refresh: refreshToken
                        });
                        
                        if (refreshResponse.data.access) {
                            const newAccessToken = refreshResponse.data.access;
                            localStorage.setItem('accessToken', newAccessToken);
                            
                            // Update Redux store with new token
                            dispatch(setUserAuthInfo({
                                ...auth,
                                access: newAccessToken
                            }));
                            
                            // Try verification again with new token
                            const retryResponse = await verifyAxios.get('verify_auth/', {
                                headers: {
                                    'Authorization': `Bearer ${newAccessToken}`
                                }
                            });
                            return retryResponse.data.authenticated === true;
                        }
                    } catch (refreshError) {
                        // If refresh fails, logout
                        dispatch(logout());
                        navigate('/login', { state: { from: location.pathname } });
                        return false;
                    }
                }
                return false;
            }
        } catch (error) {
            if ((error.response?.status === 401 || 
                error.response?.data?.error === 'Token has expired' ||
                error.response?.data?.error === 'Invalid token') &&
                !localStorage.getItem('refreshToken')) {
                dispatch(logout());
                navigate('/login', { state: { from: location.pathname } });
            }
            return false;
        }
    };

    // Rehydration check effect with timeout
    useEffect(() => {
        const checkRehydration = () => {
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');
            const accountUuid = localStorage.getItem('accountUuid');
            
            if (accessToken && refreshToken && accountUuid && !auth.isLoggedIn) {
                dispatch(setUserAuthInfo({
                    access: accessToken,
                    refresh: refreshToken,
                    accountUuid,
                    accountName: localStorage.getItem('accountName'),
                    isAdmin: localStorage.getItem('isAdmin') === 'true'
                }));
            }
            setIsRehydrated(true);
        };

        const timeoutId = setTimeout(checkRehydration, 100); // Small delay to ensure Redux is ready
        return () => clearTimeout(timeoutId);
    }, [auth.isLoggedIn, dispatch]);

    // Initialization effect
    useEffect(() => {
        if (!isRehydrated) return;

        const initializeAuth = () => {
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');
            const accountUuid = localStorage.getItem('accountUuid');
            const accountName = localStorage.getItem('accountName');
            const isAdmin = localStorage.getItem('isAdmin') === 'true';

            if (accessToken && refreshToken && accountUuid) {
                dispatch(setUserAuthInfo({
                    access: accessToken,
                    refresh: refreshToken,
                    accountUuid,
                    accountName,
                    isAdmin
                }));
                return true;
            }
            return false;
        };

        if (!auth.isLoggedIn && !isInitialized) {
            initializeAuth();
            setIsInitialized(true);
        }
    }, [auth.isLoggedIn, dispatch, isRehydrated, isInitialized]);

    // Navigation effect
    useEffect(() => {
        let isMounted = true;

        const handleNavigation = async () => {
            if (!isRehydrated) return;

            if (!auth.isLoggedIn && !isPublicEndpoint(location.pathname)) {
                const accessToken = localStorage.getItem('accessToken');
                const refreshToken = localStorage.getItem('refreshToken');
                if (accessToken && refreshToken) {
                    dispatch(setUserAuthInfo({
                        access: accessToken,
                        refresh: refreshToken,
                        accountUuid: localStorage.getItem('accountUuid'),
                        accountName: localStorage.getItem('accountName'),
                        isAdmin: localStorage.getItem('isAdmin') === 'true'
                    }));
                    return;
                }
            }
        };

        handleNavigation();
        return () => { isMounted = false; };
    }, [location.pathname, auth.isLoggedIn, dispatch, isRehydrated]);

    // Token verification effect
    useEffect(() => {
        let isMounted = true;

        const verifyAuth = async () => {
            if (!isRehydrated || !auth.isLoggedIn) return;

            try {
                const isValid = await verifyToken(auth.accessToken);
                if (isMounted) {
                    setIsVerified(isValid);
                }
            } catch (error) {
                if (isMounted) {
                    setIsVerified(false);
                }
            }
        };

        verifyAuth();
        return () => { isMounted = false; };
    }, [auth.isLoggedIn, auth.accessToken, isRehydrated]);

    // Audit access effect
    useEffect(() => {
        let isMounted = true;

        const checkAuditAccess = async () => {
            if (!isRehydrated || !isVerified) return;

            try {
                if (auditUuid) {
                    const action = await dispatch(checkAuditStatus(auditUuid));
                    
                    if (!isMounted) return;

                    if (action.isPublic) {
                        setIsChecking(false);
                        return;
                    }

                    if (!auth.isLoggedIn) {
                        navigate('/login', { state: { from: location.pathname } });
                        return;
                    }

                    if (!action.hasAccess) {
                        navigate('/dashboard');
                        return;
                    }

                    setIsChecking(false);
                } else {
                    setIsChecking(false);
                }
            } catch (error) {
                if (error.response?.status === 401) {
                    return;
                }
                if (isMounted) {
                    navigate('/error');
                }
            }
        };

        checkAuditAccess();
        return () => { isMounted = false; };
    }, [auditUuid, auth.isLoggedIn, location.pathname, navigate, dispatch, isRehydrated, isVerified]);

    // Loading state
    if (!isRehydrated || isChecking) {
        return <div className="loader-container"><div className="loader"></div></div>;
    }

    // Public audit access
    if (auditUuid && auditAccessDetails?.isPublic) {
        return <Outlet />;
    }

    // Protected route access - Immediate check
    if (!auth.isLoggedIn && !isPublicEndpoint(location.pathname)) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
};