// src/features/user/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Load initial state from localStorage
const loadInitialState = () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const accountUuid = localStorage.getItem('accountUuid');
    const accountName = localStorage.getItem('accountName');
    
    if (accessToken && refreshToken && accountUuid) {
      return {
        accountUuid,
        accessToken,
        refreshToken,
        isLoggedIn: true,
        accountName,
        isAdmin: localStorage.getItem('isAdmin') === 'true'
      };
    }
  } catch (error) {
    console.error('Error loading auth state from localStorage:', error);
  }
  return {
    accountUuid: null,
    accessToken: null,
    refreshToken: null,
    isLoggedIn: false,
    accountName: null,
    isAdmin: false,
  };
};

const initialState = {
  ...loadInitialState(),
  toastMessage: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAuthInfo: (state, action) => {
      const { accountUuid, access, refresh, accountName, isAdmin } = action.payload;
      
      // Save tokens to localStorage
      if (access) localStorage.setItem('accessToken', access);
      if (refresh) localStorage.setItem('refreshToken', refresh);
      if (accountUuid) localStorage.setItem('accountUuid', accountUuid);
      if (accountName) localStorage.setItem('accountName', accountName);
      localStorage.setItem('isAdmin', isAdmin ?? false);
      
      // Update state
      state.accountUuid = accountUuid;
      state.accessToken = access;
      state.refreshToken = refresh;
      state.isLoggedIn = true;
      state.accountName = accountName;
      state.isAdmin = isAdmin ?? false;
      state.toastMessage = null;
    },
    logout: (state, action) => {
      // Only clear auth-related items from localStorage
      const authKeys = ['accessToken', 'refreshToken', 'accountUuid', 'accountName', 'isAdmin'];
      authKeys.forEach(key => localStorage.removeItem(key));
      
      // Set toast message if session expired
      if (action.payload?.showSessionExpiredToast) {
        state.toastMessage = {
          severity: 'warn',
          summary: 'Session Expired',
          detail: 'Your session has expired. Please login again.',
          life: 5000
        };
      }
      
      // Reset state
      Object.assign(state, {
        accountUuid: null,
        accessToken: null,
        refreshToken: null,
        isLoggedIn: false,
        accountName: null,
        isAdmin: false,
      });
    },
    updateAccessToken: (state, action) => {
      const { access } = action.payload;
      state.accessToken = access;
      localStorage.setItem('accessToken', access);
    },
    clearToastMessage: (state) => {
      state.toastMessage = null;
    }
  },
});

export const { setUserAuthInfo, logout, updateAccessToken, clearToastMessage } = userSlice.actions;

export const selectAuth = state => state.user;
export const selectToastMessage = state => state.user.toastMessage;

export default userSlice.reducer;