// index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store'; // Import both store and persistor
import { ToastProvider } from './utils/ToastContext'; // Import ToastProvider

const LoadingComponent = () => (
  <div className="loader-container">
    <div className="loader"></div>
  </div>
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <Provider store={store}>
      <PersistGate loading={<LoadingComponent />} persistor={persistor}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </PersistGate>
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
