import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const AuditAccessContext = createContext({
  hasAccess: false,
  isManager: false,
  isPublic: false,
  auditUuid: null,
});

export const useAuditAccess = () => {
  const context = useContext(AuditAccessContext);
  if (context === undefined) {
    throw new Error('useAuditAccess must be used within an AuditAccessProvider');
  }
  return context;
};

export const AuditAccessProvider = ({ children }) => {
  const location = useLocation();
  const audits = useSelector(state => state.audits);
  const [auditAccess, setAuditAccess] = useState({
    hasAccess: false,
    isManager: false,
    isPublic: false,
    auditUuid: null,
  });

  useEffect(() => {    
    // Extract auditUuid from the pathname
    const pathParts = location.pathname.split('/');
    const potentialAuditUuid = pathParts[1];

    // Check if the current route is an audit view
    const isAuditView = /^\/[a-f0-9-]+\/(audit_summary|flags|dependencies|files|audit_scope_selector)/.test(location.pathname);
    
    if (isAuditView && potentialAuditUuid && audits[potentialAuditUuid]) {
      const currentAudit = audits[potentialAuditUuid];
      
      setAuditAccess({
        hasAccess: currentAudit.hasAccess || false,
        isManager: currentAudit.isManager || false,
        isPublic: currentAudit.isPublic || false,
        auditUuid: potentialAuditUuid,
      });
    } else {
      setAuditAccess({
        hasAccess: false,
        isManager: false,
        isPublic: false,
        auditUuid: null,
      });
    }
  }, [audits, location.pathname]);

  return (
    <AuditAccessContext.Provider value={auditAccess}>
      {children}
    </AuditAccessContext.Provider>
  );
};