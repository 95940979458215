import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Timeline } from 'primereact/timeline';
import icon_1 from '../../images/frontpage/icon_4.svg';
import icon_2 from '../../images/frontpage/icon_5.svg';
import icon_3 from '../../images/frontpage/icon_6.svg';
import GitHosts from '../../images/frontpage/git_hosts.png';
import SSHKey from '../../images/frontpage/ssh_certification.png';
import AuditComplete from '../../images/frontpage/audit_complete.png';
import './Security.css';

const SecurityCard = ({ icon, title, description }) => (
  <div className="security-benefit-card">
    <img src={icon} alt="" className="security-benefit-icon" loading="lazy" />
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const SecurityCards = () => {
  const benefits = [
    {
      icon: icon_1,
      title: "No code exposure",
      description: "Your code is hidden - at no point is it revealed in the audit reports. Allow transparency without compromising your IP."
    },
    {
      icon: icon_2,
      title: "No permanent storage",
      description: "Each source code file is encrypted and only stored and processed during the audit. After the audit, it is permanently deleted."
    },
    {
      icon: icon_3,
      title: "Limited repository access",
      description: "CodeDD does not ask at any point for your repository credentials. It uses unique ssh-key pair that you control."
    }
  ];

  return (
    <div className="security-benefits-section">
      <div className="security-benefits-grid">
        {benefits.map((benefit, index) => (
          <SecurityCard
            key={index}
            icon={benefit.icon}
            title={benefit.title}
            description={benefit.description}
          />
        ))}
      </div>
    </div>
  );
};

const TimelineSection = () => {
  const timelineEvents = [
    {
      title: "Audit Request",
      content: {
        paragraphs: [
          "You received a request to audit your codebase. To start the audit, you will need to provide your git repository URL. ",
          "Multiple repositories can be audited at once, and you can add as many as you want to the audit queue.",
        ],
        note: "Both public and private repositories are supported."
      },
      icon: "pi pi-code",
      image: GitHosts,
    },
    {
      title: "Repository Authentication",
      content: {
        paragraphs: [
          "For private repositories, you will be provided with your unique CodeDD SSH key pair. This key pair will be used to access your repository.",
          "The authentication process is simple and highly secure:",
        ],
        bulletPoints: [
          "During registration, a unique SSH key pair is generated for you.",
          "Add public key to your repository host (GitHub, GitLab, etc.)",
          "Remove access at any time by deleting the key at your repository host",
          "CodeDD does not store any credentials, and does not have access to your repository."
        ],
        note: "<i class='pi pi-info-circle'></i> Your repository remains under your full control at all times."
      },
      icon: "pi pi-shield",
      image: SSHKey,
    },
    {
      title: "Audit Starts - Cloning",
      content: {
        paragraphs: [
          "CodeDD will clone your repository and start the audit. Your code will be encrypted and stored in a secure and temporary environment.",
          "Security measures in place:",
        ],
        bulletPoints: [
          "End-to-end encryption of your source code",
          "Servers located in Europe (<a href='https://www.ionos.com' target='_blank' rel='noopener noreferrer'>IONOS</a> secure cloud)",
          "Temporary storage only during audit process",
          "Automatic deletion after audit completion"
        ],
      },
      icon: "pi pi-file",
      image: null,
    },
    {
      title: "Core Analysis",
      content: {
        paragraphs: [
          "CodeDD will analyze your code file by file and perform a context analysis. This is done by using state-of-the-art Large Language Models as well as our own fine-tuned models.",
          "Currently, CodeDD uses the following models:",
        ],
        bulletPoints: [
          "<a href='https://www.anthropic.com' target='_blank' rel='noopener noreferrer'>Anthropic´s Claude 3.5 Sonnet</a>",
          "<a href='https://www.openai.com' target='_blank' rel='noopener noreferrer'>OpenAI´s GPT-1o</a>",
          "Own fine-tuned models based on open source models using DeepSeek R1 architecture."
        ],
        note: [
          "Your source code will not be used for retraining any of the above models. It is only used for the audit process.",
          "The code is send via the above API´s under commercial license",
        ]
      },
      icon: "pi pi-code",
      image: null,
    },
    {
      title: "Cleanup",
      content: {
        paragraphs: [
          "After the audit is complete, the source code is deleted from the temporary environment and the audit report is generated.",
          "No traces of your source code will be left in the system."
        ],
        note: [
          "The cleanup process will also perform automatically, even if the audit is never started. There is a 14 day grace period after the audit request is made before the source code is deleted."
        ]
      },
      icon: "pi pi-check",
      image: AuditComplete,
    }
  ];

  const customizedContent = (item) => {
    const { content } = item;
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

    React.useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <div className="timeline-event-content">
        {isMobile && (
          <h2 className="timeline-event-content-title">{item.title}</h2>
        )}
        {content.paragraphs.map((paragraph, idx) => (
          <p key={idx} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
        
        {content.bulletPoints && (
          <ul className="timeline-bullet-points">
            {content.bulletPoints.map((point, idx) => (
              <li key={idx} dangerouslySetInnerHTML={{ __html: point }} />
            ))}
          </ul>
        )}
        
        {content.note && (
          <div 
            className="timeline-note"
            dangerouslySetInnerHTML={{ __html: content.note }}
          />
        )}

        {item.image && (
          <div className="timeline-image-wrapper">
            <img src={item.image} alt={item.title} className="timeline-image" />
          </div>
        )}
      </div>
    );
  };

  const customizedMarker = (item) => {
    return (
      <span className="custom-marker shadow-2">
        <i className={item.icon}></i>
      </span>
    );
  };

  const customizedOpposite = (item) => {
    return (
      <div className="timeline-event-title">
        <h2>{item.title}</h2>
      </div>
    );
  };

  return (
    <div className="timeline-section security-timeline-section">
      <div className="timeline-container">
        <h1 className="timeline-main-title">Secure Audit Process</h1>
        <p className="timeline-subtitle">
          CodeDD is designed from the ground up to protect your most valuable IP - your Source Code. 
          See below the entire process to understand the audit process in its entirety.
        </p>
        <div className="timeline-wrapper">
          <Timeline
            value={timelineEvents}
            align="center"
            className="customized-timeline security-timeline"
            marker={customizedMarker}
            content={customizedContent}
            opposite={customizedOpposite}
          />
        </div>
      </div>
    </div>
  );
};

const Security = () => {
  const navigate = useNavigate();

  const handleRequestDemo = () => {
    navigate('/demo');
  };

  const handleContactSales = () => {
    navigate('/contact-sales');
  };

  return (
    <div className="security-page">
      <div className="security-hero">
        <div className="security-hero-content">
          <div className="security-hero-text">
            <div className="security-tag">CodeDD Security</div>
            <div className="security-title">
              <div className="gradient-wrapper">
                <h1 className="security-gradient-text">IP Protection</h1>
              </div>
              <h1>at every step</h1>
            </div>
            <p className="security-description">
              How CodeDD protects your Intellectual Property
            </p>
            <div className="security-cta">
              <button className="security-cta-button" onClick={handleContactSales}>
                Contact sales
              </button>
              <button className="security-secondary-button" onClick={handleRequestDemo}>
                Try quick demo
              </button>
            </div>
          </div>
        </div>
      </div>
      <SecurityCards />
      <TimelineSection />
      <div className="security-cta-section">
        <div className="security-cta-container">
          <h2>Security at every step</h2>
          <p>AI-powered native application security testing</p>
          <div className="security-cta-buttons">
            <button className="security-cta-button" onClick={handleContactSales}>
              Contact sales <i className="pi pi-arrow-right"></i>
            </button>
            <button className="security-secondary-button" onClick={handleRequestDemo}>
              Request a demo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
