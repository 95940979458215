import React, { useState } from 'react';
import SummaryScoreGraph from '../../components/SummaryScoreGraph';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';
import '../../components/ScoreGraph.css';
import './DemoScoreGraph.css';
import { scoreDescriptions } from './scoreDescriptions';

// Import the subChapters configuration
const subChapters = [
  {
    title: 'Quality',
    points: [
      {
        name: 'Readability',
        scoreAttribute: 'readability'
      },
      {
        name: 'Consistency',
        scoreAttribute: 'consistency'
      },
      {
        name: 'Modularity and Maintainability',
        scoreAttribute: 'modularity'
      },
      {
        name: 'Reusability',
        scoreAttribute: 'reusability'
      },
      {
        name: 'Redundancy and Technical Debt',
        scoreAttribute: 'redundancy'
      },
      {
        name: 'Code Smells',
        scoreAttribute: 'code_smells'
      }
    ]
  },
  {
    title: 'Functionality',
    points: [
      {
        name: 'Completeness',
        scoreAttribute: 'completeness'
      },
      {
        name: 'Edge Cases',
        scoreAttribute: 'edge_cases'
      },
      {
        name: 'Error Handling',
        scoreAttribute: 'error_handling'
      }
    ]
  },
  {
    title: 'Scalability',
    points: [
      {
        name: 'Efficiency',
        scoreAttribute: 'efficiency'
      },
      {
        name: 'Scalability',
        scoreAttribute: 'scalability'
      },
      {
        name: 'Resource Utilization',
        scoreAttribute: 'resource_utilization'
      },
      {
        name: 'Load Handling',
        scoreAttribute: 'load_handling'
      },
      {
        name: 'Parallel Processing',
        scoreAttribute: 'parallel_processing'
      },
      {
        name: 'Database Interaction Efficiency',
        scoreAttribute: 'database_interaction_efficiency'
      },
      {
        name: 'Concurrency Management',
        scoreAttribute: 'concurrency_management'
      },
      {
        name: 'State Management Efficiency',
        scoreAttribute: 'state_management_efficiency'
      },
      {
        name: 'Modularity and Decoupling',
        scoreAttribute: 'modularity_decoupling'
      },
      {
        name: 'Configuration and Customization Ease',
        scoreAttribute: 'configuration_customization_ease'
      }
    ]
  },
  {
    title: 'Security',
    points: [
      {
        name: 'Input Validation',
        scoreAttribute: 'input_validation'
      },
      {
        name: 'Data Handling',
        scoreAttribute: 'data_handling'
      },
      {
        name: 'Authentication',
        scoreAttribute: 'authentication'
      }
    ]
  },
  {
    title: 'Compatibility',
    points: [
      {
        name: 'Independence and Flexibility',
        scoreAttribute: 'independence'
      },
      {
        name: 'Integration Capabilities',
        scoreAttribute: 'integration'
      }
    ]
  },
  {
    title: 'Documentation',
    points: [
      {
        name: 'Inline Comments',
        scoreAttribute: 'inline_comments'
      }
    ]
  },
  {
    title: 'Standards',
    points: [
      {
        name: 'Standards Compliance',
        scoreAttribute: 'standards'
      },
      {
        name: 'Design Patterns',
        scoreAttribute: 'design_patterns'
      },
      {
        name: 'Code Complexity',
        scoreAttribute: 'code_complexity'
      },
      {
        name: 'Refactoring Opportunities',
        scoreAttribute: 'refactoring_opportunities'
      }
    ]
  }
];

const ANIMATION_DELAY = 150;
const SUB_ANIMATION_DELAY = 100;

const score_map = {
  // Code Quality
  "readability": {"highly readable": 100, "moderately readable": 50, "low readability": 0},
  "consistency": {"highly consistent": 100, "somewhat inconsistent": 50, "not consistent": 0},
  "modularity": {"excellent": 100, "average": 50, "poor": 0},
  "maintainability": {"high": 100, "moderate": 50, "low": 0},
  "reusability": {"high": 100, "moderate": 50, "low": 0},
  "redundancy": {"no redundancies": 100, "some redundancies": 50, "high redundancy": 0},
  "technical_debt": {"none": 100, "low": 66, "moderate": 33, "high": 0},
  "code_smells": {"none": 100, "low": 66, "moderate": 33, "high": 0},

  // Functionality
  "completeness": {"fully functional": 100, "partially functional": 50, "not functional": 0},
  "edge_cases": {"excellently covered": 100, "partially covered": 66, "poorly covered": 33, "none covered": 0},
  "error_handling": {"robust": 100, "adequate": 50, "poor": 0},

  // Performance & Scalability
  "efficiency": {"high": 100, "average": 50, "poor": 0},
  "scalability": {"high": 100, "moderate": 50, "not scalable": 0},
  "resource_utilization": {"optimal": 100, "acceptable": 50, "excessive": 0},
  "load_handling": {"excellent": 100, "good": 66, "average": 33, "poor": 0},
  "parallel_processing": {"fully supported": 100, "partially supported": 50, "not supported": 0},
  "database_interaction_efficiency": {"optimized": 100, "sufficient": 50, "inefficient": 0},
  "concurrency_management": {"robust": 100, "adequate": 50, "poor": 0},
  "state_management_efficiency": {"optimal": 100, "adequate": 50, "problematic": 0},
  "modularity_decoupling": {"highly modular": 100, "somewhat modular": 50, "monolithic": 0},
  "configuration_customization_ease": {"flexible": 100, "moderate": 50, "rigid": 0},

  // Security
  "input_validation": {"strong": 100, "adequate": 50, "weak": 0},
  "data_handling": {"secure": 100, "moderately secure": 50, "insecure": 0},
  "authentication": {"robust": 100, "adequate": 50, "non-existent": 0},
  "dependencies": {"updated & secure": 100, "some vulnerabilities": 50, "outdated & vulnerable": 0},

  // Compatibility
  "independence": {"multi-platform": 100, "limited platforms": 50, "single platform": 0},
  "integration": {"seamless": 100, "requires workarounds": 50, "incompatible": 0},

  // Documentation
  "inline_comments": {"comprehensive": 100, "adequate": 66, "sparse": 33, "none": 0},

  // Code Standards and Best Practices
  "standards": {"fully compliant": 100, "partially compliant": 50, "non-compliant": 0},
  "design_patterns": {"extensive": 100, "moderate": 66, "rare": 33, "none": 0},
  "code_complexity": {"low": 100, "moderate": 50, "high": 0},
  "refactoring_opportunities": {"many": 100, "some": 66, "few": 33, "none": 0},
};

const getScoreValue = (attribute, value) => {
  if (!score_map[attribute]) return null;
  const scores = score_map[attribute];
  for (const [text, score] of Object.entries(scores)) {
    if (value && value.toLowerCase() === text.toLowerCase()) {
      return score;
    }
  }
  return null;
};

const DemoScoreGraph = ({ scoresData, auditDetails, parameters }) => {
  const [expandedSections, setExpandedSections] = useState({});

  const getSubpoints = (title) => {
    const chapterData = subChapters.find(chapter => chapter.title === title);
    if (!chapterData) return [];
    
    return chapterData.points
      .filter(point => point.scoreAttribute)
      .map(point => ({
        title: point.name,
        scoreAttribute: point.scoreAttribute
      }));
  };

  const toggleSection = (title) => {
    setExpandedSections(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  const renderSubpoint = (subpoint, subIndex, parameters, index) => {
    const paramValue = parameters[subpoint.scoreAttribute];
    const isNotRequired = paramValue === 'Not Required' || paramValue === 'not required';
    const score = isNotRequired ? '-' : getScoreValue(subpoint.scoreAttribute, paramValue);
    const description = scoreDescriptions[subpoint.scoreAttribute];
    
    return (
        <motion.div
            key={subpoint.scoreAttribute}
            className={`score-row sub-row ${isNotRequired ? 'not-required' : ''}`}
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ 
                delay: subIndex * 0.1,
                duration: 0.3
            }}
            style={{
                '--row-delay': `${(index * ANIMATION_DELAY) + ((subIndex + 1) * SUB_ANIMATION_DELAY)}ms`
            }}
        >
            <div className="score-title sub-title">
                {description && (
                    <span className="info-icon-wrapper">
                        <i className="pi pi-question-circle"
                           style={{ fontSize: '14px', color: '#C049DE', cursor: 'help' }} />
                        <div className="info-tooltip">
                            {description}
                        </div>
                    </span>
                )}
                <span>{subpoint.title}</span>
            </div>
            <div className="score-slider-graph" data-tooltip={isNotRequired ? 'Not Required' : `${paramValue}${score !== '-' ? ` (${score}%)` : ''}`}>
                <SummaryScoreGraph
                    scoreAttribute={subpoint.scoreAttribute}
                    name={subpoint.title}
                    isMainChapter={false}
                    auditDetails={{ [subpoint.scoreAttribute]: score }}
                    animationDelay={(index * ANIMATION_DELAY) + ((subIndex + 1) * SUB_ANIMATION_DELAY)}
                    isNotRequired={isNotRequired}
                />
                <div className="score-tooltip">
                    <div className="tooltip-value">
                        {isNotRequired ? 'Not Required' : (
                            <>
                                {paramValue}
                                {score !== '-' && ` (${score}%)`}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
    );
  };

  return (
    <div className="score-graph-container">
      {scoresData.map((data, index) => (
        <div key={data.scoreAttribute} className="chapter-section">
          <div 
            className="score-row main-row"
            onClick={() => toggleSection(data.title)}
            style={{
              '--row-delay': `${index * ANIMATION_DELAY}ms`,
              cursor: 'pointer'
            }}
          >
            <div className="score-title main-title">
              {expandedSections[data.title] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              <span>{data.title}</span>
            </div>
            <div className="score-slider-graph">
              <SummaryScoreGraph
                scoreAttribute={data.scoreAttribute}
                name={data.title}
                isMainChapter={true}
                auditDetails={auditDetails}
                animationDelay={index * ANIMATION_DELAY}
              />
            </div>
          </div>

          <AnimatePresence>
            {expandedSections[data.title] && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="subpoints-container"
              >
                {getSubpoints(data.title).map((subpoint, subIndex) => (
                  renderSubpoint(subpoint, subIndex, parameters, index)
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};

export default DemoScoreGraph;