// Flags.js
import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../axiosConfig';  // Update import
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';

import { useParams, Navigate } from 'react-router-dom';  // Import Navigate for redirection
import { useDispatch, useSelector } from 'react-redux';  // Import useDispatch and useSelector
import { checkAuditStatus } from '../utils/Actions';  // Import checkAuditStatus action

import GlobalSidebar from '../components/GlobalSidebar';
import TabMenuComponent from '../components/TabMenu';
import Header from '../components/Header';
import FlagsGraph from './flags_components/FlagsGraph';
import TotalToFix from './flags_components/TimeToFixTotal';
import TotalToFixDomain from './flags_components/TimeToFixDomain';

import './Flags.css';

const domainLogos = {
  "github.com": '/images/GitHub.png',
  "gitlab.com": '/images/GitLab.png',
  "bitbucket.org": "/images/Bitbucket.png",
  "SourceForge.net": "/images/SourceForge.png",
  "code.google.com": "/images/GoogleCode.png",
  "codeplex.com": "/images/CodePlex.png",
  "launchpad.net": "/images/Launchpad.png",
  "savannah.gnu.org": "/images/Savannah.png",
  "freecode.com": "/images/Freecode.png",
  "gitkraken.com": "/images/GitKraken.png",
  "beanstalkapp.com": "/images/Beanstalk.png",
  "assembla.com": "/images/Assembla.png",
  "phabricator.com": "/images/Phabricator.png",
  "gogs.io": "/images/Gogs.png",
  "gitea.com": "/images/Gitea.png",
  "gitbucket.com": "/images/GitBucket.png",
  "codeberg.org": "/images/Codeberg.png",
    // ... other domains if necessary
};
 
function FlagsView() {
  const { auditUuid } = useParams();
  const [flaggedFiles, setFlaggedFiles] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [auditName, setAuditName] = useState(''); 
  const [repoUrl, setRepoUrl] = useState(''); 
  const [repoDomain, setRepoDomain] = useState('');
  const dispatch = useDispatch();
  const auditAccessDetails = useSelector(state => state.audits[auditUuid]);
  const [auditDetails, setAuditDetails] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [redFlagCount, setRedFlagCount] = useState(0);
  const [organgeFlagCount, setOrgangeFlagCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  // State to handle the visibility of flag descriptions
  const [showFlagDescriptions, setShowFlagDescriptions] = useState(false);
  const [sunburstData, setSunburstData] = useState(null);
  const [timeToFixTotalData, setTimeToFixTotalData] = useState(null);
  const [timeToFixTotalDomainData, setTimeToFixTotalDomainData] = useState(null);
  const [totalTimeToFixAllFlags, setTotalTimeToFixAllFlags] = useState(null);

  // Check if the user has access to the audit
  useEffect(() => {
    if (!auditAccessDetails) {
      console.log("Audit details are not available yet.");
    } else if (auditAccessDetails.error) {
      console.error('Audit access error:', auditAccessDetails.error);
      setShouldRedirect(true);
    } else if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
      // Only redirect if the audit is private AND user doesn't have access
      setShouldRedirect(true);
    } else {
      setShouldRedirect(false);
    }
  }, [auditAccessDetails]);

  useEffect(() => {
    dispatch(checkAuditStatus(auditUuid));
  }, [dispatch, auditUuid]);

  useEffect(() => {
    let isActive = true; // Local flag to track if effect is active

    const fetchData = async () => {
      if (!auditAccessDetails) {
        return;
      }

      if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
        if (isActive) {
          setLoading(false);
          setIsDataLoaded(true);
        }
        return;
      }

      if (isActive) {
        setLoading(true);
      }

      try {
        const [auditDetailsResponse, flagsResponse] = await Promise.all([
          axiosInstance.get(`audit_details/${auditUuid}/`),
          axiosInstance.get(`flags/${auditUuid}/`)
        ]);

        if (!isActive) {
          return;
        }

        const auditDetails = auditDetailsResponse.data;
        const flagsData = flagsResponse.data;

        setAuditDetails(auditDetails);
        setAuditName(auditDetails.name || '');
        setRepoUrl(auditDetails.full_url || '');
        setFileCount(auditDetails.file_count || 0);

        try {
          const domain = auditDetails.full_url ? new URL(auditDetails.full_url).hostname : 'Unknown';
          setRepoDomain(domain);
        } catch (urlError) {
          console.error('Error parsing URL:', urlError);
          setRepoDomain('Invalid URL');
        }

        setSunburstData(flagsData.flagData || null);
        setTimeToFixTotalData(flagsData.flagSummaryTotal || null);
        setTimeToFixTotalDomainData(flagsData.flagSummaryByDomain || null);
        setTotalTimeToFixAllFlags(flagsData.totalTimeToFixAllFlags || 0);

        const sortedFlaggedFiles = (flagsData.flaggedFiles || []).sort((a, b) => 
          (a.flag_color === 'Red' && b.flag_color !== 'Red') ? -1 : 
          (a.flag_color !== 'Red' && b.flag_color === 'Red') ? 1 : 0
        );
        setFlaggedFiles(sortedFlaggedFiles);

        setIsDataLoaded(true);
        setLoading(false);

      } catch (error) {
        console.error('Error in fetchData:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
        }

        if (isActive) {
          setIsDataLoaded(true);
          setLoading(false);
        }
      }
    };

    // Only fetch if we have auditAccessDetails and haven't loaded yet
    if (auditAccessDetails && !isDataLoaded) {
      fetchData();
    }

    return () => {
      isActive = false;
    };
  }, [auditUuid, auditAccessDetails, isDataLoaded]);

  useEffect(() => {
    if (flaggedFiles.length > 0) {
      const redFlags = flaggedFiles.filter(file => file.flag_color === 'Red');
      const orangeFlags = flaggedFiles.filter(file => file.flag_color === 'Orange');
      setRedFlagCount(redFlags.length);
      setOrgangeFlagCount(orangeFlags.length);
    }
  }, [flaggedFiles]);

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  // Custom body template for the flag color
  const flagColorTemplate = (rowData) => {
    return <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: rowData.flag_color === 'Red' ? '#E83636' : '#DE7539' }}></div>;
  };

  // Update: Extract file name from file path
  const fileNameTemplate = (rowData) => {
    const parts = rowData.file_path.split('/');
    const fileName = parts.length > 5 ? parts.slice(5).join('/') : rowData.file_path;
    const color = rowData.flag_color === 'Red' ? '#E83636' : '#DE7539';
  
    return <strong style={{ color: color }}>{fileName}</strong>;
  };

  const headerProps = isDataLoaded ? {
    auditName: auditName,
    fileCount: fileCount,
    repoUrl: repoUrl,
    repoDomain: repoDomain,
    domainLogos: domainLogos
  } : {};

  // Custom body template for displaying time to fix
  const timeToFixTemplate = (rowData) => {
    return rowData.time_to_fix_flag !== 'N/A' ? `${rowData.time_to_fix_flag} hrs` : 'N/A';
  };

  // Helper function for text truncation and styling
  const truncateText = (text) => {
    if (text && text.length > 120) {  // Check if text is not undefined and has length greater than 120
      return text.substring(0, text.lastIndexOf(' ', 120)) + '...';
    }
    return text || '';  // Return an empty string if text is undefined or null
  };

  // Custom body template for the "Purpose" column
  const purposeTemplate = (rowData) => {
    const truncatedText = truncateText(rowData.script_purpose);
    return <div style={{ fontSize: '14px' }}>{truncatedText}</div>;
  };

  const renderDataTable = () => {
    const paginatorTemplate = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown';
    const firstRow = (currentPage - 1) * rowsPerPage;

    return (
        <DataTable value={flaggedFiles}
            stripedRows
            paginator
            paginatorTemplate={paginatorTemplate}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={rowsPerPage}
            totalRecords={fileCount}
            first={firstRow}
            onPageChange={(e) => {
                setCurrentPage(e.first / e.rows + 1);
                setRowsPerPage(e.rows);
            }}
            tableStyle={{ minWidth: '80%' }}
            style={{fontSize: "normal"}}>
            <Column body={flagColorTemplate} style={{ width: 'auto' }}> </Column>
            <Column field="file_path" header="File" body={fileNameTemplate} style={{ width: 'auto' }}></Column>
            <Column field="domain" header="Domain" style={{ width: 'auto' }}></Column>
            <Column field="reasons_of_flag" header="Reasons" style={{ width: 'auto' }}></Column>
            <Column field="script_purpose" header="Purpose" body={purposeTemplate} style={{ width: 'auto' }}></Column>
            <Column field="time_to_fix_flag" header="Time to fix (estimate)" body={timeToFixTemplate} style={{ width: 'auto' }}></Column>
        </DataTable>
    );
};

  const renderNoFlagsMessage = () => (
    <div className="flags-card">
      <Message
        style={{ border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#696cff' }}
        className="border-primary w-full justify-content-start"
        severity="info"
        content={<div>No flags were found!</div>}
      />
    </div>
  );

  const renderFlagDescriptions = () => {
    return (
      <Inplace closable={true} active={showFlagDescriptions} onToggle={(e) => setShowFlagDescriptions(e.value)}>
        <InplaceDisplay>
        <Button label="See flag descriptions" text className="flag-description-button" onClick={() => setShowFlagDescriptions(true)} />
        </InplaceDisplay>
        <InplaceContent>
          <div>
            <p><strong style={{ color: '#E83636' }}>Red Flag </strong>indicates a critical issue that poses a high risk, such as security vulnerabilities, legal/license violations, or major functional defects.</p>
            <p><strong style={{ color: '#DE7539' }}>Orange Flag:</strong> suggests a significant concern that may not be immediately critical but requires attention, like performance inefficiencies, minor security concerns, or partial non-compliance with coding standards.</p>
          </div>
        </InplaceContent>
      </Inplace>
    );
  };


  // Inside your FlagsView component
  const renderGraphContainer = () => {
    const isNonZero = (obj) => Object.values(obj).some(value => value > 0);

    const hasFlags = (timeToFixTotalData && isNonZero(timeToFixTotalData)) ||
                     (timeToFixTotalDomainData && Object.keys(timeToFixTotalDomainData).length > 0);
  
    return (
      <div className="flags-graph-container">
        <div className="sunburst-container">
          <div className="sunburst-header-container">
            Flags & file domain distribution within the code-base
          </div>
          {sunburstData && <FlagsGraph data={sunburstData} />}
        </div>
        {hasFlags && (
          <>
            <div className="time-to-fix-total">
              <div className="sunburst-header-container">
                Total estimated time to fix flags: {totalTimeToFixAllFlags} hours
              </div>
              {timeToFixTotalData && Object.keys(timeToFixTotalData).length > 0 && 
                <TotalToFix data={timeToFixTotalData} />
              }
            </div>
            <div className="time-to-fix-domain">
              <div className="sunburst-header-container">
                Estimate time to fix flags by domain
              </div>
              {timeToFixTotalDomainData && Object.keys(timeToFixTotalDomainData).length > 0 && 
                <TotalToFixDomain data={timeToFixTotalDomainData} />
              }
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <GlobalSidebar />
      {isDataLoaded && <Header {...headerProps} />}
      <TabMenuComponent />
      <div className="flags-page-layout">
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <p>Loading flags data...</p>
          </div>
        ) : !isDataLoaded ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <p>Initializing...</p>
          </div>
        ) : (
          <>
            <div className="flag-header">
              The audit has marked <strong style={{ color: '#E83636' }}>{redFlagCount} files </strong> with a red flag  
              and <strong style={{ color: '#DE7539' }}>{organgeFlagCount} files </strong> with an orange flag.
            </div>
            {renderFlagDescriptions()}
            <Panel header="Distribution & expected time to fix" toggleable style={{marginLeft: '35px'}}>
              {sunburstData && renderGraphContainer()}
            </Panel>
            <div className="flag-table">
              {flaggedFiles.length > 0 ? renderDataTable() : renderNoFlagsMessage()}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default FlagsView;
