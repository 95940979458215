import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import './NavigationMobile.css';

const NavigationMobile = ({ items }) => {
    const [visible, setVisible] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const navigate = useNavigate();

    const flattenMenuItems = (menuItems) => {
        if (!menuItems || !Array.isArray(menuItems)) {
            return [];
        }

        const flattened = [];
        menuItems.forEach(column => {
            if (Array.isArray(column) && column[0] && column[0].items) {
                column[0].items.forEach(item => {
                    if (item.template) {
                        const templateResult = item.template(item);
                        const { children } = templateResult.props;
                        flattened.push({
                            label: children.props.children[0].props.children, // title
                            description: children.props.children[1].props.children, // description
                            linkText: children.props.children[2].props.children[0], // link text
                            path: `/${item.label.toLowerCase().replace(' ', '-')}`,
                        });
                    }
                });
            }
        });
        return flattened;
    };

    const renderMenuItem = (item) => {
        const hasSubItems = item.items && item.items.length > 0;
        const subItems = hasSubItems ? flattenMenuItems(item.items) : [];

        return (
            <div className="mobile-menu-item" key={item.label}>
                <div 
                    className="mobile-menu-item-header" 
                    onClick={() => hasSubItems ? setActiveItem({...item, subItems}) : handleItemClick(item)}
                >
                    <h2>{item.label}</h2>
                    {hasSubItems && <i className="pi pi-angle-right"></i>}
                </div>
            </div>
        );
    };

    const handleItemClick = (item) => {
        setVisible(false);
        navigate(`/${item.label.toLowerCase().replace(' ', '-')}`);
    };

    const renderSubItems = (item) => {
        if (!item || !item.subItems) {
            return null;
        }

        return (
            <div className="mobile-submenu">
                {item.subItems.map((subItem, index) => (
                    <Link 
                        key={index} 
                        to={subItem.path}
                        onClick={() => setVisible(false)}
                        className="mobile-submenu-item"
                    >
                        <h3>{subItem.label}</h3>
                        <p>{subItem.description}</p>
                        <span className="mobile-submenu-link">
                            {subItem.linkText}
                            <i className="pi pi-angle-right"></i>
                        </span>
                    </Link>
                ))}
            </div>
        );
    };

    const renderMobileMenuHeader = () => (
        <div className="mobile-menu-header">
            <img src="/logo192.png" alt="CodeDD Logo" style={{width: '40px'}} />
            <Button icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text mobile-menu-close" />
        </div>
    );

    return (
        <div className="navigation-mobile">
            <Link to="/" className="logo-link">
                <img src="/logo192.png" alt="CodeDD Logo" style={{width: '40px'}} />
            </Link>
            <div className="mobile-nav-end">
                <Link to="/register" className="free-trial-button">
                    Get free trial
                </Link>
                <Button icon="pi pi-bars" onClick={() => setVisible(true)} className="p-button-text mobile-menu-button" />
            </div>
            <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen>
                {renderMobileMenuHeader()}
                <div className="mobile-menu">
                    {activeItem ? (
                        <>
                            <div className="mobile-submenu-header" onClick={() => setActiveItem(null)}>
                                <i className="pi pi-angle-left"></i>
                                <h2>{activeItem.label}</h2>
                            </div>
                            <div className="mobile-submenu-divider"></div>
                            {renderSubItems(activeItem)}
                        </>
                    ) : (
                        <>
                            {items.map(renderMenuItem)}
                            <Link to="/login" className="mobile-login-link" onClick={() => setVisible(false)}>
                                Log in
                            </Link>
                            <Link to="/register" className="mobile-free-trial-link" onClick={() => setVisible(false)}>
                                Get free trial
                            </Link>
                        </>
                    )}
                </div>
            </Sidebar>
        </div>
    );
};

export default NavigationMobile;