import React, { useState, useRef } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import axiosInstance from '../axiosConfig';
import './InviteToAudit.css';
import { useCsrfToken } from '../utils/CsrfTokenContext';
import { checkAuditStatus } from '../utils/Actions'; 
import { useDispatch } from 'react-redux';

const InviteToAudit = ({ accountUuid }) => {
    const [auditName, setAuditName] = useState('');
    const [invitationEmail, setInvitationEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [auditNameTouched, setAuditNameTouched] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);
    const [formErrors, setFormErrors] = useState({
        auditName: '',
        email: ''
    });
    
    const navigate = useNavigate();
    const toastRef = useRef(null);
    const { csrfToken } = useCsrfToken();
    const [isSending, setIsSending] = useState(false);
    const dispatch = useDispatch();

    // Validate audit name length
    const isAuditNameValid = auditName.length >= 3;
    const showAuditNameError = auditNameTouched && !isAuditNameValid;

    // Enhanced email validation
    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(email.toLowerCase());
    };

    const isButtonDisabled = !isAuditNameValid || 
                           invitationEmail === '' || 
                           !emailValid || 
                           isSending;

    // Handle audit name changes with validation
    const handleAuditNameChange = (e) => {
        const name = e.target.value;
        if (name.length <= 80) {
            setAuditName(name);
            setAuditNameTouched(true);
            
            // Clear error when user starts fixing
            if (name.length >= 3) {
                setFormErrors(prev => ({ ...prev, auditName: '' }));
            } else {
                setFormErrors(prev => ({
                    ...prev,
                    auditName: 'Audit name must be at least 3 characters long'
                }));
            }
        }
    };

    // Handle email changes with validation
    const handleEmailChange = (e) => {
        const email = e.target.value;
        if (email.length <= 80) {
            setInvitationEmail(email);
            setEmailTouched(true);
            
            // Validate email and update state
            const isValid = validateEmail(email) || email === '';
            setEmailValid(isValid);
            
            // Clear error when user starts fixing
            if (isValid) {
                setFormErrors(prev => ({ ...prev, email: '' }));
            } else {
                setFormErrors(prev => ({
                    ...prev,
                    email: 'Please enter a valid email address'
                }));
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isButtonDisabled) {
            sendAuditInvitation();
        }
    };

    const sendAuditInvitation = async () => {
        // Validate before sending
        if (!isAuditNameValid || !validateEmail(invitationEmail)) {
            setFormErrors({
                auditName: !isAuditNameValid ? 'Audit name must be at least 3 characters long' : '',
                email: !validateEmail(invitationEmail) ? 'Please enter a valid email address' : ''
            });
            return;
        }

        setIsSending(true);
        try {
            const response = await axiosInstance.post(
                'send_audit_invitation/',
                {
                    name: auditName,
                    email: invitationEmail,
                    account_uuid: accountUuid
                }
            );

            const { audit_uuid } = response.data;
            
            // Just await the dispatch result directly
            await dispatch(checkAuditStatus(audit_uuid));
            
            toastRef.current.show({
                severity: 'success',
                summary: 'Invitation Sent',
                detail: 'The audit invitation has been sent successfully.',
                life: 3000
            });

            navigate(`/${audit_uuid}/audit-invitation`, { replace: true });
        } catch (error) {
            let errorMessage = 'Failed to send the audit invitation. Please try again.';
            
            // Enhanced error handling
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        errorMessage = 'Invalid input. Please check your entries and try again.';
                        break;
                    case 403:
                        errorMessage = 'You do not have permission to send audit invitations.';
                        break;
                    case 429:
                        errorMessage = 'Too many attempts. Please wait a moment and try again.';
                        break;
                    case 500:
                        errorMessage = 'Server error. Please try again later.';
                        break;
                }
            }

            console.error('Error sending audit invitation:', error);
            toastRef.current.show({
                severity: 'error',
                summary: 'Sending Failed',
                detail: errorMessage,
                life: 5000
            });
        } finally {
            setIsSending(false);
        }
    };

    return (
        <div className="invite-to-audit-container">
            <Toast ref={toastRef} />
            <Card className="invitation-card">
                <div className="invite-content">
                    <h2 className="invite-title">Invite repository owner for auditing</h2>
                    
                    <p className="invite-text">Name your audit</p>
                    <div className="field">
                        <InputText
                            className={`invitation-input-field ${showAuditNameError ? 'p-invalid' : ''}`}
                            style={{ maxWidth: "455px" }}
                            value={auditName}
                            onChange={handleAuditNameChange}
                            placeholder="e.g. SpaceX"
                            maxLength={80}
                        />
                        {showAuditNameError && (
                            <Message 
                                severity="error" 
                                text={formErrors.auditName}
                                style={{ display: 'block' }}
                            />
                        )}
                    </div>

                    <p className="invite-text">Enter email from repository owner</p>
                    <div className="field">
                        <InputText
                            className={`invitation-input-field ${emailTouched && !emailValid ? 'p-invalid' : ''}`}
                            style={{ maxWidth: "455px" }}
                            value={invitationEmail}
                            onChange={handleEmailChange}
                            onKeyDown={(e) => e.key === 'Enter' && handleKeyPress(e)}
                            placeholder="e.g. john@smith.com"
                            maxLength={80}
                        />
                        {emailTouched && !emailValid && (
                            <Message 
                                severity="error" 
                                text={formErrors.email}
                                style={{ display: 'block', marginTop: '0.5rem' }}
                            />
                        )}
                    </div>

                    <div className="button-container">
                    <Button
                        className="send-invitation-button"
                        label={isSending ? "Sending invitation" : "Send invitation"}
                        onClick={sendAuditInvitation}
                        disabled={isButtonDisabled}
                        icon={isSending ? 'pi pi-spin pi-spinner' : 'pi pi-send'}
                        iconPos="left"  // Explicitly set icon position
                    />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default InviteToAudit;