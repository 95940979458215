import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import axiosInstance from '../../axiosConfig';

import './ForgotPassword.css';
import './ResetPassword.css';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [tokenValid, setTokenValid] = useState(true);
    const [attempts, setAttempts] = useState(0);
    const navigate = useNavigate();
    const msgs = useRef(null);

    const token = searchParams.get('token');

    useEffect(() => {
        validateToken();
    }, [token]);

    const validateToken = async () => {
        if (!token) {
            setTokenValid(false);
            showMessage('error', 'Error', 'Invalid or missing reset token');
            return;
        }

        try {
            const response = await axiosInstance.post('validate-reset-token/',
            { token }
            );
            
            setTokenValid(response.data.valid);
            if (!response.data.valid) {
                showMessage('error', 'Error', response.data.message || 'Invalid or expired reset token');
            }
        } catch (error) {
            setTokenValid(false);
            const errorMessage = error.response?.data?.message || 'Failed to validate reset token';
            showMessage('error', 'Error', errorMessage);
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    const showMessage = (severity, detail) => {
        msgs.current?.clear();
        msgs.current?.show({
            severity,
            detail,
            sticky: true,
            closable: true,
            icon: false // Hides the icon
        });
    };   

    const validatePasswords = () => {
        if (password.length < 8) {
            showMessage('error', 'Error', 'Password must be at least 8 characters long');
            return false;
        }
        if (password !== confirmPassword) {
            showMessage('error', 'Error', 'Passwords do not match');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e?.preventDefault();
        
        // Check attempts to match backend rate limiting
        if (attempts >= 3) {
            showMessage('error', 'Error', 'Too many reset attempts. Please request a new reset link.');
            return;
        }

        if (!validatePasswords()) return;

        setIsLoading(true);
        setAttempts(prev => prev + 1);

        try {
            const response = await axiosInstance.post(
                `confirm-password-reset/`,
                {
                    password,
                    token
                },

            );

            if (response.data.status === 'success') {
                showMessage('success', 'Success', response.data.message || 'Password has been reset successfully');
                setTimeout(() => navigate('/login'), 2000);
            }
        } catch (error) {
            if (error.response?.status === 429) {
                showMessage('error', 'Error', 'Too many reset attempts. Please request a new reset link.');
            } else {
                showMessage('error', 'Error', error.response?.data?.message || 'Failed to reset password');
            }
        } finally {
            setIsLoading(false);
        }
    };

    if (!tokenValid) {
        return (
            <div className="reset-password-container">
                <Messages ref={msgs} className="floating-message" style={{marginTop: '75px'}} />
                <Card className="invalid-token-card">
                    <h2>Invalid Reset Token</h2>
                    <Button 
                        label="Return to Login" 
                        onClick={() => navigate('/login')} 
                        className="reset_password_button"
                    />
                </Card>
            </div>
        );
    }

    return (
        <div className="reset-password-container">
            <Messages ref={msgs} className="floating-message" style={{marginTop: '75px'}} />
            <Card className="reset-password-card">
                <div className="logo-section">
                    <img 
                        src="/logo192.png" 
                        style={{ width: '50px', height: '50px', verticalAlign: 'middle'}} 
                        alt="CodeDD Logo" 
                    />
                    <h1>CodeDD</h1>
                </div>
                <div className="change-password-section">
                    <h4>Change your password</h4>
                    <form onSubmit={handleSubmit} className="reset-form">
                        <div className="form-field">
                            <label>New password</label>
                            <Password
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                feedback={false}
                                className="w-full custom-password"
                                onKeyDown={handleKeyDown}
                                inputClassName="password-input"
                                tabIndex={1}
                                toggleMask
                            />
                        </div>
                        <div className="form-field">
                            <label>Confirm new password</label>
                            <Password
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                feedback={false}
                                className="w-full custom-password"
                                onKeyDown={handleKeyDown}
                                inputClassName="password-input"
                                tabIndex={2}
                            />
                        </div>
                        <Button
                            type="submit"
                            label={isLoading ? "Updating..." : "Change password"}
                            className="w-full mt-4"
                            disabled={isLoading}
                            tabIndex={3}
                        />
                        <div className="links-section">
                            <a href="/forgot-password">Haven't received a confirmation email?</a>
                            <a href="/login">Already have an account? Log in</a>
                        </div>
                    </form>
                </div>
            </Card>
        </div>
    );
};

export default ResetPassword;