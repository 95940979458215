import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { useCsrfToken } from '../../utils/CsrfTokenContext';
import axiosInstance from '../../axiosConfig';
import './ForgotPassword.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inputComplete, setInputComplete] = useState(false);
    const msgs = useRef(null);
    const { csrfToken } = useCsrfToken();

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return email === '' || regex.test(email);
    };

    const validateForm = () => {
        return email !== '' && validateEmail(email) && Object.keys(errors).length === 0;
    };

    useEffect(() => {
        setInputComplete(validateForm());
    }, [email, errors]);

    const showMessage = (severity, detail, sticky = true) => {
        msgs.current?.clear();
        msgs.current?.show({
            severity,
            detail,
            sticky,
            closable: true,
            icon: false
        });
    };

    const handleInputChange = (value) => {
        setEmail(value);
        let newErrors = { ...errors };
        delete newErrors.email;
        delete newErrors.api;

        if (!validateEmail(value) && value !== '') {
            newErrors.email = 'Invalid email address';
        }

        setErrors(newErrors);
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setIsLoading(true);
            try {
                const response = await axiosInstance.post('reset-password/', {
                    email
                }
                );
                if (response.data.status === 'success') {
                    showMessage('success', 'If an account exists with this email, you will receive password reset instructions.');
                } else {
                    showMessage('error', response.data.message);
                }
            } catch (error) {
                console.error('Password reset error:', error);
                const errorMessage = error.response?.data?.message || 
                    (error.request ? 'No response from server. Please try again.' : 'An unexpected error occurred');
                showMessage('error', errorMessage);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="forgot-password-container">
            <Messages ref={msgs} className="floating-message-password" style={{marginTop: '75px'}} />
            <Card className="forgot-password-card">
                <div className="forgot-password-header">
                    <img 
                        src="/logo192.png" 
                        style={{ width: '50px', height: '50px', verticalAlign: 'middle'}} 
                        alt="CodeDD Logo" 
                    />
                    <h1>CodeDD</h1>
                    <h4>Enter your email to reset your password</h4>
                </div>
                <div className="p-fluid">
                    <div className="p-field">
                        <span className="p-float-label">
                            <InputText
                                id="email"
                                value={email}
                                onChange={(e) => handleInputChange(e.target.value)}
                                className={errors.email ? 'p-invalid' : ''}
                            />
                            <label htmlFor="email">Email</label>
                        </span>
                        {errors.email && <small className="p-error">{errors.email}</small>}
                    </div>
                    <Button
                        label={isLoading ? "Sending..." : "Reset Password"}
                        className="reset-password-button"
                        onClick={handleSubmit}
                        disabled={isLoading || !inputComplete}
                    />
                    {errors.api && <small className="p-error">{errors.api}</small>}
                    <div className="jump-to-login">
                        <p>Already have an account? <a href="/login">Login</a></p>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ForgotPassword;