import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-main">
                    <div className="footer-logo">
                        <Link to="/">
                            <img src="/codedd_white.svg" alt="CodeDD Logo" className="footer-logo-img" />
                        </Link>
                    </div>
                    <div className="footer-columns">
                        <div className="footer-column">
                            <h3>Platform</h3>
                            <ul>
                                <li><Link to="/platform">Discover CodeDD</Link></li>
                                <li><Link to="/why-codedd">Why CodeDD</Link></li>
                            </ul>
                        </div>
                        
                        <div className="footer-column">
                            <h3>Resources</h3>
                            <ul>
                                <li><Link to="/security">Security</Link></li>
                                <li><Link to="/discover-audits">Discover public audits</Link></li>
                            </ul>
                        </div>

                        <div className="footer-column">
                            <h3>Company</h3>
                            <ul>
                                <li><Link to="/impressum">Impressum</Link></li>
                                <li><Link to="/about">About us</Link></li>
                                <li><Link to="/terms">Terms of use</Link></li>
                                <li><Link to="/data-processing-addendum">Source Code & Data Management</Link></li>
                            </ul>
                        </div>

                        <div className="footer-column">
                            <h3>Contact us</h3>
                            <ul>
                                <li><Link to="/contact-sales">Contact an expert</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-bottom-content">
                    <p className="footer-bottom-text">© {new Date().getFullYear()} CodeDD e.U. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;