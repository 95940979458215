import React from 'react';
import dataProcessFlowImage from '../../assets/images/legal/DataProcessFlow.png';
import './Terms.css';

const DataProcessingAddendum = () => {
  return (
    <div className="terms-page">
      <header className="terms-header">
        <h1 className="terms-logo">CodeDD</h1>
        <h2 className="terms-title">CodeDD Data Processing Addendum</h2>
        <p className="terms-date">Last updated January 22, 2025</p>
      </header>
      
      <div className="terms-container">

        <p class="terms-paragraph">This Data Processing Addendum ("DPA") supplements the CodeDD Terms and Conditions or other agreement in place between Customer and CodeDD (the "Agreement") covering Customer's use of CodeDD and CodeDD Commercial Support (the "Products"). Capitalized terms not defined in this DPA have the meanings set forth in the relevant Agreement.</p>

        <h3 class="terms-section-title">1. Definitions</h3>

        <p class="terms-paragraph">"<strong>Affiliate</strong>" means an entity that, directly or indirectly, owns or controls, is owned or is controlled by or is under common ownership or control with a party, where "ownership" means the beneficial ownership of more than fifty percent (50%) of an entity's voting equity securities or other equivalent voting interests and "control" means the power to direct the management or affairs of an entity.</p>

        <p class="terms-paragraph">"<strong>Applicable Data Protection Law</strong>" means all data protection laws and regulations applicable to the Processing of Personal Data under the Agreement, and may include the additional definitions provided for in Schedule 2 herein.</p>

        <p class="terms-paragraph">"<strong>Account Data</strong>" means Personal Data relating to Customer's relationship with CodeDD, including: (i) Users' account information (e.g. first and last name, email address); (ii) billing and contact information of individuals associated with Customer's CodeDD account (e.g. billing address, first and last name, email address); (iii) Users' device and connection information (e.g. IP address); and (iv) content/description of technical support requests (excluding attachments).</p>

        <p class="terms-paragraph">"<strong>Controller</strong>" means the natural or legal person, public authority, agency, or other body which, alone or jointly with others, determines the purposes and means of the Processing of Personal Data.</p>

        <p class="terms-paragraph">"<strong>Customer</strong>" means the customer entity or individual that is the contracting party to the Agreement.</p>

        <p class="terms-paragraph">"<strong>Customer Data</strong>" means (i) any data Customer inputs into the Products, including without limitation any source code or other materials relating to Customer's software projects and (ii) any materials or attachments provided by Customer or its Users in any technical support requests.</p>

        <p class="terms-paragraph">"<strong>Customer Personal Data</strong>" means any Personal Data contained in Customer Data that CodeDD Processes under the Agreement solely on behalf of Customer, and excludes Account Data, Results Data, and Usage Data.</p>

        <p class="terms-paragraph">"<strong>Personal Data</strong>" means any information that relates to an identified or identifiable natural person, or which otherwise constitutes "personal data", "personal information", "personally identifiable information" or similar terms as defined in Applicable Data Protection Law.</p>

        <p class="terms-paragraph">"<strong>Processing</strong>" (and "Process") means any operation or set of operations that is performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure, or destruction.</p>

        <p class="terms-paragraph">"<strong>Processo</strong>" means the entity that Processes Personal Data on behalf of the Controller.</p>

        <p class="terms-paragraph">"<strong>Results Data</strong>" means any Personal Data relating to the customer-specific results that are generated by the Products processing Customer Data and available to Customer and/or Users via the Products.</p>

        <p class="terms-paragraph">"<strong>Security Incident</strong>" means any breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Customer Data Processed by CodeDD and/or its Sub-processors.</p>

        <p class="terms-paragraph">"<strong>Sub-processor</strong>" means any third party engaged by CodeDD in its role as a Processor to Customer to Process Customer Personal Data.</p>

        <p class="terms-paragraph">"<strong>Term</strong>" means the term of this DPA which begins with the commencement of the term of the Agreement and terminates upon expiration or earlier termination of the Agreement (or, if later, the date on which CodeDD ceases all Processing of Customer Personal Data).</p>

        <p class="terms-paragraph">"<strong>Usage Data</strong>" means any Personal Data relating to or obtained in connection with the use, performance, operation, support or use of the Products. Usage Data may include events (i.e. actions performed), event timestamps, browser information, and diagnostic data.</p>

        <p class="terms-paragraph">"<strong>User</strong>" means any individual that Customer authorizes to use the Products. Users may include: (i) Customer's and its Affiliates' employees, consultants, contractors and agents (ii) third parties with which Customer or its Affiliates transact business (iii) individuals invited by Customer's users (iv) individuals under managed accounts, or (v) individuals interacting with a Product as Customer's customer.</p>

        <h3 class="terms-section-title">2. Scope</h3>

        <p class="terms-paragraph">2.1 Customer Personal Data. CodeDD will Process Customer Personal Data as Customer's Processor in accordance with Customer's instructions as outlined in Section 3.1 (Customer Instructions). Customer agrees that any Customer Personal Data it submits as part of its use of the Products will be done in accordance with the requirements of Applicable Data Protection Law, and Customer will, if applicable, provide notice to, and obtain the necessary rights and consents from, data subjects concerning the Customer's use of the Product. Customer shall have sole responsibility for the accuracy, quality and legality of Customer Personal Data and the means by which Customer or any relevant third-party acquired Customer Personal Data.</p>

        <p class="terms-paragraph">2.2 Account Data. CodeDD will Process Account Data as a Controller for the following purposes: (i) to provide and improve the Service; (ii) to manage the Customer relationship (communicating with Customer and Users in accordance with their account preferences, responding to Customer inquiries and providing technical support, etc.); (iii) to facilitate security, fraud prevention, performance monitoring, business continuity and disaster recovery; and (iv) to carry out core business functions such as accounting, billing, and filing taxes; (v) to market CodeDD's products, services, and events; and (vi) to comply with and resolve legal obligations.</p>

        <p class="terms-paragraph">2.3 Usage Data and Results Data. CodeDD will Process Usage Data and Results Data as a Controller for the following purposes: (i) to provide, optimize, secure, and maintain the Service; (ii) to optimize user experience; and (iii) to inform CodeDD's business strategy.</p>

        <p class="terms-paragraph">2.4 Description of the Processing. Details regarding the Processing of Personal Data by CodeDD are stated in Schedule 1 (Description of Processing).</p>

        <p class="terms-paragraph">2.5 International Applicability. To the extent CodeDD Processes Personal Data protected by Applicable Data Protection Law in one of the regions listed in Schedule 2 (Region-Specific Terms), the terms specified for the applicable regions will also apply, including the provisions relevant for international transfers of Personal Data (directly or via onward transfer).</p>

        <p class="terms-paragraph">2.6 Order of Precedence. If there is any conflict or inconsistency among the following documents, the order of precedence is: (1) the applicable terms stated in Schedule 2 (Region-Specific Terms including any transfer provisions); (2) the main body of this DPA; and (3) the Agreement.</p>

        <p class="terms-paragraph">2.7 Updates. CodeDD may modify this DPA as required due to (a) changes in Applicable Data Protection Law; or (b) the release of new features, functions, products or services or material changes to any of the existing Products. CodeDD may make such modifications by posting a revised version of this DPA at codedd.ai, or by otherwise sending an email to the primary email address specified in Customer's Account.</p>

        <p class="terms-paragraph">2.8 Cumulative claims. Any claims against CodeDD or its affiliates under this DPA can only be brought against CodeDD by the Customer entity that is a party to the Agreement.</p>

        <p class="terms-paragraph">2.9 Liability. The total aggregate liability of CodeDD and its Affiliates arising out of or related to this DPA, whether in contract, tort, or under any other theory of liability, will be subject to the same limitations and exclusions of liability as apply under the Agreement, whether any such liability arises under the Agreement or this DPA.</p>

        <p class="terms-paragraph">2.10 Governing law and jurisdiction. This DPA will be governed by and construed in accordance with the governing law and jurisdiction provisions in the Agreement unless required otherwise by Applicable Data Protection Law.</p>

        <p class="terms-paragraph">2.11 Entire agreement. This DPA supersedes any prior agreements between Customer and CodeDD concerning the privacy, data protection, and security requirements that apply to the processing of Customer Personal Data. Except where expressly approved by CodeDD in writing, any deletions or revisions to this DPA by Customer shall be null and void.</p>

        <h3 class="terms-section-title">3. Processing</h3>

        <p class="terms-paragraph">3.1 Customer Instructions. CodeDD will Process Customer Personal Data in accordance with the documented lawful instructions of Customer as stated in the Agreement (including this DPA) and respective CodeDD order forms, as necessary to (i) enable the use of various features and functionalities of the Products in accordance with the Documentation, or (ii) comply with its legal obligations. CodeDD will notify Customer if it becomes aware, or reasonably believes, that Customer's instructions violate Applicable Data Protection Law.</p>

        <p class="terms-paragraph">3.2 Confidentiality. CodeDD will treat Customer Personal Data as Customer's Confidential Information under the Agreement. CodeDD will ensure personnel authorized to Process Personal Data are bound by written or statutory obligations of confidentiality.</p>

        <p class="terms-paragraph">3.3 AI Model Processing Specifics. Customer explicitly acknowledges and agrees that:</p>

        <ul class="terms-list">
        <li>The processing of source code through AI models (specifically Anthropic's Claude) is essential for the Service's core functionality</li>
        <li>While CodeDD has commercial agreements with AI providers that include data protection measures, the nature of AI processing means that data may be temporarily stored in the AI model's memory during processing</li>
        <li>CodeDD ensures that AI providers are contractually bound to not use the processed data for model training or any purpose other than immediate analysis</li>
        <li>Customer accepts the inherent risks associated with AI-based processing and acknowledges that absolute prevention of data retention in AI systems may not be technically feasible</li>
        </ul>

        <p class="terms-paragraph">3.4 Temporary Data Storage and Deletion. CodeDD implements the following specific measures regarding data storage:</p>

        <ul class="terms-list">
        <li>All source code and related data is stored only for the duration necessary to complete the audit</li>
        <li>Automated deletion processes ensure data removal immediately after audit completion</li>
        <li>Backup copies are maintained only for the minimum time required by law or necessary for disaster recovery</li>
        <li>Customer can request immediate deletion of all data at any time, subject to legal retention requirements</li>
        </ul>

        <p class="terms-paragraph">3.5 Technical Security Measures. In addition to general security measures, CodeDD implements:</p>

        <ul class="terms-list">
        <li>End-to-end encryption for all data in transit and at rest</li>
        <li>Regular security audits and penetration testing of infrastructure</li>
        <li>Access logging and monitoring systems</li>
        <li>Automated threat detection and prevention systems</li>
        <li>Regular security training for all personnel with data access</li>
        </ul>

        <p class="terms-paragraph">3.6 Liability Limitations for AI Processing. Customer acknowledges and agrees that:</p>

        <ul class="terms-list">
        <li>CodeDD's liability for any data breach or loss occurring during AI model processing is limited to cases of gross negligence or willful misconduct</li>
        <li>Any liability claims related to AI processing must be primarily directed at the respective AI provider under their commercial agreements</li>
        <li>CodeDD's maximum liability for AI-related incidents is limited to the fees paid by Customer in the 12 months preceding the incident</li>
        <li>CodeDD is not liable for any business decisions made based on AI-generated analysis results</li>
        </ul>

        <p class="terms-paragraph">3.7 Breach Notification and Response. In addition to general security incident procedures:</p>

        <ul class="terms-list">
        <li>CodeDD will maintain a detailed breach response plan specific to AI-processing related incidents</li>
        <li>In case of a breach at the AI provider level, CodeDD will coordinate with the provider to ensure proper incident response</li>
        <li>Customer will be notified of any breach within 24 hours of discovery, where feasible</li>
        <li>CodeDD will provide regular updates during breach investigation and remediation</li>
        </ul>

        <p class="terms-paragraph">3.8 Intellectual Property Protection. To further protect Customer's intellectual property:</p>

        <ul class="terms-list">
        <li>All source code processing is conducted in isolated environments</li>
        <li>No source code is stored in plain text format at any time</li>
        <li>Access to source code is strictly limited to essential processing systems</li>
        <li>Customer retains all rights to processed source code and derived analysis</li>
        </ul>

        <p class="terms-paragraph">3.9 Data Processing Flow. The following image illustrates the process of code auditing and data flow within CodeDD:</p>

        <img 
          src={dataProcessFlowImage} 
          alt="Data Processing Flow" 
          className="terms-image responsive-image"
        />

        <p class="terms-paragraph">3.10 Detailed Processing Description. CodeDD implements the following measures and procedures in its data processing activities:</p>

        <ul class="terms-list">
        <li><strong>Storage of Source Code</strong>: Customer Data, including source code, is stored on servers (provided by <a href="https://www.ionos.com" target="_blank" rel="noopener noreferrer">IONOS</a>) solely for the purpose of conducting the audit. Upon completion of the audit, all data is permanently deleted.</li>
        
        <li><strong>AI-Powered Code Analysis</strong>: Source code files are sent to <strong>Claude 3.5</strong>, an AI model developed by <a href="https://www.anthropic.com" target="_blank" rel="noopener noreferrer">Anthropic</a>, via a commercial license agreement. This license is designed with strict data protection measures and prohibits any use of the data beyond the immediate analysis (e.g., for further training of the models). Data transmitted via API is encrypted and only temporarily made available to the model for inference purposes. This ensures that no natural person ever views the source code itself, including in the evaluation on CodeDD.</li>
        
        <li><strong>Security Measures</strong>: Existing technical security mechanisms protect against unauthorized access to the servers.</li>
        
        <li><strong>Remote Repository Access</strong>: Access to the remote repository is secured using SSH keys. The repository owner stores the public key on their code hosting account (e.g., GitHub). After the audit, this key can be immediately deleted, thereby revoking CodeDD's access. CodeDD does not store any sensitive login data for the hosting platform.</li>
        
        <li><strong>Data Protection Commitment</strong>: CodeDD (currently represented by its founder, as the company is not yet formally established) commits to protect User Data and Customer Data (including source code) to the best of its ability and not to disclose it to third parties (except for official partners – currently only Anthropic as described above). All data can be deleted and/or transferred at any time upon request by the Customer.</li>
        
        <li><strong>Intellectual Property Protection</strong>: Customer Data, including source code, is thus protected to the best possible extent. For extremely sensitive source code files (which typically do not include frontend files, normal backend scripts, etc.), these can be excluded from the audit upon Customer's request. Such excluded files will not be sent to Anthropic or reviewed. Any evaluation of these files will remain empty, and the files will be permanently deleted at the end of the audit along with all other Customer Data.</li>
        </ul>

        <p class="terms-paragraph">3.11 Data Minimization and Purpose Limitation. CodeDD adheres to the principles of data minimization and purpose limitation. We only process Customer Data to the extent necessary for conducting the code audit and providing our services as outlined in the Agreement. Any processing of Customer Data, including source code, is strictly limited to the purposes specified in this DPA and the Agreement.</p>

        <p class="terms-paragraph">3.12 Third-Party Access Restriction. Except as explicitly stated in this DPA regarding the use of Anthropic's AI model for code analysis, CodeDD does not grant access to Customer Data, including source code, to any third parties. Any future changes to this policy would require explicit consent from the Customer and an update to this DPA.</p>

        <p class="terms-paragraph">3.13 Transparency and Control. CodeDD is committed to maintaining transparency in its data processing activities. Customers have the right to request information about the processing of their data at any time. Furthermore, Customers retain control over their data and can request modification, deletion, or transfer of their data as outlined in this DPA and the Agreement.</p>

        <h3 class="terms-section-title">4. Security</h3>

        <p class="terms-paragraph">4.1 Security Measures. CodeDD implements and maintains appropriate technical and organizational measures designed to protect the security, confidentiality, integrity and availability of Customer Data and protect against Security Incidents. Customer is responsible for configuring the Products and using features and functionalities made available by CodeDD to maintain appropriate security in light of the nature of Customer Data. CodeDD's Trust Center describes its current technical and organizational measures. Customer acknowledges that the Security Measures are subject to technical progress and development and that CodeDD may update or modify the Security Measures from time to time, provided that such updates and modifications do not materially decrease the overall security of the Products during the Term.</p>

        <p class="terms-paragraph">4.2 Security Incidents. CodeDD will notify Customer without undue delay and, where feasible, no later than seventy-two (72) hours after becoming aware of a Security Incident. CodeDD will make reasonable efforts to identify the cause of the Security Incident, mitigate the effects and remediate the cause to the extent within CodeDD's reasonable control. Upon Customer's request and taking into account the nature of the Processing and the information available to CodeDD, CodeDD will assist Customer by providing information reasonably necessary for Customer to meet its Security Incident notification obligations under Applicable Data Protection Law. CodeDD's notification of a Security Incident is not an acknowledgment by CodeDD of its fault or liability.</p>

        <h3 class="terms-section-title">5. Sub-processing</h3>

        <p class="terms-paragraph">5.1 Authorization. Customer provides general authorization for CodeDD to engage Sub-processors to Process Customer Personal Data. CodeDD will: (i) enter into a written agreement with each Sub-processor imposing data protection terms that require the Sub-processor to protect Customer Personal Data to the standard required by Applicable Data Protection Law and to the same standard provided by this DPA; and (ii) remain liable to Customer for the acts and omissions of its Sub-processor to the same extent CodeDD would be liable if performing the task or service directly under this DPA. CodeDD maintains an up-to-date list of its Sub-processors in its Trust Center.</p>

        <p class="terms-paragraph">5.2 New Sub-processors. Periodically, CodeDD may engage new Sub-processors. CodeDD will give Customer at least fourteen (14) calendar days' notice prior to providing that Sub-processor with access to Customer Personal Data by updating the Trust Center accordingly ("Sub-processor Notice Period"). The Trust Center provides the option for Customer to subscribe to notifications of any new Sub-processors.</p>

        <p class="terms-paragraph">5.3 Objections. Customer may object to CodeDD's appointment of a new Sub-processor during the Sub-processor Notice Period. If Customer objects, Customer, as its sole and exclusive remedy, may terminate the applicable CodeDD order form for the affected Product in accordance with the terms of the relevant Agreement.</p>

        <h3 class="terms-section-title">6. Assistance and Cooperation Obligations</h3>

        <p class="terms-paragraph">6.1 Data Subject Rights. Taking into account the nature of the Processing, CodeDD will provide reasonable and timely assistance to Customer to enable Customer to respond to requests for exercising a data subject's rights (including rights of access, rectification, erasure, restriction, objection, and data portability) in respect to Customer Personal Data (each a "Data Subject Request"). Accordingly, CodeDD will refrain from responding to the data subject except to acknowledge receipt of the Data Subject Request and will redirect the data subject to make its request or objection directly to the Customer. CodeDD reserves the right to charge a mutually agreed fee for assistance rendered upon Customer's assistance demands.</p>

        <p class="terms-paragraph">6.2 Cooperation Obligations. Upon Customer's reasonable request, and taking into account the nature of the applicable Processing, CodeDD will provide reasonable assistance to Customer in fulfilling Customer's obligations under Applicable Data Protection Law (including data protection impact assessments and consultations with supervisory authorities), provided that Customer cannot reasonably fulfill such obligations independently with help of available Documentation. Requests under this Section 6.2 should be made to contact@codedd.ai or such other location as CodeDD may periodically make available on its Website. CodeDD reserves the right to charge a mutually agreed fee for assistance rendered upon Customer's assistance demands.</p>

        <p class="terms-paragraph">6.3 Third Party Requests. Unless prohibited by Law, CodeDD will promptly notify Customer of any valid, enforceable subpoena, warrant, or court order from law enforcement or public authorities compelling CodeDD to disclose Customer Personal Data. In the event that CodeDD receives an inquiry or a request for information from any other third party (such as a regulator or data subject) concerning the Processing of Customer Personal Data, CodeDD will redirect such inquiries to Customer, and will not provide any information unless required to do so under applicable law.</p>

        <h3 class="terms-section-title">7. Deletion of Customer Personal Data</h3>

        <p class="terms-paragraph">During the term of the Agreement, Customer and its Users may, through the features of the Products, access, retrieve or delete Customer Personal Data. Following expiration or termination of the Agreement, CodeDD will delete all Customer Personal Data. Notwithstanding the foregoing, CodeDD may retain Customer Personal Data (i) as required by Applicable Data Protection Law or (ii) in accordance with its standard backup or record retention policies, provided that, in either case, CodeDD will maintain the confidentiality of, and otherwise comply with the applicable provisions of this DPA with respect to retained Customer Personal Data and not further Process it except as required by Applicable Data Protection Law.</p>

        <h3 class="terms-section-title">8. Audit</h3>

        <p class="terms-paragraph">8.1 CodeDD will be regularly audited against the ISO 27001 standard (or equivalent). The audit may, in CodeDD's sole discretion, be an internal audit, or an audit performed by a third party. Upon written request, CodeDD will provide Customer with a summary of the audit report(s) ("Audit Information"), so that Customer can verify CodeDD's compliance with the audit standards and this DPA. Such Audit Information constitutes CodeDD's Confidential Information and Customer shall comply with its confidentiality obligations provided for in the Agreement with respect to any Audit Information.</p>

        <p class="terms-paragraph">8.2 Only to the extent Customer cannot reasonably satisfy CodeDD's compliance with this DPA through the exercise of its rights under Section 8.1 above, or where required by Applicable Data Protection Law or a supervisory authority, an independent and qualified third party auditor appointed by Customer and approved by CodeDD, may, at Customer's sole expense, audit, during the term of the relevant Agreement, the relevant applicable Personal Data processing activities to assess CodeDD's compliance with the terms of this DPA. Any audit will (i) be conducted during CodeDD's regular business hours, with reasonable advance written notice of at least sixty (60) calendar days (unless Applicable Data Protection Law or a supervisory authority requires a shorter notice period; (ii) be subject to reasonable confidentiality controls obligating Customer (and its authorized representatives) to maintain the confidentiality of all information obtained or observed during the audit; (iii) not unreasonably interfere with CodeDD's business activities; and (iv) be limited to once per year, unless a Security Incident has occurred, in which case Customer may conduct an additional audit.</p>

        <p class="terms-paragraph">keep confidential any information disclosed that, by its nature, should be confidential; and (iii) restrict its findings to only information relevant to Customer.</p>

        <h3 class="terms-section-title">Schedule 1: Description of Processing</h3>

        <p class="terms-paragraph">Categories of data subjects whose Personal Data is Processed: Customer and its Users.</p>

        <p class="terms-paragraph">Categories of Personal Data Processed: Account Data, Results Data, Usage Data, and Customer Personal Data.</p>

        <p class="terms-paragraph">Sensitive data transferred: Account Data, Results Data, and Usage Data do not contain data (and, as part of Customer Personal Data, Customer shall ensure that it does not provide any data) (i) revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, (ii) genetic data, biometric data Processed for the purposes of uniquely identifying a natural person, data concerning health, or data concerning a natural person's sex life or sexual orientation, or (iii) relating to criminal convictions and offenses (altogether "Sensitive Data").</p>

        <p class="terms-paragraph">The frequency of the transfer: Continuous.</p>

        <p class="terms-paragraph">Nature of the Processing: CodeDD will Process Personal Data in order to provide the Products and related Support in accordance with the Agreement, including this DPA.</p>

        <p class="terms-paragraph">Purpose(s) of the Processing:</p>

        <ul class="terms-list">
        <li>Customer Personal Data: CodeDD will Process Customer Personal Data as Processor in accordance with Customer's instructions as set out in Section 3.1 (Customer Instructions)</li>
        <li>Account Data, Results Data, and Usage Data: CodeDD will Process Account Data, Results Data, and Usage Data for the limited and specified purposes outlined in Section 2 (Scope).</li>
        </ul>

        <p class="terms-paragraph">Duration of Processing:</p>

        <ul class="terms-list">
        <li>Customer Personal Data: CodeDD will Process Customer Personal Data for the term of the Agreement as outlined in Section 7 (Deletion of Customer Personal Data).</li>
        <li>Account Data, Results Data, and Usage Data: CodeDD will Process Account Data, Results Data, and Usage Data only as long as required (a) to provide Products and related Support and Advisory Services to Customer in accordance with the Agreement; (b) for CodeDD's legitimate business purposes outlined in Section 2 (Scope); or (c) by applicable law(s).</li>
        </ul>

        <p class="terms-paragraph">Transfers to (Sub-)processors: CodeDD will transfer Customer Personal Data to Sub-processors as permitted in Section 5 (Sub-processing).</p>

        <h3 class="terms-section-title">Schedule 2: Region-Specific Terms</h3>

        <h4 class="terms-subsection-title">1. Europe, United Kingdom and Switzerland</h4>

        <p class="terms-paragraph">1.1 Customer Instructions. In addition to Section 3.1 (Customer Instructions) of the DPA above, CodeDD will Process Customer Personal Data only on documented instructions from Customer, including with regard to transfers of such Customer Personal Data to a third country or an international organization, unless required to do so by Applicable Data Protection Law to which CodeDD is subject; in such a case, CodeDD will inform Customer of that legal requirement before Processing, unless that law prohibits such information on important grounds of public interest. CodeDD will promptly inform Customer if it becomes aware that Customer's Processing instructions infringe Applicable Data Protection Law.</p>

        <p class="terms-paragraph">1.2 European Transfers. Where Personal Data protected by the EU Data Protection Law is transferred, either directly or via onward transfer, to a country outside of Europe that is not subject to an adequacy decision, the following applies:</p>

        <p class="terms-paragraph">(a) The EU SCCs are hereby incorporated into this DPA by reference as follows:</p>

        <ul class="terms-list">
        <li>Customer is the "data exporter" and CodeDD is the "data importer".</li>
        <li>Module One (Controller to Controller) applies where CodeDD is Processing Account Data, Results Data, or Usage Data.</li>
        <li>Module Two (Controller to Processor) applies where Customer is a Controller of Customer Personal Data and CodeDD is Processing Customer Personal Data as a Processor.</li>
        <li>Module Three (Processor to Processor) applies where Customer is a Processor of Customer Personal Data and CodeDD is Processing Customer Personal Data as another Processor.</li>
        <li>By entering into this DPA, each party is deemed to have signed the EU SCCs as of the commencement date of the Agreement.</li>
        </ul>

        <p class="terms-paragraph">(b) For each Module, where applicable:</p>

        <ul class="terms-list">
        <li>In Clause 7, the optional docking clause does not apply.</li>
        <li>In Clause 9, Option 2 applies, and the time period for prior notice of Sub-processor changes is stated in Section 4 (Sub-processing) of this DPA.</li>
        <li>In Clause 11, the optional language does not apply.</li>
        <li>In Clause 17, Option 1 applies, and the EU SCCs are governed by Irish law.</li>
        <li>In Clause 18(b), disputes will be resolved before the courts of Ireland.</li>
        <li>The Appendix of EU SCCs is populated as follows:
            <ul>
            <li>The information required for Annex I(A) is located in the Agreement and/or relevant CodeDD order forms.</li>
            <li>The information required for Annex I(B) is located in Schedule 1 (Description of Processing) of this DPA.</li>
            <li>The competent supervisory authority in Annex I(C) will be determined in accordance with Clauses 17, Option 1 and 18(b) above; and</li>
            <li>The information required for Annex II is located in CodeDD's Trust Center.</li>
            </ul>
        </li>
        </ul>

        <p class="terms-paragraph">1.3 Swiss Transfers. Where Personal Data protected by the Swiss FADP is transferred, either directly or via onward transfer, to any other country that is not subject to an adequacy decision, the EU SCCs apply as stated in Section 1.2 (European Transfers) above with the following modifications:</p>

        <ul class="terms-list">
        <li>All references in the EU SCCs to "Regulation (EU) 2016/679" will be interpreted as references to the Swiss FADP, and references to specific Articles of "Regulation (EU) 2016/679" will be replaced with the equivalent article or section of the Swiss FADP; all references to the EU Data Protection Law in this DPA will be interpreted as references to the FADP.</li>
        <li>In Clause 13, the competent supervisory authority is the Swiss Federal Data Protection and Information Commissioner.</li>
        <li>In Clause 17, the EU SCCs are governed by the laws of Switzerland.</li>
        <li>In Clause 18(b), disputes will be resolved before the courts of Switzerland.</li>
        <li>All references to Member State will be interpreted to include Switzerland and data subjects in Switzerland are not excluded from enforcing their rights in their place of habitual residence in accordance with Clause 18(c).</li>
        </ul>

        <p class="terms-paragraph">1.4 United Kingdom Transfers. Where Personal Data protected by the UK Data Protection Law is transferred, either directly or via onward transfer, to a country outside of the United Kingdom that is not subject to an adequacy decision, the following applies:</p>

        <p class="terms-paragraph">(a) The EU SCCs apply as set forth in Section 1.2 (European Transfers) above with the following modifications:</p>

        <ul class="terms-list">
        <li>Each party shall be deemed to have signed the UK Addendum.</li>
        <li>For Table 1 of the UK Addendum, the parties' key contact information is located in the Agreement and/or relevant CodeDD order forms.</li>
        <li>For Table 2 of the UK Addendum, the relevant information about the version of the EU SCCs, modules, and selected clauses which this UK Addendum is appended to is located above in Section 1.2 (European Transfers) of this Schedule.</li>
        <li>For Table 3 of the UK Addendum:
            <ul>
            <li>The information required for Annex 1A is located in the Agreement and/or relevant CodeDD order forms.</li>
            <li>The Information required for Annex 1B is located in Schedule 1 (Description of Processing) of this DPA.</li>
            <li>The information required for Annex II is located CodeDD's Trust Center; and</li>
            <li>The information required for Annex III is located in Section 4 (Sub-processing) of this DPA.</li>
            </ul>
        </li>
        </ul>

        <p class="terms-paragraph">(b) In Table 4 of the UK Addendum, "neither party" was selected.</p>

        <h4 class="terms-subsection-title">2. United States of America</h4>

        <p class="terms-paragraph">The following terms apply where CodeDD Processes Personal Data subject to the US State Privacy Laws:</p>

        <p class="terms-paragraph">2.1 To the extent Customer Personal Data includes personal information protected under US State Privacy Laws that CodeDD Processes as a Service Provider or Processor, on behalf of Customer, CodeDD will Process such Customer Personal Data in accordance with the US State Privacy Laws, including by complying with applicable sections of the US State Privacy Laws and providing the same level of privacy protection as required by US State Privacy Laws, and in accordance with Customer's written instructions, as necessary for the limited and specified purposes identified in Section 2.1 (Customer Personal Data) and Schedule 1 (Description of Processing) of this DPA. CodeDD will not:</p>

        <ul class="terms-list">
        <li>(a) retain, use, disclose or otherwise Process such Customer Personal Data for a commercial purpose other than for the limited and specified purposes identified in this DPA, the Agreement, and/or any related CodeDD order form, or as otherwise permitted under US State Privacy Laws;</li>
        <li>(b) "sell" or "share" such Customer Personal Data within the meaning of the US State Privacy Laws; and</li>
        <li>(c) retain, use, disclose or otherwise Process such Customer Personal Data outside the direct business relationship with Customer and not combine such Customer Personal Data with personal information that it receives from other sources, except as permitted under US State Privacy Laws.</li>
        </ul>

        <p class="terms-paragraph">2.2 CodeDD will inform Customer if it determines that it can no longer meet its obligations under US State Privacy Laws within the timeframe specified by such laws, in which case Customer may take reasonable and appropriate steps to prevent, stop, or remediate any unauthorized Processing of such Customer Personal Data.</p>

        <p class="terms-paragraph">2.3 To the extent Customer discloses or otherwise makes available Deidentified Data to CodeDD or to the extent CodeDD creates Deidentified Data from Customer Personal Data, in each case in its capacity as a Service Provider, CodeDD will:</p>

        <ul class="terms-list">
        <li>(a) adopt reasonable measures to prevent such Deidentified Data from being used to infer information about, or otherwise being linked to, a particular natural person or household;</li>
        <li>(b) publicly commit to maintain and use such Deidentified Data in a de-identified form and to not attempt to re-identify the Deidentified Data, except that CodeDD may attempt to re-identify such data solely for the purpose of determining whether its de-identification processes are compliant with the US State Privacy Laws; and</li>
        <li>(c) before sharing Deidentified Data with any other party, including Sub-processors, contractors, or any other persons ("Recipients"), contractually obligate any such Recipients to comply with all requirements of this Section 2.3 (including imposing this requirement on any further Recipients).</li>
        </ul>

        <h4 class="terms-subsection-title">3. Definitions</h4>

        <p class="terms-paragraph">3.1 Where Personal Data is subject to the laws of one the following regions, the definition of "Applicable Data Protection Law" includes:</p>

        <ul class="terms-list">
        <li>(a) Europe: (i) the Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the Processing of Personal Data and on the free movement of such data (General Data Protection Regulation, or GDPR) and (ii) the EU e-Privacy Directive (Directive 2002/58/EC) as amended, superseded or replaced from time to time ("EU Data Protection Law");</li>
        <li>(b) Singapore: the Singapore Personal Data Protection Act;</li>
        <li>(c) Switzerland: the Swiss Federal Act on Data Protection and its implementing regulations as amended, superseded, or replaced from time to time ("Swiss FADP");</li>
        <li>(d) The United Kingdom: the Data Protection Act 2018 and the GDPR as saved into United Kingdom law by virtue of Section 3 of the United Kingdom's European Union (Withdrawal) Act 2018 as amended, superseded or replaced from time to time ("UK Data Protection Law"); and</li>
        <li>(e) The United States: all state laws relating to the protection and Processing of Personal Data in effect in the United States of America, which may include, without limitation, the California Consumer Privacy Act, as amended by the California Privacy Rights Act, and its implementing regulations ("CCPA"), the Virginia Consumer Data Protection Act, the Colorado Privacy Act, the Connecticut Data Privacy Act, and the Utah Consumer Privacy Act ("US State Privacy Laws").</li>
        </ul>

        <p class="terms-paragraph">3.2. "Deidentified Data" means data that cannot reasonably be used to infer information about, or otherwise be linked to, a data subject.</p>

        <p class="terms-paragraph">3.3. "Europe" includes, for the purposes of this DPA, the Member States of the European Union and European Economic Area.</p>

        <p class="terms-paragraph">3.4. "EU SCCs" means the contractual clauses annexed to the European Commission's Implementing Decision 2021/914 of 4 June 2021 on standard contractual clauses for the transfer of Personal Data to third countries pursuant to Regulation (EU) 2016/679 of the European Parliament and of the Council, as amended, superseded, or replaced from time to time.</p>

        <p class="terms-paragraph">3.5. "Service Provider" has the same meaning as given in the CCPA.</p>

        <p class="terms-paragraph">3.6. "UK Addendum" means the International Data Transfer Addendum to the EU Commission Standard Contractual Clauses issued by the UK Information Commissioner, Version B1.0, in force 21 March 2022, as amended, superseded or replaced from time to time.</p>

        <p class="terms-paragraph">3.7. "US State Privacy Laws" means all state laws relating to the protection and Processing of Personal Data in effect in the United States of America, which may include, without limitation, the California Consumer Privacy Act, as amended by the California Privacy Rights Act, and its implementing regulations ("CCPA"), the Virginia Consumer Data Protection Act, the Colorado Privacy Act, the Connecticut Data Privacy Act, and the Utah Consumer Privacy Act.</p>

        <h3 class="terms-section-title">4. Contact</h3>

        <p class="terms-paragraph">If you have any questions about this DPA, please contact us at <a href="mailto:contact@codedd.ai"></a>  </p>

        </div>
    </div>
  );
};

export default DataProcessingAddendum;