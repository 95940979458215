// AuditSummary.js
import React, { useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import axiosInstance from '../axiosConfig';
import { Link } from 'react-router-dom';
import { useParams, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import { checkAuditStatus } from '../utils/Actions';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';
import { PrimeIcons } from 'primereact/api';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import DOMPurify from 'dompurify';
import GlobalSidebar from '../components/GlobalSidebar';
import TabMenuComponent from '../components/TabMenu';
import ToggleablePanel from '../components/ToggleablePanel';
import Header from '../components/Header';
import SliderWithScore from '../components/SliderWithScore';
import ChapterDivider from '../components/ChapterDivider';
import ScoreGraph from '../components/ScoresGraph';
import AttributeStatistics from '../components/AttributeStatistics';
import { useAuditAccess } from '../utils/AuditAccessContext';

import 'primereact/resources/themes/fluent-light/theme.css';
import './AuditSummary.css';

const domainLogos = {
  "github.com": '/images/GitHub.png',
  "gitlab.com": '/images/GitLab.png',
  "bitbucket.org": "/images/Bitbucket.png",
  "SourceForge.net": "/images/SourceForge.png",
  "code.google.com": "/images/GoogleCode.png",
  "codeplex.com": "/images/CodePlex.png",
  "launchpad.net": "/images/Launchpad.png",
  "savannah.gnu.org": "/images/Savannah.png",
  "freecode.com": "/images/Freecode.png",
  "gitkraken.com": "/images/GitKraken.png",
  "beanstalkapp.com": "/images/Beanstalk.png",
  "assembla.com": "/images/Assembla.png",
  "phabricator.com": "/images/Phabricator.png",
  "gogs.io": "/images/Gogs.png",
  "gitea.com": "/images/Gitea.png",
  "gitbucket.com": "/images/GitBucket.png",
  "codeberg.org": "/images/Codeberg.png",
    // ... other domains if necessary
};

const subChapters = [
  {
    title: 'Quality',
    divider: 'Overall Source Code Quality',
    divider_img: 'pi-check-circle',
    divider_text: "Source code quality*** encompasses factors like readability, consistency, and maintainability, crucial for efficient development and future scalability.",
    scoreAttribute: "overall_quality_score",
    overviewKey: 'general_quality_overview',
    points: [
      {
        name: 'Readability',
        scoreAttribute: 'readability_score',
        descriptionKey: 'average_readability'
      },
      {
        name: 'Consistency',
        scoreAttribute: 'consistency_score',
        descriptionKey: 'consistency_evaluation'
      },
      {
        name: 'Modularity and Maintainability',
        scoreAttribute: 'modularity_score',
        descriptionKey: 'modularity_maintainability'
      },
      {
        name: 'Reusability',
        scoreAttribute: 'reusability_score',
        descriptionKey: 'reusability_assessment'
      },
      {
        name: 'Redundancy and Technical Debt',
        scoreAttribute: 'redundancy_score',
        descriptionKey: 'redundancy_technical_debt'
      },
      {
        name: 'Code Smells',
        scoreAttribute: 'code_smells_score',
        descriptionKey: 'code_smells_issues'
      },
      {
        name: 'Improvement Recommendations',
        descriptionKey: 'improvement_recommendations'
      },
    ]
  },
  {
    title: 'Functionality',
    divider: 'Overall Functionality',
    divider_img: 'pi-list',
    divider_text: "Functionality assessment*** focuses on completeness, error handling, and edge case coverage, ensuring robust and reliable software.",
    scoreAttribute: "overall_functionality_score",
    overviewKey: 'functionality_summary',
    points: [
      {
        name: 'Completeness',
        scoreAttribute: 'completeness_score',
        descriptionKey: 'completeness_analysis'
      },
      {
        name: 'Edge Cases',
        scoreAttribute: 'edge_cases_score',
        descriptionKey: 'edge_cases_handling'
      },
      {
        name: 'Error Handling',
        scoreAttribute: 'error_handling_score',
        descriptionKey: 'error_handling_assessment'
      },
    ]
  },
  {
    title: 'Scalability',
    divider: 'Scalability and Performance',
    divider_img: 'pi-bolt',
    divider_text: "Scalability and performance*** evaluate the efficiency, resource utilization, and load handling, key to sustaining growing user demands.",
    scoreAttribute: "overall_performance_score",
    overviewKey: 'performance_scalability_summary',
    points: [
      {
        name: 'Efficiency',
        scoreAttribute: 'efficiency_score',
        descriptionKey: 'efficiency_analysis'
      },
      {
        name: 'Scalability',
        scoreAttribute: 'scalability_score',
        descriptionKey: 'scalability_assessment'
      },
      {
        name: 'Resource Utilization',
        scoreAttribute: 'resource_utilization_score',
        descriptionKey: 'resource_utilization'
      },
      {
        name: 'Load Handling',
        scoreAttribute: 'load_handling_score',
        descriptionKey: 'load_handling_capabilities'
      },
      {
        name: 'Parallel Processing',
        scoreAttribute: 'parallel_processing_score',
        descriptionKey: 'parallel_processing_evaluation'
      },
      {
        name: 'Database Interaction Efficiency',
        scoreAttribute: 'database_interaction_efficiency_score',
        descriptionKey: 'database_interaction_efficiency'
      },
      {
        name: 'Concurrency Management',
        scoreAttribute: 'concurrency_management_score',
        descriptionKey: 'concurrency_management_analysis'
      },
      {
        name: 'State Management Efficiency',
        scoreAttribute: 'state_management_efficiency_score',
        descriptionKey: 'state_management_efficiency'
      },
      {
        name: 'Modularity and Decoupling',
        scoreAttribute: 'modularity_decoupling_score',
        descriptionKey: 'modularity_decoupling_evaluation'
      },
      {
        name: 'Configuration and Customization Ease',
        scoreAttribute: 'configuration_customization_ease_score',
        descriptionKey: 'configuration_customization_ease'
      },
    ]
  },
  {
    title: 'Security',
    divider: 'Security and Vulnerabilities',
    divider_img: 'pi-shield',
    divider_text: "Security analysis*** covers input validation, data handling, and authentication measures, crucial for protecting against vulnerabilities and threats.",
    scoreAttribute: "overall_security_score",
    overviewKey: 'security_summary',
    points: [
      {
        name: 'Input Validation',
        scoreAttribute: 'input_validation_score',
        descriptionKey: 'input_validation_review'
      },
      {
        name: 'Data Handling',
        scoreAttribute: 'data_handling_score',
        descriptionKey: 'data_handling_practices'
      },
      {
        name: 'Authentication',
        scoreAttribute: 'authentication_score',
        descriptionKey: 'authentication_mechanisms'
      },
    ]
  },
  {
    title: 'Compatibility',
    divider: 'Compatibility and Portability',
    divider_img: 'pi-key',
    divider_text: "Compatibility and portability*** examine the software's ability to operate across various environments and platforms seamlessly.",
    scoreAttribute: "overall_architecture_score",
    overviewKey: "compatibility_summary",
    points: [
      {
        name: 'Independence and Flexibility',
        scoreAttribute: 'independence_score',
        descriptionKey: 'independence_flexibility'
      },
      {
        name: 'Integration Capabilities',
        scoreAttribute: 'integration_score',
        descriptionKey: 'integration_capabilities'
      },
    ]
  },
  {
    title: 'Documentation',
    divider: 'Documentation Quality',
    divider_img: 'pi-comment',
    divider_text: "Documentation quality*** is vital for maintainability, encompassing inline comments and comprehensive coverage for clarity and ease of use.",
    scoreAttribute: "overall_documentation_score",
    overviewKey: "documentation_coverage_clarity_summary", 
    points: [
      {
        name: 'Inline Comments',
        scoreAttribute: 'inline_comments_score',
        descriptionKey: 'inline_comments_quality'
      },
    ]
  },
  {
    title: 'Standards',
    divider: 'Adherence to Standards & Best Practices',
    divider_img: 'pi-thumbs-up',
    divider_text: "Adherence to standards and best practices*** ensures code quality, complexity management, and refactoring opportunities, aligning with industry norms.",
    scoreAttribute: "overall_standards_score",
    overviewKey: "standards_compliance_summary",
    points: [
      {
        name: 'Standards Compliance',
        scoreAttribute: 'standards_score',
        descriptionKey: 'adherence_to_standards'
      },
      {
        name: 'Design Patterns',
        scoreAttribute: 'design_patterns_score',
        descriptionKey: 'use_design_patterns'
      },
      {
        name: 'Code Complexity',
        scoreAttribute: 'code_complexity_score',
        descriptionKey: 'code_complexity_analysis'
      },
      {
        name: 'Refactoring Opportunities',
        scoreAttribute: 'refactoring_opportunities_score',
        descriptionKey: 'refactoring_opportunities'
      },
    ]
  },
];

// Add this function before the AuditSummary component
const generateStructuredData = (auditDetails, auditName, repoUrl) => {
  if (!auditDetails) return null;

  return {
    "@context": "https://schema.org",
    "@type": "TechArticle",
    "headline": `Code Audit Summary for ${auditName}`,
    "description": `Comprehensive code audit results for ${auditName}, analyzing code quality, security, and performance.`,
    "datePublished": new Date().toISOString(),
    "author": {
      "@type": "Organization",
      "name": "CodeDD"
    },
    "about": {
      "@type": "SoftwareSourceCode",
      "codeRepository": repoUrl,
      "programmingLanguage": auditDetails.primary_language || "Multiple",
      "abstract": `Code audit analysis covering quality metrics, security assessment, and performance evaluation for ${auditName}.`
    },
    "articleSection": [
      "Code Quality",
      "Security Analysis",
      "Performance Metrics",
      "Best Practices"
    ],
    "keywords": [
      "code audit",
      "software quality",
      "security assessment",
      "performance analysis",
      "code review"
    ]
  };
};

function AuditSummary() {
  const { auditUuid } = useParams();
  const dispatch = useDispatch();
  const { hasAccess, isPublic } = useAuditAccess(); // Get from context
  const [auditDetails, setAuditDetails] = useState(null);
  const [expandedSections, setExpandedSections] = useState(subChapters.map(() => false));
  const [auditStatus, setAuditStatus] = useState('');
  const [fileCount, setFileCount] = useState(0);
  const [auditName, setAuditName] = useState(''); 
  const [repoUrl, setRepoUrl] = useState(''); 
  const [repoDomain, setRepoDomain] = useState('');
  const auditAccessDetails = useSelector(state => state.audits[auditUuid]);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [loading, setLoading] = useState(true);

  // New state for SEO-related information
  const [metaDescription, setMetaDescription] = useState('');
  const [pageTitle, setPageTitle] = useState('');

  const auditStatusData = [
    { status: "Audit started", icon: "pi pi-search", description: "File screening has started. This process involves screening of every file and extracting relevant information."},
    { status: "File auditing completed", icon: "pi pi-android", description: "File auditing completed successfully. Each file has been analyzed, you can start exploring the results in the Flags, Dependencies and Files tab." },
    { status: "Scores calculated", icon: "pi pi-star", description: "Overall scores have been calculated based on each audit element (e.g. Readability, Security, Documentation, etc)." },
    { status: "Contextualization completed", icon: "pi pi-align-center", description: "This process aimes at the bigger picture - understanding the entire code base in general terms of Quality, Security or Standards." },
    { status: "Consolidation completed", icon: "pi pi-check-circle", description: "The consolidation of all relevant information has concluded and the final report is being prepared." },
  ];

  const [auditStats, setAuditStats] = useState(null);

  useEffect(() => {

    if (!auditAccessDetails) {
      return;
    }

    const canAccess = auditAccessDetails.hasAccess || auditAccessDetails.isPublic;

    if (!canAccess) {
      setShouldRedirect(true);
    } else {
      setShouldRedirect(false);
    }
  }, [auditAccessDetails, hasAccess, isPublic, auditUuid]);

  useEffect(() => {
    dispatch(checkAuditStatus(auditUuid));
  }, [dispatch, auditUuid]);
  
  const toggleSection = (index) => {
    setExpandedSections(prevState =>
      prevState.map((expanded, idx) => (idx === index ? !expanded : expanded))
    );
  };

  
  // Fetch audit details only if we have access
  useEffect(() => {
    if (!auditAccessDetails || (!auditAccessDetails.hasAccess && !auditAccessDetails.isPublic)) {
      return;
    }

    let interval;
    let mounted = true;

    const fetchAuditDetails = async () => {
      try {
        const response = await axiosInstance.get(`audit_details/${auditUuid}/`);
        
        if (mounted) {
          const { audit_status, name, file_count, full_url } = response.data;
          
          // Update all states in a single batch
          Promise.all([
            setAuditDetails(response.data),
            setAuditStatus(audit_status),
            setFileCount(file_count || 0),
            setAuditName(name || ''),
            setRepoUrl(full_url || ''),
            setLoading(false)
          ]).then(() => {
            // Set domain after other states are updated
            if (full_url) {
              try {
                const url = new URL(full_url);
                setRepoDomain(url.hostname.toLowerCase());
              } catch (error) {
                console.error('Error parsing URL:', error);
                const domainMatch = full_url.match(/^https?:\/\/([^/?#]+)/);
                setRepoDomain(domainMatch ? domainMatch[1].toLowerCase() : '');
              }
            }
          });
        }
      } catch (error) {
        console.error('Error fetching audit details:', error);
        if (mounted) {
          setLoading(false);
          if (error.response?.status === 401) {
            setShouldRedirect(true);
          }
        }
      }
    };

    fetchAuditDetails();
    
    // Only set up polling if audit is not completed
    if (auditStatus !== "Audit completed") {
      interval = setInterval(fetchAuditDetails, 15000);
    }

    return () => {
      mounted = false;
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [auditUuid, auditAccessDetails, auditStatus]);

  useEffect(() => {
    const fetchAuditStats = async () => {
      // Skip data fetch if no access
      if (!auditAccessDetails || (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess)) {
        return;
      }
      try {
        
        const response = await axiosInstance.get(`audit_statistics/${auditUuid}/`);
        if (response.data && response.data.statistics) {
          setAuditStats(response.data.statistics);
        }
      } catch (error) {
        console.error('Error fetching audit statistics:', error);
      }
    };

    if (isAuditCompleted()) {
      fetchAuditStats();
    }
  }, [auditUuid, auditStatus, auditAccessDetails]);

  // Update meta information when audit details change
  useEffect(() => {
    if (auditDetails && auditName) {
      const description = `Comprehensive code audit analysis for ${auditName}, evaluating code quality (${auditDetails.overall_quality_score}), security (${auditDetails.overall_security_score}), and performance (${auditDetails.overall_performance_score}).`;
      const title = `Code Audit Results: ${auditName}`;
      setMetaDescription(description);
      setPageTitle(title);
    }
  }, [auditDetails, auditName]);

  if (shouldRedirect) {
    return <Navigate to="/" replace />;
  }
  

  // Function to map auditStatus to timeline data
  const mapAuditStatusToTimeline = (currentStatus) => {
    let foundCurrentStatus = false;
    return auditStatusData.map((statusData, index) => {
      const isCurrent = statusData.status === currentStatus;
      // Mark the next item after the current status
      const isNext = !isCurrent && foundCurrentStatus && index === auditStatusData.findIndex(data => data.status === currentStatus) + 1;
      if (isCurrent) foundCurrentStatus = true;
  
      return {
        ...statusData,
        completed: auditStatusData.findIndex(data => data.status === currentStatus) >= index,
        isNext, // Add isNext to determine the styling for the next item
      };
    });
  };

  const isAuditFailed = auditStatus === "Audit failed";

  const headerProps = {
    auditName: auditName,
    fileCount: auditDetails?.file_count || 0,
    repoUrl: repoUrl,
    repoDomain: repoDomain,
    domainLogos: domainLogos
  };

  const timelineData = mapAuditStatusToTimeline(auditStatus);

  // Function to check if the audit is completed
  const isAuditCompleted = () => {
    const completed = Boolean(
      auditStatus === "Audit completed" &&
      auditDetails &&
      auditDetails.file_count > 0 &&
      auditDetails.name
    );

    return completed;
  };

  
  function formatDividerText(text) {
    if (!text) return null;

    const parts = text.split("***");
    const boldPart = parts[0];
    const normalPart = parts[1] || "";

    return (
      <>
        <strong>{boldPart}</strong>{normalPart}
      </>
    );
  }

  // Updated customizedMarker function to apply pulsating effect
  const customizedMarker = (item) => {
    const markerClass = item.completed ? 'completedMarker' : 'pendingMarker';
    const nextMarkerClass = item.isNext ? 'nextMarkerPulsating' : ''; // Add class for pulsating effect
    return (
      <span className={`custom-marker ${markerClass} ${nextMarkerClass}`}>
        <i className={item.icon}></i>
      </span>
    );
  };
  
  // Updated customizedContent function to change the border for the next item
  const customizedContent = (item) => {
    const cardClass = item.completed ? 'completedCard' : 'pendingCard';
    const nextCardBorderClass = item.isNext ? 'nextCardBorder' : ''; // Add class for next item border
    return (
        <Card title={item.status} className={`timeline-card ${cardClass} ${nextCardBorderClass}`}>
            <p className="item-description">{item.description}</p>
        </Card>
    );
  };


  if (loading) {
    return (
      <div className="audit-summary-container">
        <Header {...headerProps} />
        <TabMenuComponent />
        <div className="summary-loader-container">
          <div className="summary-loader"></div>
        </div>
      </div>
    );
  }

  if (!isAuditCompleted()) {
    return (
      <div className="audit-summary-container">
        <Header {...headerProps} />
        <TabMenuComponent />
        <h1 className="audit-summary-title">
          {auditStatus === "Audit completed" ? "Loading audit results..." : "Your audit has started"}
        </h1>
        <p className="audit-info-message">
          {auditStatus === "Audit completed" 
            ? "Please wait while we prepare your results..."
            : "It will take some time to complete the audit. You can follow the progress here."}
        </p>
        {auditStatus !== "Audit completed" && (
          <p className="audit-email-notification" style={{ fontWeight: 'bold'}}>
            You will receive an email as soon as the audit has finished.
          </p>
        )}
        <div className="timeline-layout">
          <GlobalSidebar />
          <Timeline 
            value={timelineData} 
            align="alternate" 
            className="customized-timeline" 
            marker={customizedMarker} 
            content={customizedContent} 
          />
        </div>
      </div>
    );
  }

  // Function to render HTML content in the text output
  const renderHTMLContent = (content) => {
    // Transform **text** into <strong>text</strong>
    let transformedContent = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Convert line breaks to <br>
    transformedContent = transformedContent.replace(/\n/g, '<br/>');

    // Sanitize HTML
    const sanitizedContent = DOMPurify.sanitize(transformedContent);

    return { __html: sanitizedContent };
  };

  // Main render for completed audit
  return (
    <div>
      <Helmet>
        <title>{pageTitle || `Code Audit Results`}</title>
        <meta name="description" content={metaDescription || `Code audit analysis evaluating code quality, security, and performance metrics.`} />
        <meta property="og:title" content={pageTitle || `Code Audit Results`} />
        <meta property="og:description" content={metaDescription || `Comprehensive code audit analysis`} />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {JSON.stringify(generateStructuredData(auditDetails, auditName, repoUrl))}
        </script>
      </Helmet>
      
      <GlobalSidebar />
      <div className="audit-summary-container">
        <Header {...headerProps} />
        <TabMenuComponent />
        <div className="page-layout">
          {isAuditFailed && (
            <div className="error-message-container">
              <h2>Audit Failed</h2>
              <p>Unfortunately, the audit process has failed. Please check your inputs and try again. If the issue persists, contact our support team for assistance.</p>
              <Button label="Contact Support" className="p-button-danger" onClick={() => {/* logic to contact support */}} />
            </div>
          )}
          {loading && (
            <div className="summary-loader-container">
                <div className="summary-loader"></div>
            </div>
          )}
          {/* Overview and ScoreGraph section */}
          <div className="overview-section">
            <div className="left-frame">
              <div className="main-chapter-title">Overview</div>
            </div>
            <div className="middle-frame">
              <div className="main-chapter-description">
                This is an average representation of all the <strong>{fileCount} files audited</strong>. For a detailed view, explore the&nbsp;
                <Link to={`/${auditUuid}/files`} style={{ color: '#32AFC3', textDecoration: 'underline' }}>
                  Files
                </Link>
                &nbsp;tab to assess each file individually.
              </div>

              <div className="graph-frame">
              <ScoreGraph
                scoresData={[
                  { title: 'Quality', scoreAttribute: 'overall_quality_score' },
                  { title: 'Functionality', scoreAttribute: 'overall_functionality_score' },
                  { title: 'Scalability', scoreAttribute: 'overall_performance_score' },
                  { title: 'Security', scoreAttribute: 'overall_security_score' },
                  { title: 'Compatibility', scoreAttribute: 'overall_architecture_score' },
                  { title: 'Documentation', scoreAttribute: 'overall_documentation_score' },
                  { title: 'Standards', scoreAttribute: 'overall_standards_score' },
                ]}
                auditDetails={auditDetails}
              />

              </div>

              <div>
              <ToggleablePanel header="General Purpose">
                <p className="m-0" dangerouslySetInnerHTML={renderHTMLContent(auditDetails.general_purpose)}></p>
              </ToggleablePanel>
              </div>
              <div>
              <ToggleablePanel header="General Domain">
                <p className="m-0" dangerouslySetInnerHTML={renderHTMLContent(auditDetails.general_domain)}></p>
              </ToggleablePanel>
              </div>

              
            </div>
          </div>
  
          {/* Code Review and its content section */}
          <div className="code-review-section">
            <div className="left-frame">
              <div className="main-chapter-title">Code Review</div>
            </div>
            <div className="middle-frame-2">

              {/* Sub-chapters */}
              {subChapters.map((subChapter, index) => (
                <div key={index} className="sub-chapter-container">
                  {/* Existing Divider code */}
                  {subChapter.divider && (
                    <ChapterDivider
                      title={subChapter.divider}
                      icon={PrimeIcons[subChapter.divider_img]}
                    />
                  )}
                  <p className="sub-chapter-divider-text">
                    {formatDividerText(subChapter.divider_text)}
                  </p>
                  <div className="sub-chapter-header">
                    <h3 className="sub-chapter-title">{subChapter.title}</h3>
                    <SliderWithScore
                      scoreAttribute={subChapter.scoreAttribute}
                      name={subChapter.title}
                      isMainChapter={true}
                      auditDetails={auditDetails}
                    />
                  </div>
                  <div className="sub-chapter-description">
                    <span>
                    <strong>Remarks:</strong> <span dangerouslySetInnerHTML={renderHTMLContent(auditDetails[subChapter.overviewKey])}></span>
                    </span>
                  </div>
                  <Divider>
                    <Button
                      label={expandedSections[index] ? 'Hide Details' : 'More Details'}
                      className={`p-button-outlined custom-toggle-button ${expandedSections[index] ? 'expanded' : ''}`}
                      onClick={() => toggleSection(index)}
                    />
                  </Divider>
                  {expandedSections[index] && (
                    <div className="points-container">
                      {subChapter.points.map((point, pointIndex) => (
                        <div key={pointIndex} className="point-item">
                          <div className="point-header">
                            <h4 className="point-title">{point.name}</h4>
                            {point.scoreAttribute && (
                              <SliderWithScore
                                scoreAttribute={point.scoreAttribute}
                                name={point.name}
                                isMainChapter={false}
                                auditDetails={auditDetails}
                              />
                            )}
                          </div>
                          {auditStats && (
                            <AttributeStatistics
                              attributeName={point.name}
                              statistics={auditStats}
                            />
                          )}
                          <div className="point-description">
                            <span>
                              <strong>Remarks:</strong>{' '}
                              <span dangerouslySetInnerHTML={renderHTMLContent(auditDetails[point.descriptionKey])}></span>
                            </span>
                          </div>
                          
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}

              <ToggleablePanel header="Recommendations">
                <p className="m-0" dangerouslySetInnerHTML={renderHTMLContent(auditDetails.recommendations)}></p>
              </ToggleablePanel>
            </div>
          </div>
          {/* New container for bottom sections */}
          <div className="bottom-sections-container">
          <section className="about-audit">
            <h2>About this Audit</h2>
            <p>This code audit provides a comprehensive analysis of the repository's quality, security, and performance. It helps identify potential issues and areas for improvement.</p>
            <p>Original Repository: <a href={repoUrl} target="_blank" rel="noopener noreferrer">{repoUrl}</a></p>
          </section>

          <section className="faq">
            <h2>Frequently Asked Questions</h2>
            <details>
              <summary>What is a code audit?</summary>
              <p>A code audit is a comprehensive review of a software project's source code. It aims to identify potential bugs, security vulnerabilities, and areas for improvement in code quality and performance.</p>
            </details>
            <details>
              <summary>How can I use the results of this audit?</summary>
              <p>You can use the results of this audit to prioritize improvements in your codebase. Focus on areas with lower scores, address security concerns, and implement the recommendations provided to enhance overall code quality and maintainability.</p>
            </details>
            <details>
              <summary>How often should I conduct a code audit?</summary>
              <p>The frequency of code audits depends on your project's size and complexity. For actively developed projects, conducting audits quarterly or bi-annually is recommended. For smaller or less frequently updated projects, annual audits may suffice.</p>
            </details>
            <details>
              <summary>Can I compare this audit with previous ones?</summary>
              <p>Yes, comparing audits over time can provide valuable insights into your project's evolution. Look for improvements in scores and check if previously identified issues have been resolved.</p>
            </details>
          </section>
      </div>
    </div>
    </div>
  </div>
);
}

export default AuditSummary;
