import React from 'react';
import './AttributeStatistics.css';

const scoreMap = {
    "readability": {"highly readable": 100, "moderately readable": 50, "low readability": 0},
    "consistency": {"highly consistent": 100, "somewhat inconsistent": 50, "not consistent": 0},
    "modularity": {"excellent": 100, "average": 50, "poor": 0},
    "maintainability": {"high": 100, "moderate": 50, "low": 0},
    "reusability": {"high": 100, "moderate": 50, "low": 0},
    "redundancy": {"no redundancies": 100, "some redundancies": 50, "high redundancy": 0},
    "technical_debt": {"none": 100, "low": 66, "moderate": 33, "high": 0},
    "code_smells": {"none": 100, "low": 66, "moderate": 33, "high": 0},

    "completeness": {"fully functional": 100, "partially functional": 50, "not functional": 0},
    "edge_cases": {"excellently covered": 100, "partially covered": 66, "poorly covered": 33, "none covered": 0},
    "error_handling": {"robust": 100, "adequate": 50, "poor": 0},

    "efficiency": {"high": 100, "average": 50, "poor": 0},
    "scalability": {"high": 100, "moderate": 50, "not scalable": 0},
    "resource_utilization": {"optimal": 100, "acceptable": 50, "excessive": 0},
    "load_handling": {"excellent": 100, "good": 66, "average": 33, "poor": 0},
    "parallel_processing": {"fully supported": 100, "partially supported": 50, "not supported": 0},
    "database_interaction_efficiency": {"optimized": 100, "sufficient": 50, "inefficient": 0},
    "concurrency_management": {"robust": 100, "adequate": 50, "poor": 0},
    "state_management_efficiency": {"optimal": 100, "adequate": 50, "problematic": 0},
    "modularity_decoupling": {"highly modular": 100, "somewhat modular": 50, "monolithic": 0},
    "configuration_customization_ease": {"flexible": 100, "moderate": 50, "rigid": 0},

    "input_validation": {"strong": 100, "adequate": 50, "weak": 0},
    "data_handling": {"secure": 100, "moderately secure": 50, "insecure": 0},
    "authentication": {"robust": 100, "adequate": 50, "non-existent": 0},
    "dependencies": {"updated & secure": 100, "some vulnerabilities": 50, "outdated & vulnerable": 0},

    "independence": {"multi-platform": 100, "limited platforms": 50, "single platform": 0},
    "integration": {"seamless": 100, "requires workarounds": 50, "incompatible": 0},

    "inline_comments": {"comprehensive": 100, "adequate": 66, "sparse": 33, "none": 0},

    "standards": {"fully compliant": 100, "partially compliant": 50, "non-compliant": 0},
    "design_patterns": {"extensive": 100, "moderate": 66, "rare": 33, "none": 0},
    "code_complexity": {"low": 100, "moderate": 50, "high": 0},
    "refactoring_opportunities": {"many": 100, "some": 66, "few": 33, "none": 0},
};

const attributeNameMapping = {
  "Readability": "readability",
  "Consistency": "consistency",
  "Modularity and Maintainability": "modularity",
  "Reusability": "reusability",
  "Redundancy and Technical Debt": "redundancy",
  "Code Smells": "code_smells",
  "Improvement Recommendations": "improvement_recommendations",

  "Completeness": "completeness",
  "Edge Cases": "edge_cases",
  "Error Handling": "error_handling",

  "Efficiency": "efficiency",
  "Scalability": "scalability",
  "Resource Utilization": "resource_utilization",
  "Load Handling": "load_handling",
  "Parallel Processing": "parallel_processing",
  "Database Interaction Efficiency": "database_interaction_efficiency",
  "Concurrency Management": "concurrency_management",
  "State Management Efficiency": "state_management_efficiency",
  "Modularity and Decoupling": "modularity_decoupling",
  "Configuration and Customization Ease": "configuration_customization_ease",

  "Input Validation": "input_validation",
  "Data Handling": "data_handling",
  "Authentication": "authentication",
  "Dependencies": "dependencies",

  "Independence and Flexibility": "independence",
  "Integration Capabilities": "integration",

  "Inline Comments": "inline_comments",

  "Standards Compliance": "standards",
  "Design Patterns": "design_patterns",
  "Code Complexity": "code_complexity",
  "Refactoring Opportunities": "refactoring_opportunities"
};

const AttributeStatistics = ({ attributeName, statistics }) => {
  
  // Map the display name to the backend name
  const backendAttributeName = attributeNameMapping[attributeName] || attributeName.toLowerCase().replace(/ /g, '_');
  
  if (!statistics || !statistics[backendAttributeName]) {
    return null;
  }

  // Helper function to get color based on score
  const getColorClass = (value) => {
    const attributeNameLower = backendAttributeName.toLowerCase();
    const valueLower = value.toLowerCase();
    
    const scores = scoreMap[attributeNameLower];
    if (!scores) {
      return 'stat-grey';
    }

    // Find the matching score by comparing lowercase values
    const score = scores[Object.keys(scores).find(key => key.toLowerCase() === valueLower)];
    if (score === undefined) return 'stat-grey';
    if (score >= 66) return 'stat-green';
    if (score >= 33) return 'stat-orange';
    return 'stat-red';
  };

  // Sort values based on their scores in the map
  const sortedStats = Object.entries(statistics[backendAttributeName])
    .sort(([valueA], [valueB]) => {
      const scoresForAttr = scoreMap[backendAttributeName.toLowerCase()] || {};
      const scoreA = scoresForAttr[Object.keys(scoresForAttr).find(key => key.toLowerCase() === valueA.toLowerCase())] || 0;
      const scoreB = scoresForAttr[Object.keys(scoresForAttr).find(key => key.toLowerCase() === valueB.toLowerCase())] || 0;
      return scoreB - scoreA;
    });

  return (
    <div className="attribute-statistics">
      <span className="stat-label">Info:</span>
      {sortedStats.map(([value, count], index) => (
        <React.Fragment key={value}>
          <span className="stat-count">{count}</span> files marked as <span className={`stat-value ${getColorClass(value)}`}>{value}</span>
          {index < sortedStats.length - 1 && <span className="stat-separator">, </span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AttributeStatistics;