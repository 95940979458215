import React from 'react';
import './Header.css';

const Header = ({ auditName, fileCount, repoUrl, repoDomain, domainLogos }) => {

  const logoUrl = repoDomain && domainLogos && domainLogos[repoDomain];

  return (
    <div className="header">
      {repoUrl && (
        <a href={repoUrl} target="_blank" rel="noopener noreferrer">
          {logoUrl && (
            <img 
              src={`${process.env.PUBLIC_URL}${logoUrl}`}
              alt={repoDomain || 'Repository'} 
              onError={(e) => {
                console.error('Image failed to load:', e.target.src);
                // Optionally set a fallback image
                // e.target.src = `${process.env.PUBLIC_URL}/images/default-repo.png`;
              }}
            />
          )}
          <div className="header-text">
            <div className={auditName ? "audit-name" : "auditing_loader"}>
              {auditName || ''}
            </div>
            <div className="repository-url">{repoUrl}</div>
            <div className="file-count">{fileCount} files audited</div>
          </div>
        </a>
      )}
    </div>
  );
};

export default Header;