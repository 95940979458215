import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Timeline } from 'primereact/timeline';
import { Helmet } from 'react-helmet-async';
import './Platform.css';
import PlatformDemo from './PlatformDemo';
import gitRepositoryImage from '../../images/frontpage/git_repository.svg';
import ExecutiveSummaryImage from '../../images/frontpage/executive_summary.svg';
import AIPowerfulImage from '../../images/frontpage/ai-powerful.svg';
import FlagsImage from '../../images/frontpage/flags.svg';
import DependenciesImage from '../../images/frontpage/dependencies.svg';
import FileLevelInsightsImage from '../../images/frontpage/files_report.svg';
import ConsolidationImage from '../../images/frontpage/consolidation.svg';
import { codeSnippets } from './codeSnippets';

const PlatformHero = () => {
  const navigate = useNavigate();

  const handleStartTrial = () => {
    navigate('/register');
  };

  const handleLearnPricing = () => {
    navigate('/pricing');
  };

  return (
    <div className="platform-hero">
      <div className="platform-hero-content">
        <div className="platform-hero-text">
          <h3>Easy, complete and secure</h3>
          <div className="hero-title">
            <div className="gradient-wrapper">
              <span className="hero-gradient-text">AI-</span>
              <span className="hero-gradient-text">powered</span>
            </div>
            <h1>Code Auditing Platform</h1>
          </div>
          <p className="hero-description-platform">
            Transform your technical due diligence process with advanced AI analysis, 
            delivering comprehensive insights while protecting intellectual property
          </p>
        </div>
        
        <div className="hero-cta">
          <button 
            className="cta-primary-button"
            onClick={handleStartTrial}
          >
            Get started for free
          </button>
          <h2 
            className="pricing-link"
            onClick={handleLearnPricing}
          >
            See plans & pricing
          </h2>
        </div>

        <div className="platform-demo-container">
          <PlatformDemo />
        </div>
      </div>
    </div>
  );
};

const ModelSection = () => {
  const timelineEvents = [
    {
      title: "Codebase-wide insights",
      description: "CodeDD provides a holistic view of your codebase, including code quality, security vulnerabilities, and technical debt.",
      icon: "pi pi-code"
    },
    {
      title: "Easy to understand, actionable and secure reports",
      description: "Codedd breaks the complexity of technical due diligence into easy digestable reports, graphs and context.",
      icon: "pi pi-file"
    }
  ];

  const customContent = (item) => (
    <div className="model-point">
      <span className="point-title">{item.title}</span>
      <span className="point-text">{item.description}</span>
    </div>
  );

  return (
    <section className="model-section">
      <div className="platform-hero-content">
        <div className="model-grid">
          <div className="model-text-left">
            <h2 style={{fontSize: '2rem', fontWeight: '400'}}>Powered by Large Language Models</h2>
            <p>Rely on the most advanced AI models to review and contextualize your codebase. Built on improved versions of <a href="https://www.anthropic.com/news/claude-3-5-sonnet" className="model-link">Anthropic's Claude 3.5 Sonnet</a> and OpenAI o1 models, our platform excels at coding analytics.</p>
          </div>
          <div className="model-text-right">
            <h2 style={{fontSize: '2rem', fontWeight: '400'}}>AI-powered code auditing</h2>
            <p>Let the entire code base be audited by our AI models - each single file to contextualized views of the codebase.</p>
            <div className="timeline-container">
              <Timeline 
                value={timelineEvents} 
                content={customContent}
                className="custom-timeline"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const CodeScroll = () => {
  return (
    <div className="code-scroll-container">
      <img src={AIPowerfulImage} alt="AI Powerful" className="ai-powerful-image" />
      <div className="code-scroll-gradient-container">
        <pre className="code-scroll">
          {codeSnippets.join('\n\n')}
          {codeSnippets.join('\n\n')} {/* Repeat to ensure continuous scroll */}
        </pre>
      </div>
    </div>
  );
};

const ConsolidationSection = () => {
  const navigate = useNavigate();

  const handleContactSales = () => {
    navigate('/contact-sales');
  };

  return (
    <section className="consolidation-section">
      <div className="consolidation-container">
        <div className="consolidation-header">
          <h2>Want to increase investment security?</h2>
          <h3>Consolidate your due diligence process today.</h3>
        </div>
        
        <div className="consolidation-content">
          <div className="consolidation-left">
            <div className="consolidation-benefits">
              <div className="consolidation-benefit">
                <i className="pi pi-check-circle"></i>
                <span>Get the complete picture</span>
              </div>
              <div className="consolidation-benefit">
                <i className="pi pi-check-circle"></i>
                <span>Get actionable insights within minutes</span>
              </div>
              <div className="consolidation-benefit">
                <i className="pi pi-check-circle"></i>
                <span>Increase security for you and the startup</span>
              </div>
              <div className="consolidation-benefit">
                <i className="pi pi-check-circle"></i>
                <span>Lower total cost of the due diligence process</span>
              </div>
              <div className="consolidation-benefit">
                <i className="pi pi-check-circle"></i>
                <span>Reduce admin burden for everyone involved</span>
              </div>
            </div>
            
            <div className="consolidation-cta">
              <h4>Don't know where to start?</h4>
              <p>Our sales team can help guide you.</p>
              <button className="intro-cta-button" onClick={handleContactSales}>
                Talk to sales
              </button>
            </div>
          </div>

          <div className="consolidation-image-container">
            <div className="consolidation-image-gradient"></div>
            <div className="consolidation-image">
              <img src={ConsolidationImage} alt="Consolidation" className="consolidation-image-svg" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PricingSection = () => {
  const navigate = useNavigate();

  const handlePerAuditModelClick = () => {
    navigate('/pricing/per-audit-model');
  };

  const handlePremiumClick = () => {
    navigate('/pricing/premium');
  };

  return (
    <section className="pricing-section">
      <div className="pricing-content">
        <div className="pricing-header">
          <div className="pricing-text">
            <h2>Find out which pricing model works best for you and your team</h2>
          </div>
          <div className="pricing-links">
            <button 
              className="pricing-link-button"
              onClick={handlePerAuditModelClick}
            >
              <span>Why per-audit model?</span>
              <i className="pi pi-arrow-right"></i>
            </button>
            <button 
              className="pricing-link-button"
              onClick={handlePremiumClick}
            >
              <span>Why CodeDD Premium?</span>
              <i className="pi pi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const Platform = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="platform-page">
      <Helmet>
        {/* Primary Meta Tags */}
        <title>CodeDD Platform | AI-powered Code Auditing & Analysis</title>
        <meta name="title" content="CodeDD Platform | AI-powered Code Auditing & Analysis" />
        <meta name="description" content="Streamline your technical due diligence with our AI-powered platform. Analyze repositories, get executive summaries, review security flags, and track dependencies - all while protecting intellectual property." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.codedd.ai/platform" />
        <meta property="og:title" content="CodeDD Platform | AI-powered Code Auditing & Analysis" />
        <meta property="og:description" content="Streamline your technical due diligence with our AI-powered platform. Get comprehensive code analysis, security insights, and dependency tracking while protecting intellectual property." />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.codedd.ai/platform" />
        <meta name="twitter:title" content="CodeDD Platform | AI-powered Code Auditing & Analysis" />
        <meta name="twitter:description" content="Streamline your technical due diligence with our AI-powered platform. Get comprehensive code analysis, security insights, and dependency tracking while protecting intellectual property." />
        
        {/* Additional Meta Tags */}
        <meta name="keywords" content="Code Auditing Platform, AI Code Analysis, Repository Analysis, Security Flag Review, Dependency Tracking, Technical Due Diligence, Executive Summary, Software Analysis" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="7 days" />
      </Helmet>

      <PlatformHero />

      <section className="features-section">
        <div className="platform-hero-content">
          <span className="features-tag">Features</span>
          <h1 className="features-title">
            Contextual. Unbiased.<br />
            AI-powerful.
          </h1>
          <div className="features-image-container">
            <CodeScroll />
            <div className="features-browser-background"></div>
            <div className="features-background-shape-top"></div>
            <div className="features-background-shape"></div>
            <div className="features-background-shape-bottom"></div>
          </div>
        </div>
      </section>

      <ModelSection />

      <section className="feature-list-section">
        <div className="platform-hero-content">
          <div className="feature-list-platform">
            <div className="feature-item-platform">
              <div className="feature-text-platform">
                <h2>Audit any repository</h2>
                <p>Audit any repository from any provider, no matter how large or complex with support for all common programming languages.</p>
              </div>
              <div className="feature-image-container-platform">
                <div className="feature-image-gradient-platform gradient-1"></div>
                <img 
                  src={gitRepositoryImage}
                  alt="Git Repository"
                  className="feature-image-platform"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="feature-item-platform">
              <div className="feature-text-platform">
                <h2>40+ parameters executive summary</h2>
                <p>Get a quick overview of the codebase on more than 40 distinct topics. Each topic is explained in a way that is easy to understand and actionable.</p>
              </div>
              <div className="feature-image-container-platform">
                <div className="feature-image-gradient-platform gradient-2"></div>
                <img 
                  src={ExecutiveSummaryImage}
                  alt="Executive Summary"
                  className="feature-image-platform-executive-summary"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="feature-item-platform">
              <div className="feature-text-platform">
                <h2>Review security flags</h2>
                <p>Review security flags and get a detailed report on the security vulnerabilities in the codebase. Each flag will be assessed with an estimated time to fix.</p>
              </div>
              <div className="feature-image-container-platform">
                <div className="feature-image-gradient-platform gradient-3"></div>
                <img 
                  src={FlagsImage}
                  alt="Flags"
                  className="feature-image-platform-flags"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="feature-item-platform">
              <div className="feature-text-platform">
                <h2>View all project dependencies</h2>
                <p>Get a detailed overview of the external dependencies in the codebase, including their licenses, vulnerabilities and more. Each dependency comes with additional context via a growing database of +2M software packages.</p>
              </div>
              <div className="feature-image-container-platform">
                <div className="feature-image-gradient-platform gradient-4"></div>
                <img 
                  src={DependenciesImage}
                  alt="Dependencies"
                  className="feature-image-platform-dependencies"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="feature-item-platform">
              <div className="feature-text-platform">
                <h2>Dive into file-level insights</h2>
                <p>Each file is analyzed in detail, providing a comprehensive overview of the entire codebase - without revealing a single line of code.</p>
              </div>
              <div className="feature-image-container-platform">
                <div className="feature-image-gradient-platform gradient-5"></div>
                <img 
                  src={FileLevelInsightsImage}
                  alt="File Level Insights"
                  className="feature-image-platform-file-level-insights"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <ConsolidationSection />

      <PricingSection />

    </div>
  );
};

export default Platform;
