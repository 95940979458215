import React, { useState, useRef, useEffect } from 'react';
import { useParams, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { AuditAccessProvider } from './AuditAccessContext';
import GlobalSidebar from '../components/GlobalSidebar';
import { useAuditAccess } from '../hooks/useAuditAccess';

const AuditRoute = () => {
    const { auditUuid } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const navigationCheckComplete = useRef(false);
    
    const { canAccess, isChecking, isPublic } = useAuditAccess(auditUuid);

    useEffect(() => {
        if (!isChecking && !navigationCheckComplete.current) {
            console.log('AuditRoute: Access check complete', {
                canAccess,
                isPublic,
                pathname: location.pathname
            });

            if (canAccess || isPublic) {
                setIsLoading(false);
                navigationCheckComplete.current = true;
            } else {
                navigate('/dashboard', { replace: true });
            }
        }
    }, [canAccess, isChecking, isPublic, navigate, location.pathname]);

    if (isLoading || isChecking) {
        return (
            <>
                <GlobalSidebar />
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            </>
        );
    }

    return (
        <AuditAccessProvider>
            <GlobalSidebar />
            <Outlet />
        </AuditAccessProvider>
    );
};

export default AuditRoute;
