import axiosInstance from '../axiosConfig';
import { selectAuth } from '../features/user/userSlice';

// Action Type
export const SET_AUDIT_STATUS = 'SET_AUDIT_STATUS';
export const SET_AUDIT_ERROR = 'SET_AUDIT_ERROR';

// Action creators
export const setAuditStatus = (payload) => ({
  type: SET_AUDIT_STATUS,
  payload
});

export const setAuditError = (payload) => ({
  type: SET_AUDIT_ERROR,
  payload
});

// Thunk for checking audit status
export const checkAuditStatus = (auditUuid) => async (dispatch, getState) => {
  // Get accountUuid from Redux store
  const { accountUuid } = selectAuth(getState());
  
  try {
    const response = await axiosInstance.get(
      `is_uuid_path_public/`,
      {
        params: { 
          audit_uuid: auditUuid,
          account_uuid: accountUuid 
        }
      }
    );

    // Transform the backend response to our Redux state format
    const payload = { 
      uuid: auditUuid,
      isPublic: response.data.publicity,
      auditStatus: response.data.auditStatus,
      hasAccess: response.data.hasAccess,
      isManager: response.data.isManager
    };

    dispatch(setAuditStatus(payload));

    return payload;

  } catch (error) {
    dispatch(setAuditError({ 
      uuid: auditUuid,
      error: error.message
    }));
    throw error;
  }
};