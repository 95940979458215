// files.js
import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../axiosConfig';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { useParams, Navigate } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { Editor } from 'primereact/editor';
import { useDispatch, useSelector } from 'react-redux'; // Import Redux hooks
import { checkAuditStatus } from '../utils/Actions'; // Correct import path
import GlobalSidebar from '../components/GlobalSidebar';
import TabMenuComponent from '../components/TabMenu';
import Header from '../components/Header';
import FileDetailSidebar from './fileDetailSidebar';

import './Files.css';

const domainLogos = {
    "github.com": '/images/GitHub.png',
    "gitlab.com": '/images/GitLab.png',
    "bitbucket.org": "/images/Bitbucket.png",
    "SourceForge.net": "/images/SourceForge.png",
    "code.google.com": "/images/GoogleCode.png",
    "codeplex.com": "/images/CodePlex.png",
    "launchpad.net": "/images/Launchpad.png",
    "savannah.gnu.org": "/images/Savannah.png",
    "freecode.com": "/images/Freecode.png",
    "gitkraken.com": "/images/GitKraken.png",
    "beanstalkapp.com": "/images/Beanstalk.png",
    "assembla.com": "/images/Assembla.png",
    "phabricator.com": "/images/Phabricator.png",
    "gogs.io": "/images/Gogs.png",
    "gitea.com": "/images/Gitea.png",
    "gitbucket.com": "/images/GitBucket.png",
    "codeberg.org": "/images/Codeberg.png",
      // ... other domains if necessary
  };

export default function FilesView() {

    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const [treeData, setTreeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { auditUuid } = useParams();
    const auditAccessDetails = useSelector(state => state.audits[auditUuid]); // Redux state for access control

    const [auditName, setAuditName] = useState(''); 
    const [repoUrl, setRepoUrl] = useState(''); 
    const [repoDomain, setRepoDomain] = useState('');
    const [fileCount, setFileCount] = useState(0);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [selectedNodeKey, setSelectedNodeKey] = useState(null);
    const [editorContent, setEditorContent] = useState('');

    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [selectedFileDetails, setSelectedFileDetails] = useState({});
    const [expandedRows, setExpandedRows] = useState({});
  

    const fileDetailAttributes = {
    "Code Quality": ["readability", "consistency", "modularity", "maintainability", "reusability", "redundancy", "technical_debt", "code_smells"],
    "Functionality": ["completeness", "edge_cases", "error_handling"],
    "Performance & Scalability": ["efficiency", "scalability", "resource_utilization", "load_handling", "parallel_processing", "database_interaction_efficiency", "concurrency_management", "state_management_efficiency", "modularity_decoupling", "configuration_customization_ease"],
    "Security": ["input_validation", "data_handling", "authentication", "package_dependencies", "flag_color"],
    "Compatibility": ["independence", "integration"],
    "Documentation": ["inline_comments"],
    "Coding Standards": ["standards", "design_patterns", "code_complexity", "refactoring_opportunities"]
    };
  
    const initialSelectedAttributes = {
    "Code Quality": [],
    "Functionality": [],
    "Performance & Scalability": [],
    "Security": [],
    "Compatibility": [],
    "Documentation": [],
    "Coding Standards": []
    };

    const [selectedAttributes, setSelectedAttributes] = useState(initialSelectedAttributes);


    useEffect(() => {
      if (!auditAccessDetails) {
        console.log("Audit details are not available yet.");
      } else if (auditAccessDetails.error) {
        console.error('Audit access error:', auditAccessDetails.error);
        setShouldRedirect(true);
      } else if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
        // Only redirect if the audit is private AND user doesn't have access
        setShouldRedirect(true);
      } else {
        setShouldRedirect(false);
      }
    }, [auditAccessDetails]);

  useEffect(() => {
    let isActive = true;

    const checkStatus = async () => {
      if (!auditUuid) return;
      
      try {
        await dispatch(checkAuditStatus(auditUuid));
      } catch (error) {
        console.error('Error checking audit status:', error);
      }
    };

    checkStatus();

    return () => {
      isActive = false;
    };
  }, [auditUuid]);


  useEffect(() => {
    let isActive = true;

    const fetchAuditAndFilesData = async () => {
      if (!auditUuid || !auditAccessDetails) return;

      // Skip if we don't have access
      if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
        return;
      }

      try {
        setLoading(true);
        
        const [auditDetailsResponse, filesResponse] = await Promise.all([
          axiosInstance.get(`audit_details/${auditUuid}/`),
          axiosInstance.get(`api/file_list/${auditUuid}/`)
        ]);

        if (!isActive) return;

        // Process audit details
        const auditData = auditDetailsResponse.data;
        setAuditName(auditData.name);
        setRepoUrl(auditData.full_url);
        setFileCount(auditData.file_count);

        const domain = auditData.full_url && new URL(auditData.full_url).hostname;
        setRepoDomain(domain || 'Unknown');

        // Processing file list
        const files = filesResponse.data;
        const formattedData = formatAndSortData(files);

        // Fetch additional details for each file if needed
        const filesWithDetails = await Promise.all(formattedData.map(file => {
          if (!file.children || file.children.length === 0) {
            // It's a file, not a folder, fetch its details
            const filePath = encodeURIComponent(file.data.file_path);
            return axiosInstance.get(`api/file_detail/${auditUuid}/${filePath}/`)
                .then(res => ({
                    ...file,
                    data: {
                        ...file.data,
                        size: res.data.lines_of_code + res.data.lines_of_doc,
                        type: res.data.file_type,
                        ...res.data
                    }
                }))
                .catch(error => {
                    console.error('Error fetching file details:', error);
                    return file; // Return the original file data in case of an error
                });
          }
          return Promise.resolve(file); // It's a folder, return as is
        }));

        setTreeData(filesWithDetails);
        setIsDataLoaded(true);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        if (isActive) {
          setLoading(false);
          setIsDataLoaded(true);
        }
      }
    };

    fetchAuditAndFilesData();

    return () => {
      isActive = false;
    };
  }, [auditUuid, auditAccessDetails]);

  useEffect(() => {
    let isActive = true;

    const fetchComments = async () => {
      if (!auditUuid || !auditAccessDetails) return;
      
      if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
        return;
      }

      try {
        const response = await axiosInstance.get(`api/get_file_selection_comments/${auditUuid}/`);
        
        if (!isActive) return;
        
        if (response.data && response.data.comments.length > 0) {
          setEditorContent(response.data.comments[0]);
        } else {
          setEditorContent('');
        }
      } catch (error) {
        console.error('Error fetching file comments:', error);
      }
    };

    fetchComments();

    return () => {
      isActive = false;
    };
  }, [auditUuid, auditAccessDetails]);

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  
  const formatAndSortData = (data, basePath = '') => {
    // Input validation
    if (!Array.isArray(data)) {
      console.error('Invalid data format received:', data);
      return [];
    }

    return data.map(node => {
      if (!node || typeof node !== 'object') {
        console.warn('Invalid node format:', node);
        return null;
      }

      try {
        // The incoming data already has the structure we need, so we'll preserve it
        // while adding any missing properties
        return {
          ...node,
          key: node.key || encodeURIComponent(node.data.fullPath.replace(/^\//, '')),
          data: {
            ...node.data,
            isFolder: Array.isArray(node.children) && node.children.length > 0,
            // Ensure required properties exist
            name: node.data.name || '',
            fullPath: node.data.fullPath || '',
            file_path: node.data.file_path || null,
            // Add default values for size and type if they don't exist yet
            size: node.data.size || 0,
            type: node.data.type || (Array.isArray(node.children) && node.children.length > 0 ? 'folder' : 'file')
          },
          // Recursively format children if they exist
          children: node.children ? formatAndSortData(node.children, node.data.fullPath) : []
        };
      } catch (error) {
        console.error('Error processing node:', error, node);
        return null;
      }
    })
    .filter(Boolean) // Remove any null entries from invalid nodes
    .sort((a, b) => {
      try {
        if (a.data.isFolder && !b.data.isFolder) return -1;
        if (!a.data.isFolder && b.data.isFolder) return 1;
        return (a.data.name || '').localeCompare(b.data.name || '');
      } catch (error) {
        console.error('Error sorting nodes:', error, { a, b });
        return 0;
      }
    });
  };

  const headerProps = isDataLoaded ? {
      auditName: auditName,
      fileCount: fileCount,
      repoUrl: repoUrl,
      repoDomain: repoDomain,
      domainLogos: domainLogos
    } : {};

  // Adjust fetchFileDetails function to accept a node and update its data directly
  const fetchFileDetails = async (node) => {
    
    if (node.data.detailsFetched) {
        // Details have already been fetched, no need to fetch again
        return node;
    }

    const filePath = encodeURIComponent(node.data.file_path);
    try {
        const response = await axiosInstance.get(`api/file_detail/${auditUuid}/${filePath}/`);
        const details = response.data;

        // Mark that details have been fetched to avoid refetching in the future
        node.data.detailsFetched = true;

        // Update the node data with fetched details
        node.data = {
            ...node.data,
            size: details.lines_of_code + details.lines_of_doc,
            type: details.file_type,
            ...details,
        };
    } catch (error) {
        console.error('Error fetching file details:', error);
    }
};


  const renderAttributeSelection = () => {
    return (
        <>
            <div className="adjust-table-title">Add data colums</div>
            <div className="adjust-table-text">You can adjust the data shown in your file table. Simply select additional fields. </div>
            <div className="adjust-table-container">
                <div className="adjust-table-content">
                    {Object.entries(fileDetailAttributes).map(([category, attributes]) => (
                        <div key={category} className="attribute-dropdown">
                            <span className="p-float-label w-full">
                                <MultiSelect 
                                    value={selectedAttributes[category]} 
                                    options={attributes.map(attr => ({ label: attr, value: attr }))}
                                    onChange={(e) => handleAttributeSelectionChange(e, category)}
                                    optionLabel="label"
                                    maxSelectedLabels={3}
                                    className="w-full"
                                    id={`ms-${category.replace(/\s+/g, '')}`} // Remove spaces
                                />
                                <label htmlFor={`ms-${category.replace(/\s+/g, '')}`} className="custom-multiselect-label">
                                  {category}
                                </label>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};


const handleAttributeSelectionChange = (e, category) => {
  setSelectedAttributes({
      ...selectedAttributes,
      [category]: e.value
  });
};

const renderReadOnlyEditor = () => {
  if (!editorContent) return null;
  return (
      <div className="file_comment_card">
          <Editor value={editorContent} readOnly={true} headerTemplate={<span className="file_selection_title">Comments on file selection</span>} style={{ height: '320px' }} />
      </div>
  );
};

// Recursive utility function to update node children by key
const updateNodeChildren = (nodes, key, updatedChildren) => {
  return nodes.map((node) => {
      if (node.key === key) {
          return { ...node, children: updatedChildren };
      } else if (node.children) {
          return { ...node, children: updateNodeChildren(node.children, key, updatedChildren) };
      }
      return node;
  });
};

const renderColumns = () => {
    const columns = [
        <Column key="audited" header="Audited" body={node => (
            node.data.selected_for_audit ? 
            <i className="pi pi-eye" style={{ color: '#2CB392' }}></i> : 
            null
        )} style={{ width: '90px', minWidth: '90px' }} />,
        <Column key="name" field="name" header="Name" expander body={node => (
          node.data.isFolder ? <span>{node.data.name}</span> : <span className="file-name">{node.data.name}</span>
        )} style={{ minWidth: '200px' }} />,
        <Column key="size" field="size" header="Size" style={{ minWidth: '100px' }} />,
        <Column key="type" field="type" header="Type" body={(node) => node.data.isFolder ? 'Folder' : node.data.type} style={{ minWidth: '100px' }}/>
        // ... other columns
    ];
  
    // Aggregate all selected attributes from each category into a single array
    // and apply a minWidth for each dynamically added column
    const allSelectedAttributes = Object.values(selectedAttributes).flat();
  
    allSelectedAttributes.forEach(attribute => {
        columns.push(<Column key={attribute} field={attribute} header={attribute} style={{ minWidth: '150px' }} />);
    });
  
    return columns;
};

  const onSelect = (event) => {
    const node = event.node;
    if (node.data.isFolder) {
      // If it's a folder, update its expanded state in the expandedRows
      const newExpandedRows = { ...expandedRows };
      if (newExpandedRows[node.key]) {
        delete newExpandedRows[node.key]; // Collapse if it was expanded
      } else {
        newExpandedRows[node.key] = true; // Expand if it was collapsed
      }
      setExpandedRows(newExpandedRows);
    } else {
      // If it's a file, fetch its details and open the sidebar
      fetchFileDetails(node);
      setSelectedFileDetails(node.data);
      setSidebarVisible(true);
    }
  };

  const onUnselect = () => {
    setSidebarVisible(false);;
  };

  const onExpand = async (event) => {
    // Check if the expanded node is a folder and if it has children that need details fetched
    if (event.node.data.isFolder && event.node.children) {
      setLoading(true); // Start loading animation or indicator
  
      // Fetch details for each child node that is a file and hasn't fetched details yet
      const childrenUpdates = await Promise.all(event.node.children.map(child => {
        if (!child.data.detailsFetched && !child.data.isFolder) {
          return fetchFileDetails(child);
        }
        return Promise.resolve(child); // Return child unchanged if it's a folder or details are already fetched
      }));
  
      // Update the children of the node with new data
      const updatedChildren = event.node.children.map((child, index) => {
        return { ...child, ...childrenUpdates[index] };
      });
  
      // Update the whole tree data to reflect these changes
      const updatedNodes = updateNodeChildren(treeData, event.node.key, updatedChildren);
      setTreeData(updatedNodes);
  
      setLoading(false); // End loading animation or indicator
    }
  };

return (
  <div>
      <GlobalSidebar />
      <Header {...headerProps} />
      <TabMenuComponent />
      <div className="files-page-layout" style={{ overflowX: 'auto', width: '100%' , tableLayout: 'auto'}}>
        <FileDetailSidebar visible={sidebarVisible} onClose={() => setSidebarVisible(false)} fileDetails={selectedFileDetails}/>
        {renderAttributeSelection()}
        {loading && (
                <div className="file-loader-container">
                    <div className="file-loader"></div>
                </div>
        )}
        {treeData.length === 0 && !loading && (
          <div>No files available</div>
        )}
        <TreeTable 
          value={treeData} 
          lazy 
          onExpand={onExpand} 
          loading={loading} 
          selectionMode="single" 
          selectionKeys={selectedNodeKey} 
          expandedRows={expandedRows}
          onSelectionChange={(e) => setSelectedNodeKey(e.value)} 
          metaKeySelection={false} 
          onSelect={onSelect} 
          onUnselect={onUnselect} 
          style={{paddingLeft: '2rem'}}
        >
          {renderColumns()}
        </TreeTable>
    </div>
    {renderReadOnlyEditor()}
  </div>
);
}

