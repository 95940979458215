import React, { useState, useCallback, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import axiosInstance from '../../axiosConfig';

const AuditSearch = ({ 
    accountUuid, 
    onSearchResults, 
    fetchAudits, 
    limit,
    setOffset,
    setTotalItems,
    setHasLoadedMore,
    fetchAuditDetails,
    setHasSearched,
    setCurrentSearchQuery,
    onSearchReference
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchLength, setSearchLength] = useState(0);
    const [error, setError] = useState(null);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [lastSearchedQuery, setLastSearchedQuery] = useState('');
    const offset = 0;

    const searchHandlerRef = useRef(null);

    const validateQuery = (query) => {
        if (!query || typeof query !== 'string') return false;
        
        if (query.length < 2 && query.length > 0) {
            setError('Please enter at least 2 characters');
            return false;
        }
        if (/[{}"'\\]/.test(query)) {
            setError('Invalid characters detected');
            return false;
        }
        setError(null);
        return true;
    };

    const handleSearch = useCallback(async (query, loadMore = false) => {
        if (!query || typeof query !== 'string') return;
        
        const searchStr = query.trim();
        if (!searchStr) return;
        
        if (!loadMore && searchStr === lastSearchedQuery) return;
        if (!validateQuery(searchStr)) return;

        const newOffset = loadMore ? offset + limit : 0;
        setIsSearching(true);
        setHasSearched(true);
        setError(null);
        setCurrentSearchQuery(searchStr);

        try {
            const response = await axiosInstance.get('search_audits/', {
                params: {
                    query: searchStr,
                    account_uuid: accountUuid,
                    offset: newOffset,
                    limit: limit
                }
            });
            
            setLastSearchedQuery(searchStr);

            if (response.data.status === 'error') {
                setError(response.data.message);
                return;
            }

            const fetchedAudits = response.data.audits_data || [];
            const fetchedGroupAudits = response.data.group_audits_data || [];

            const auditsWithDetails = await Promise.all(fetchedAudits.map(async audit => {
                const details = await fetchAuditDetails(audit.audit_uuid);
                return { 
                    ...audit, 
                    ...details  // This will spread all the scores into the audit object
                };
            }));

            onSearchResults(auditsWithDetails, fetchedGroupAudits, loadMore);
            setTotalItems(response.data.total_audits);
            
            if (loadMore) {
                setOffset(newOffset);
                setHasLoadedMore(true);
            }
            
        } catch (error) {
            setError(error.response?.data?.message || 'Error performing search');
            console.error('Error searching audits:', error);
        } finally {
            setIsSearching(false);
        }
    }, [accountUuid, limit, onSearchResults, setOffset, setTotalItems, setHasLoadedMore, fetchAuditDetails, setHasSearched, lastSearchedQuery, setCurrentSearchQuery]);

    useEffect(() => {
        searchHandlerRef.current = handleSearch;
        if (onSearchReference) {
            onSearchReference((...args) => searchHandlerRef.current?.(...args));
        }
    }, []);

    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        if (searchQuery && typeof searchQuery === 'string' && searchQuery.trim()) {
            const newTimeout = setTimeout(() => {
                setOffset(0);
                handleSearch(searchQuery);
            }, 1000);
            setSearchTimeout(newTimeout);
        }

        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        };
    }, [searchQuery]);

    return (
        <div className="search-container">
            <div className="search-input-wrapper">
                <span className="p-input-icon-left">
                    <InputText
                        value={searchQuery}
                        onChange={(e) => {
                            const input = e.target.value;
                            if (input.length <= 50) {
                                const trimmedInput = input.trim();
                                setSearchQuery(trimmedInput);
                                setSearchLength(trimmedInput.length);
                                
                                if (!trimmedInput) {
                                    setHasSearched(false);
                                    setError(null);
                                    setCurrentSearchQuery('');
                                    setLastSearchedQuery('');
                                    fetchAudits(false);
                                }
                            }
                        }}
                        placeholder="Search audits..."
                        className={`search-input ${
                            searchLength >= 45 ? 'near-limit' : ''
                        } ${searchLength >= 50 ? 'at-limit' : ''} ${
                            error ? 'p-invalid' : ''
                        }`}
                        maxLength={50}
                        tooltip={`${searchLength}/50 characters`}
                        tooltipOptions={{ position: 'top' }}
                        aria-label="Search audits"
                    />
                </span>
                {isSearching && <ProgressSpinner style={{width: '20px', height: '20px', marginLeft: '10px'}} />}
            </div>
            {error && (
                <Message 
                    severity="error" 
                    text={error}
                    className="search-error-message"
                />
            )}
        </div>
    );
};

export default AuditSearch;
