import React from 'react';
import DemoScoreGraph from '../demo/DemoScoresGraph';
import './PlatformDemo.css';

// Mock data for the demo
const mockScoresData = [
  { title: 'Quality', scoreAttribute: 'quality' },
  { title: 'Functionality', scoreAttribute: 'functionality' },
  { title: 'Scalability', scoreAttribute: 'scalability' },
  { title: 'Security', scoreAttribute: 'security' },
  { title: 'Compatibility', scoreAttribute: 'compatibility' },
  { title: 'Documentation', scoreAttribute: 'documentation' },
  { title: 'Standards', scoreAttribute: 'standards' }
];

// Main chapter scores from section_scores
const mockAuditDetails = {
  quality: 94,
  functionality: 44,
  scalability: 56,
  security: 0,
  compatibility: 100,
  documentation: 66,
  standards: 54
};

// Parameters matching the API response format and score_map values
const mockParameters = {
  // Code Quality (67)
  readability: "highly readable",
  consistency: "highly consistent",
  modularity: "excellent",
  maintainability: "high",
  reusability: "high",
  redundancy: "no redundancies",
  technical_debt: "low",
  code_smells: "low",

  // Functionality (44)
  completeness: "fully functional",
  edge_cases: "poorly covered",
  error_handling: "poor",

  // Scalability/Performance (53)
  efficiency: "average",
  scalability: "moderate",
  resource_utilization: "acceptable",
  load_handling: "average",
  parallel_processing: "not required",
  database_interaction_efficiency: "not required",
  concurrency_management: "not required",
  state_management_efficiency: "not required",
  modularity_decoupling: "highly modular",
  configuration_customization_ease: "moderate",

  // Security (0)
  input_validation: "weak",
  data_handling: "not required",
  authentication: "not required",

  // Compatibility (100)
  independence: "multi-platform",
  integration: "seamless",

  // Documentation (50)
  inline_comments: "adequate",

  // Standards (46)
  standards: "partially compliant",
  design_patterns: "none",
  code_complexity: "low",
  refactoring_opportunities: "some"
};

const PlatformDemo = () => {
  return (
    <div className="platform-demo-container">
      <div className="demo-content">
        <DemoScoreGraph
          scoresData={mockScoresData}
          auditDetails={mockAuditDetails}
          parameters={mockParameters}
        />
      </div>
    </div>
  );
};

export default PlatformDemo; 