// List of public endpoints
export const PUBLIC_ENDPOINTS = [
    '/validate_pass_key/',
    '/set_csrf_token/',
    '/resend_pass_key/',
    '/reset-password/',
    '/register/',
    '/login/'
];

// Function to check if a URL path contains an audit UUID
export const getAuditUuidFromPath = (url) => {
    // Skip if url is for audit_status endpoint
    if (url.includes('/audit_status/')) {
        return null;
    }

    const parts = url.split('/');
    // Look for UUID pattern in URL parts
    const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    for (const part of parts) {
        if (UUID_REGEX.test(part)) {
            return part;
        }
    }
    return null;
}; 