import React, { useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../features/user/userSlice';
import { checkAuditStatus } from '../utils/Actions';
import { TieredMenu } from 'primereact/tieredmenu';
import axiosInstance from '../axiosConfig';
import AuditScoreBar from '../user_dashboard/components/AuditScoreBar';
import '../user_dashboard/components/AuditScoreBar.css';

import './RepositoryCard.css';

const RepositoryCard = ({ repositories, userRole, fetchRepositories, groupAuditUuid, auditStarted, auditFinished, isLoading, error }) => {
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [selectedAuditUuid, setSelectedAuditUuid] = useState('');
    const navigate = useNavigate();
    const userAuth = useSelector(selectAuth);
    const dispatch = useDispatch();
    // Create a single ref for the menu refs object
    const menuRefs = useRef({});
    
    // Initialize menu refs once when component mounts or repositories change
    useEffect(() => {
        const newRefs = {};
        repositories.forEach(repo => {
            if (!menuRefs.current[repo.auditUuid]) {
                newRefs[repo.auditUuid] = React.createRef();
            }
        });
        menuRefs.current = { ...menuRefs.current, ...newRefs };
    }, [repositories]);

    const [auditStatuses, setAuditStatuses] = useState({});

    // Define a mapping from audit status to progress percentage
    const auditStatusToProgress = {
        "File information imported": 0,
        "Audit started": 10,
        "Scores calculated": 50,
        "Contextualization completed": 80,
        "Audit completed": 100,
    };
    const [showProgressBar, setShowProgressBar] = useState({});

    useEffect(() => {
        // Initialize the audit statuses only once when the audit starts
        if (!auditStarted) {
            const initialStatuses = repositories.reduce((acc, repo) => {
                acc[repo.auditUuid] = repo.auditStatus || 'Pending'; // 'Pending' as a fallback status
                return acc;
            }, {});
            setAuditStatuses(initialStatuses);
        }
    }, [repositories, auditStarted]);

    useEffect(() => {

        if (auditStarted) {

        const fetchAllAuditStatuses = async () => {
            const statuses = {...auditStatuses}; // Start with the current statuses
            const progressBarDisplay = {};
    
            // Fetch status for each repository
            for (const repo of repositories) {
                try {
                    const response = await axiosInstance.get(`audit_status/`, {
                        params: { audit_uuid: repo.auditUuid },
                    });
                    statuses[repo.auditUuid] = response.data.audit_status;
                    // Determine if ProgressBar should be shown based on the fetched status
                    const showProgress = ["Audit started", "Scores calculated", "Contextualization completed", "Audit completed"].includes(response.data.audit_status);
                    progressBarDisplay[repo.auditUuid] = showProgress;

                } catch (error) {
                    console.error("Error getting audit status for UUID:", repo.auditUuid, error);
                    // Optionally handle error, e.g., by setting a status indicating failure
                }
            }
            setAuditStatuses(statuses); // Update state with all fetched statuses
            setShowProgressBar(progressBarDisplay); // Update state with ProgressBar display status
        };
    
        // Call once initially and then set an interval
        fetchAllAuditStatuses();
        let intervalId;
        if (!auditFinished) {
            intervalId = setInterval(fetchAllAuditStatuses, 10000); // Continue fetching periodically
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId); // Cleanup interval on component unmount
            }
        };
    }
    }, [auditStarted, auditFinished, repositories]);

    const getDomainLogo = (url) => {
        const domainLogos = {
            "github.com": '/images/GitHub.png',
            "gitlab.com": '/images/GitLab.png',
            "bitbucket.org": "/images/Bitbucket.png",
            "SourceForge.net": "/images/SourceForge.png",
            "code.google.com": "/images/GoogleCode.png",
            "codeplex.com": "/images/CodePlex.png",
            "launchpad.net": "/images/Launchpad.png",
            "savannah.gnu.org": "/images/Savannah.png",
            "freecode.com": "/images/Freecode.png",
            "gitkraken.com": "/images/GitKraken.png",
            "beanstalkapp.com": "/images/Beanstalk.png",
            "assembla.com": "/images/Assembla.png",
            "phabricator.com": "/images/Phabricator.png",
            "gogs.io": "/images/Gogs.png",
            "gitea.com": "/images/Gitea.png",
            "gitbucket.com": "/images/GitBucket.png",
            "codeberg.org": "/images/Codeberg.png",
            // ... other domains if necessary
        };
        const domain = new URL(url).hostname;
        return domainLogos[domain] || '/images/default-logo.png'; // Path to a default logo
    };

    // Function to handle button click
    const handleButtonClick = async (repo) => {
        const accountUuid = userAuth.accountUuid;
        
        if (!accountUuid) {
            console.error('No account UUID found');
            navigate('/login');
            return;
        }

        try {
            // Check access and wait for the result
            const accessResult = await dispatch(checkAuditStatus(repo.auditUuid, accountUuid));

            if (!accessResult.hasAccess) {
                console.error('No access to this audit');
                navigate('/dashboard');
                return;
            }

            // Determine the target path based on audit status
            const targetStatuses = ["Opening", "Audit session created", "Repository structure imported", "File information imported"];
            const isTargetStatus = targetStatuses.includes(accessResult.auditStatus);
            
            // No need to pass state since we can get audit status and access info from Redux
            // and user info from auth context in the target components
            const path = isTargetStatus 
                ? `/${repo.auditUuid}/audit-scope-selection` 
                : `/${repo.auditUuid}/audit_summary`;
                        
            navigate(path, { replace: true });
        } catch (error) {
            console.error('Error during access check:', error);
            navigate('/error');
        }
    };

    const calculateProgress = (auditUuid) => {
        const status = auditStatuses[auditUuid]; // Fetch status from the centralized state
        if (status === "Audit started" && !showProgressBar[auditUuid]) {
            // Initially set progress to 0% when status changes to "Audit started"
            return 0;
        }
        return auditStatusToProgress[status] || 0;
    };

    const getMenuItems = (repo, auditStarted) => {
        const targetStatuses = ["Opening", "Audit session created", "Repository structure imported", "File information imported"];
        const isTargetStatus = targetStatuses.includes(repo.auditStatus);
        const buttonText = isTargetStatus ? "Adapt Scope" : "See Results";
        
        return [
            {
                label: buttonText,
                icon: 'pi pi-file',
                command: () => handleButtonClick(repo),
                disabled: auditStarted && isTargetStatus
            },
            ...(isTargetStatus ? [{
                label: 'Delete',
                icon: 'pi pi-trash',
                className: 'p-error',
                command: () => showDeleteDialog(repo.auditUuid),
                disabled: auditStarted
            }] : [])
        ];
    };

    const [auditDetails, setAuditDetails] = useState({});

    const fetchAuditDetails = async (auditUuid) => {
        try {
            const response = await axiosInstance.get(`audit_details/${auditUuid}/`);
            return {
                file_count: response.data.file_count,
                overall_quality_score: response.data.overall_quality_score,
                overall_functionality_score: response.data.overall_functionality_score,
                overall_security_score: response.data.overall_security_score,
                overall_architecture_score: response.data.overall_architecture_score,
                overall_documentation_score: response.data.overall_documentation_score,
                overall_standards_score: response.data.overall_standards_score
            };
        } catch (error) {
            console.error('Error fetching audit details:', error);
            return {
                file_count: 0,
                overall_quality_score: 0,
                overall_functionality_score: 0,
                overall_security_score: 0,
                overall_architecture_score: 0,
                overall_documentation_score: 0,
                overall_standards_score: 0
            };
        }
    };

    useEffect(() => {
        const fetchDetailsForCompletedAudits = async () => {
            const newAuditDetails = { ...auditDetails };
            let detailsChanged = false;

            for (const repo of repositories) {
                if (auditStatuses[repo.auditUuid] === "Audit completed" && !auditDetails[repo.auditUuid]) {
                    const details = await fetchAuditDetails(repo.auditUuid);
                    newAuditDetails[repo.auditUuid] = details;
                    detailsChanged = true;
                }
            }

            if (detailsChanged) {
                setAuditDetails(newAuditDetails);
            }
        };

        fetchDetailsForCompletedAudits();
    }, [repositories, auditStatuses]);

    if (isLoading) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ProgressSpinner style={{marginTop: '3rem', with: '50px', height: '50px'}}/></div>;
    }

    if (error) {
        return <div style={{marginLeft: '50px'}}>Error loading repositories: {error}</div>;
    }

    const renderRepositories = () => {
        const sortedRepositories = repositories.sort((a, b) => new Date(b.aiSynthesis) - new Date(a.aiSynthesis));
    
        return sortedRepositories.map((repo, index) => {
            const targetStatuses = ["Opening", "Audit session created", "Repository structure imported", "File information imported"];
            const isTargetStatus = targetStatuses.includes(repo.auditStatus);
            const buttonText = isTargetStatus ? "Adapt Scope" : "See Results";
            const menuRef = menuRefs.current[repo.auditUuid];
            
            // Get the audit details for this repository
            const details = auditDetails[repo.auditUuid] || {};
            
            const scoreMetrics = [
                { key: 'overall_quality_score', score: details.overall_quality_score },
                { key: 'overall_functionality_score', score: details.overall_functionality_score },
                { key: 'overall_security_score', score: details.overall_security_score },
                { key: 'overall_architecture_score', score: details.overall_architecture_score },
                { key: 'overall_documentation_score', score: details.overall_documentation_score },
                { key: 'overall_standards_score', score: details.overall_standards_score }
            ];
            
            return (
                <div key={index} className={`repo-container ${index !== sortedRepositories.length - 1 ? 'repo-border' : ''}`}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                <img src={getDomainLogo(repo.url)} alt="Repo Logo" className="repo-logo" />
                                <div className="repo-info">
                                    <h2 className="repo-name">{repo.repoName.replace(/-|_/g, " ")}</h2>
                                    <a href={repo.url} target="_blank" rel="noopener noreferrer" className="repo-url-in-repo-card" style={{ color: '#8D8D8D' }}>{repo.url}</a>
                                    <p style={{color: "#8D8D8D", fontSize: "16px"}}>{`${repo.numberFiles ? repo.numberFiles.toLocaleString('de-DE') : '0'} files with ${repo.linesOfCode ? repo.linesOfCode.toLocaleString('de-DE') : '0'} lines of code`}</p>
                                    <p style={{color: "#2CB392", fontSize: "14px"}}>Status: {auditStatuses[repo.auditUuid] || 'Fetching status...'}</p>
                                </div>
                            </div>
                            <div className="repo-actions">
                                <div className="desktop-actions">
                                    <Button 
                                        label={buttonText} 
                                        className="p-button-text" 
                                        onClick={() => handleButtonClick(repo)} 
                                        style={{ minWidth: '120px' }}
                                        disabled={auditStarted && isTargetStatus}
                                    />
                                    {isTargetStatus && (
                                        <Button 
                                            icon="pi pi-trash" 
                                            style={{
                                                border: "1px solid #E83636",
                                                color: "#E83636",
                                                width: "50px"
                                            }}
                                            className="p-button-outlined p-button-danger" 
                                            onClick={() => showDeleteDialog(repo.auditUuid)} 
                                            disabled={auditStarted}
                                        />
                                    )}
                                </div>
                                <div className="mobile-actions">
                                    <TieredMenu 
                                        model={getMenuItems(repo, auditStarted)}
                                        popup 
                                        ref={menuRef}
                                    />
                                    <Button 
                                        icon="pi pi-bars" 
                                        className="p-button-text mobile-menu-button" 
                                        onClick={(e) => menuRef?.current?.toggle(e)} 
                                        disabled={auditStarted && isTargetStatus}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Add score visualization if audit is completed */}
                        {auditStatuses[repo.auditUuid] === "Audit completed" && auditDetails[repo.auditUuid] && (
                            <div className="audit-scores-grid" style={{ marginTop: '1rem' }}>
                                {scoreMetrics.map(({ key, score }) => (
                                    <AuditScoreBar
                                        key={`${repo.auditUuid}-${key}`}
                                        title={key}
                                        score={score}
                                        auditId={repo.auditUuid}
                                    />
                                ))}
                            </div>
                        )}
                        
                        {showProgressBar[repo.auditUuid] && (
                            <ProgressBar 
                                className={`repo-card-progress-bar ${calculateProgress(repo.auditUuid) === 100 ? 'complete' : ''}`} 
                                style={{height: '10px'}} 
                                value={calculateProgress(repo.auditUuid)} 
                                displayValueTemplate={(value) => `${value}% Complete`} 
                            />
                        )}
                    </div>
                </div>
            );
        });
    };
    
    const deleteRepo = async () => {
        setDeleting(true);
        try {
            await axiosInstance.post(`delete_repo_from_scope/`, { audit_uuid: selectedAuditUuid });
            // Call fetchRepositories from the parent component to refresh the list
            fetchRepositories();
        } catch (error) {
            console.error("Error deleting repository:", error);
            // Handle error

        } finally {
            setDeleting(false);
            setDeleteDialogVisible(false); // Close the dialog
        }
    };

    const showDeleteDialog = (auditUuid) => {
        setSelectedAuditUuid(auditUuid);
        setDeleteDialogVisible(true);
    };

    const deleteConfirmationDialog = (
        <Dialog className="delete-repo-from-list" header="Confirm Delete" visible={deleteDialogVisible} style={{ minWidth: '350px', width:'30%' }} modal onHide={() => setDeleteDialogVisible(false)} footer={
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                <Button label="Cancel" onClick={() => setDeleteDialogVisible(false)} className="p-button-text" disabled={deleting} style={{ marginLeft: '20px'}} />
                <Button label="Delete" onClick={deleteRepo} className="delete-audit-button" disabled={deleting}/>
            </div>
        }>
            {deleting ? <ProgressSpinner /> : <p style={{paddingLeft: '20px', paddingTop: '10px', paddingBottom: '20px'}}>Are you sure you want to delete this repository from auditing?</p>}
        </Dialog>
    );

    return (
        <Card className="card-container">
            <h2 className="audit-list-title">Audit List</h2>
            <p className="repositories-count"><strong style={{color: "#8D8D8D"}}>{repositories.length}</strong> repositories added for auditing</p>
            {repositories.length === 0 && (
                <>
                    <p className="audit-list-empty">Audit list is empty.</p>
                    <p className="audit-list-action-required">
                        {userRole === 'inviter' ? "The repository owner needs to add repositories for auditing." : "You need to add repositories for auditing."}
                    </p>
                </>
            )}
            {renderRepositories()}
            {deleteConfirmationDialog}
        </Card>
    );
};

export default RepositoryCard;
