import React from 'react';
import './Terms.css';

const Terms = () => {
  return (
    <div className="terms-page">
      <header className="terms-header">
        <h1 className="terms-logo">CodeDD</h1>
        <h2 className="terms-title">Terms of Use</h2>
        <p className="terms-date">Last updated August 26, 2024</p>
      </header>
      
      <div className="terms-container">
        <h3 class="terms-section-title">1. Definitions</h3>
        <p class="terms-paragraph">1.1. "<strong>Account</strong>" represents your authorization to log in to, access, and use the Service. An Account can be a member of any number of Organizations.</p>
        <p class="terms-paragraph">1.2. "<strong>Agreement</strong>" means, collectively, all the terms, conditions, and notices contained or referenced in these Terms of Service and all other applicable operating rules and policies.</p>
        <p class="terms-paragraph">1.3. "<strong>Customer</strong>," "<strong>user</strong>," "<strong>you</strong>," and "<strong>your</strong>" refer to the individual or entity that has accessed or is using the Service via an Account.</p>
        <p class="terms-paragraph">1.4. "<strong>Customer Data</strong>" means (i) any data you input into the Service, including without limitation any source code or other materials relating to your software projects and (ii) any materials or attachments that you provide in any technical support requests.</p>
        <p class="terms-paragraph">1.5. "<strong>Data Processing Addendum</strong>" means the CodeDD data processing addendum referenced in Section 7.</p>
        <p class="terms-paragraph">1.6. "<strong>Documentation</strong>" means the official user documentation prepared and provided by CodeDD to you on the use of the Service (as updated from time to time). For the avoidance of doubt, any online community site, unofficial documentation, videos, white papers, related media, or feedback do not constitute Documentation.</p>
        <p class="terms-paragraph">1.7. "<strong>Early Access Period</strong>" means the phase before the official release of a Service plan or feature, as indicated on the early access page of the Website.</p>
        <p class="terms-paragraph">1.8. "<strong>Enterprise Plan</strong>" means the license plan for the Service with features for enterprises that is defined on the Website as the "Enterprise Plan".</p>
        <p class="terms-paragraph">1.9. "<strong>Intellectual Property</strong>" means all rights to patents, inventions, copyright, and related rights, trademarks, business names, goodwill, designs, computer software, database rights, know-how, trade secrets, and all other intellectual property rights.</p>
        <p class="terms-paragraph">1.10. "<strong>Organization</strong>" means a shared group of projects that may be associated with a single entity or with one or more users. Multiple users can join an Organization to collaborate across projects.</p>
        <p class="terms-paragraph">1.11. "<strong>Party</strong>" means CodeDD or Customer individually, and "Parties" means CodeDD and Customer together.</p>
        <p class="terms-paragraph">1.12. "<strong>Personal Data</strong>" means any information relating to an identified or identifiable natural person, or which otherwise constitutes "personal data", "personal information", "personally identifiable information" or similar terms as defined in applicable data protection law.</p>
        <p class="terms-paragraph">1.13. "<strong>Private Project</strong>" means a project created within a paid Organization that only certain designated users will be able to access.</p>
        <p class="terms-paragraph">1.14. "<strong>Public Project</strong>" means a project that is visible to all users of the Service. Projects created within an unpaid Organization will automatically be Public Projects. Projects created within a paid Organization may be designated as Private Projects or Public Projects.</p>
        <p class="terms-paragraph">1.15. "<strong>Results Data</strong>" means the customer-specific results that are generated by the Service processing Customer Data and available to you via the Service (e.g., an application program interface or in report form).</p>
        <p class="terms-paragraph">1.16. "<strong>Service</strong>" means CodeDD's software-as-a-service application -- and any related applications, software, products, and services -- with "CodeDD" branding.</p>
        <p class="terms-paragraph">1.17. "<strong>CodeDD</strong>" means CodeDD e.U., a company registered in Austria with registered office at Wötzling 2, 3233 Kilb, Austria. </p>
        <p class="terms-paragraph">1.18. "<strong>Support</strong>" means access to CodeDD's online support offering, as described on the Website.</p>
        <p class="terms-paragraph">1.19. "<strong>Usage Data</strong>" means any data about your use of the Service excluding Results Data.</p>
        <p class="terms-paragraph">1.20. "<strong>Website</strong>" means CodeDD's website located at codedd.ai.</p>
        <h3 class="terms-section-title">2. License and Conditions of Use</h3>
        <p class="terms-paragraph">2.1. Upon payment of the applicable fees for the Service and subject to your continuous compliance with this Agreement, CodeDD hereby grants you a limited, worldwide, nonexclusive, nontransferable license to access and use (i) the Service and Documentation and (ii) Results Data, in each case solely for your own internal software development purposes, subject to the terms and conditions contained herein.</p>
        <p class="terms-paragraph">2.2. You may not:</p>
        <ul class="terms-list">
        <li>provide, make available to, or permit others to use or access your Account;</li>
        <li>copy, reproduce, republish, upload, post, or transmit the Service or Documentation;</li>
        <li>license, sell, resell, rent, lease, transfer, distribute, or otherwise transfer rights to the Service or Documentation unless as authorized in this Agreement;</li>
        <li>modify, translate, reverse engineer, decompile, disassemble, create derivative works, or otherwise attempt to derive the source code of the Service or Documentation;</li>
        <li>create, market, distribute add-ons or enhancements, or incorporate into another product the Service without prior written consent of CodeDD;</li>
        <li>remove any proprietary notices or labels on the Service or Documentation, unless authorized by CodeDD;</li>
        <li>use the Service to store or transmit infringing, libelous, unlawful, or tortious material, or to store or transmit material in violation of third party rights, including privacy rights;</li>
        <li>use the Service in a manner that violates any rights of others;</li>
        <li>use the Service to store or transmit malicious code, Trojan horses, malware, spam, viruses, or other destructive technology;</li>
        <li>interfere with, impair, or disrupt the integrity or performance of the Service or any other third party's use of the Service;</li>
        <li>access or use the Service in a manner that results in excessive use, bandwidth, or storage or harms the performance or security of the Service by, for example, making abusive or excessively frequent data requests;</li>
        <li>alter, circumvent, or provide the means to alter or circumvent the Service, including technical limitations, recurring fees, or usage limits;</li>
        <li>perform or disclose any performance or vulnerability testing, password cracking, or remote access testing of the Service without CodeDD's prior written approval;</li>
        <li>employ, use, or engage artificial intelligence technology that is not part of the Service to ingest, interpret, analyze, train on, or interact with the data provided by the Service, or to engage with the Service in any manner, without the prior written consent of CodeDD;</li>
        <li>enhance, augment, or improve data provided by the Service without the prior written consent of CodeDD;</li>
        <li>use Results Data unless the Customer Data relating to that Results Data is active in the Service;</li>
        <li>access or use the Service or Documentation (a) if you are a direct competitor of CodeDD or (b) in violation of applicable law;</li>
        <li>disclose, publish, or otherwise make publicly available any benchmark, comparative, or performance tests or evaluations on of the Service without the express written permission of CodeDD;</li>
        <li>perform, or direct any third party to perform, any benchmark, comparative, or performance tests or evaluations on the Service for competitive advantage.</li>
        </ul>
        <h3 class="terms-section-title">3. Support and Service Level Commitment</h3>
        <p class="terms-paragraph">If you purchased Support or are otherwise entitled to receive Support based on the license plan that you have purchased, CodeDD will provide Support in accordance with the Support terms set forth on the following page of the Website: <span class="terms-url">https://www.codedd.ai/suport-terms</span>. If you have purchased an Enterprise Plan, CodeDD will provide the Service in accordance with the Service Level Addendum set forth on the following page of the Website: https://www.codedd.ai/legal/codedd/service-level-agreement/. Notwithstanding the foregoing, Support and the Service Level Addendum are not applicable to Service plans and features released under an Early Access Period.</p>
        <h3 class="terms-section-title">4. Payment, No Refunds, Downgrading Organizations</h3>

        <p class="terms-paragraph">4.1. To create a paid Organization you must purchase a license subscription plan (i) via the Website with valid payment information or (ii) via an order accepted by CodeDD or an authorized third party as indicated on the Website.</p>

        <p class="terms-paragraph">4.2. You are not required to enter payment information for unpaid Organizations.</p>

        <p class="terms-paragraph">4.3. For paid Organizations, you will be billed in advance on a monthly or yearly basis depending on your subscription plan. All payments are non-refundable and CodeDD will not make any refunds or credits for partial months of service, refunds for upgrades/downgrades, or refunds for any time that the Service is unused.</p>

        <p class="terms-paragraph">4.4. In addition to applicable fees, you are responsible for payment of any applicable VAT or sales tax.</p>

        <p class="terms-paragraph">4.5. If you purchase a subscription plan via an order accepted by CodeDD, you must pay amounts due (plus any applicable VAT or sales tax) by an electronic funds transfer to be received in CodeDD's account within thirty (30) days of the date of the applicable invoice unless CodeDD has agreed otherwise in writing.</p>

        <p class="terms-paragraph">4.6. Downgrading from a paid Organization to a free Organization may cause a loss of Customer Data and features. CodeDD does not accept any liability for such losses.</p>

        <p class="terms-paragraph">4.7. You are solely responsible for properly downgrading your Organization. CodeDD will not honor email or phone requests to downgrade an Organization.</p>

        <h3 class="terms-section-title">5. Suspension and Termination</h3>

        <p class="terms-paragraph">5.1. You may terminate this Agreement at any time by fully and properly terminating your Account and any Organization for which you have administrative rights. If you terminate before the end of the current paid-up term, your termination will take effect immediately. Amounts paid will not be refundable and your obligation to pay amounts payable under an applicable order will not terminate.</p>

        <p class="terms-paragraph">5.2. CodeDD has the right to suspend any Account or Organization at any time for any lawful reason. For example, CodeDD may suspend an Account for failure to pay fees when due or that it reasonably suspects of being in violation of this Agreement.</p>

        <p class="terms-paragraph">5.3. CodeDD may terminate this Agreement upon thirty (30) days' prior written notice or immediately if:</p>

        <ul class="terms-list">
        <li>you become subject to bankruptcy or any other proceeding relating to insolvency, receivership, liquidation, or assignment for the benefit of creditors;</li>
        <li>you infringe or misappropriate CodeDD's Intellectual Property;</li>
        <li>you breach this Agreement, including failure to pay fees when due, and fail to cure such breach within (30) thirty days of receiving notice thereof;</li>
        <li>CodeDD receives a subpoena, court order, or other request by a law enforcement agency relating to your Account or Organization; or</li>
        <li>CodeDD is prohibited by law or otherwise restricted from providing you the Service.</li>
        </ul>

        <p class="terms-paragraph">5.4. Upon termination of your Account, Customer Data within your Account and any Organization for which you have administrative rights will be immediately unavailable. This information cannot be recovered once this Organization is terminated. You are solely responsible to all other users who may be accessing this Organization when you terminate it.</p>

        <h3 class="terms-section-title">6. Modifications to the Service</h3>

        <p class="terms-paragraph">CodeDD may make commercially reasonable updates or modifications to the Service from time to time to reflect changes in, among other things, laws, regulations, rules, technology, industry practices, patterns of system use, and availability of a third-party program. CodeDD will provide advance notice of any such material updates or modifications that it reasonably believes are likely to materially degrade core features or functionalities of the Service.</p>

        <h3 class="terms-section-title">7. Personal Information</h3>

        <p class="terms-paragraph">You acknowledge that any Personal Data you (or others acting on your behalf) provide for the purpose of performance of this Agreement will be processed in accordance with the CodeDD Privacy Statement and the Data Processing Addendum set forth on the following page of the Website: <span class="terms-url">https://www.codedd.ai/data-processing-addendum/</span>.</p>

        <h3 class="terms-section-title">8. Confidential Information</h3>

        <p class="terms-paragraph">"Confidential Information" means all non-public information, materials, documentation, or data, relating to a Party's business, which is disclosed by one Party ("Discloser"), or received by the other Party ("Recipient"), in connection with this Agreement, and which is clearly identified or marked as confidential or proprietary at the time of delivery to Recipient or which a reasonable person would understand to be confidential or proprietary. Confidential Information includes source code you provide for a Private Project and the pricing, discounts, or terms under which the Service is offered to you.</p>

        <p class="terms-paragraph">Recipient undertakes to:</p>

        <ul class="terms-list">
        <li>protect the confidentiality of the Confidential Information with at least the same degree of care as it applies to its own Confidential Information of a similar nature, but in no event less than a reasonable degree of care;</li>
        <li>only use Confidential Information for purposes consistent with its rights and obligations under this Agreement;</li>
        <li>not reverse engineer or decompile Confidential Information;</li>
        <li>not disclose Confidential Information to any third party other than its employees, consultants, vendors or advisors who have a need to know and who are bound by confidentiality and non-use obligations no less restrictive than those set forth herein.</li>
        </ul>

        <p class="terms-paragraph">Confidential Information shall not include any information which:</p>

        <ul class="terms-list">
        <li>Recipient already knew at the time of disclosure;</li>
        <li>is generally available to the public or becomes publicly known through no wrongful act of Recipient;</li>
        <li>Recipient received from a third-party who had a legal right to provide it; and/or</li>
        <li>Recipient developed independently of any knowledge of or access to any of Discloser's Confidential Information.</li>
        </ul>

        <p class="terms-paragraph">Either Party may disclose Confidential Information if required by law or regulatory authorities, provided that, so far as it is lawful to do so, Recipient gives prompt notice to Discloser, so that Discloser may contest the requirement to provide such information. Upon Discloser's written request, Recipient will return or destroy all Confidential Information in Recipient's possession within thirty (30) days of the request. Recipient may retain a limited number of electronic copies of the Confidential Information to comply with applicable law, and as may be automatically created, maintained, and destroyed by its standard backup processes and systems. Recipient will remain bound by its confidentiality obligations for any copies retained. For the avoidance of doubt, Public Projects, and any source code contained therein, are not Confidential Information.</p>

        <h3 class="terms-section-title">9. Security</h3>

        <p class="terms-paragraph">Both Parties acknowledge and agree that security is a shared responsibility. CodeDD has implemented physical, administrative, organizational, and technical information security measures. It will maintain security practices consistent with its Trust Center, as published on the Website. You are responsible for managing and maintaining the security of your Account, including but not limited to, safeguarding your Account credentials, implementing appropriate security measures to protect your Account from unauthorized access or use, and promptly notifying CodeDD of any known or suspected breach or unauthorized use of your Account.</p>

        <h3 class="terms-section-title">10. Intellectual Property and Data Rights</h3>

        <p class="terms-paragraph">10.1. Except for the limited license rights expressly granted by CodeDD to you in Section 2 above, all right, title, and interest in and to the Service, Documentation, Usage Data, and Results Data, including all Intellectual Property rights therein, belong exclusively to CodeDD and/or licensors. All rights not expressly granted under this Agreement are reserved by CodeDD.</p>

        <p class="terms-paragraph">10.2. As between you and CodeDD, all right, title, and interest in and to Customer Data, including all Intellectual Property rights therein, belong exclusively to you and you grant to CodeDD the right to internally use Customer Data to manage your Account, to provide you with the Service, Documentation, and Results Data, and for the limited purpose of enhancing services provided by CodeDD.</p>

        <p class="terms-paragraph">10.3. CodeDD is hereby granted a royalty-free, fully-paid, worldwide, exclusive, transferable, sub-licensable, irrevocable, and perpetual license to use or incorporate into its products and services any information, data, suggestions, enhancement requests, recommendations, or other feedback provided by you relating to the Service or Documentation.</p>

        <h3 class="terms-section-title">11. Indemnification</h3>

        <p class="terms-paragraph">11.1. CodeDD will defend you against any third-party claim that the Service, as provided by CodeDD, infringes or misappropriates a third party's Intellectual Property ("IP Claim") and will indemnify you against any damages finally awarded to the third party making the IP Claim, and all penalties, fines, and reasonable third-party costs (including reasonable attorneys' fees) paid by you to the extent arising out of an IP Claim (collectively, "IP Losses"). CodeDD's obligations under this Section 11.1 shall not apply to the extent an IP Claim is based on or arises from:</p>

        <ul class="terms-list">
        <li>a combination or use of the Service with hardware, software, or other materials not provided by CodeDD;</li>
        <li>the modification of the Service by anyone other than CodeDD or its authorized agents;</li>
        <li>the use of the Service not in accordance with its Documentation or this Agreement;</li>
        <li>your breach of this Agreement; or</li>
        <li>your negligence, fraud, or willful misconduct.</li>
        </ul>

        <p class="terms-paragraph">11.2. In the event of an IP Claim, CodeDD shall be entitled, at its own expense and option, to either:</p>

        <ul class="terms-list">
        <li>procure the right for you to continue utilizing the Service features at issue;</li>
        <li>modify the Service to render the Service non-infringing; or</li>
        <li>replace the Service with an equally suitable, functionally equivalent, compatible, non-infringing product.</li>
        </ul>

        <p class="terms-paragraph">CodeDD's obligation to defend and indemnify requires that:</p>

        <ul class="terms-list">
        <li>you give notice to CodeDD of any IP Claim immediately upon becoming aware of the same;</li>
        <li>you give CodeDD the sole right to conduct the defense of any claim or action, or the negotiation of any settlement, in respect of an IP Claim and does not at any time admit liability or otherwise settle or compromise or attempt to settle or compromise the IP Claim except upon the express written instructions of CodeDD; and</li>
        <li>you act in accordance with CodeDD's reasonable instructions and give CodeDD assistance as it shall reasonably require in respect of the conduct of the defense, including the filing of all pleadings and other court processes and the provision of all relevant documents.</li>
        </ul>

        <p class="terms-paragraph">Sections 11.1 and 11.2 set forth your sole and exclusive remedy from CodeDD for any IP Claim.</p>

        <p class="terms-paragraph">11.3. You agree to defend and indemnify CodeDD and its affiliates, and its directors, employees, and agents from any third-party claim arising out of or relating to: (i) Customer Data; or (ii) your use of the Service or Documentation in violation of this Agreement or applicable law.</p>

        <h3 class="terms-section-title">12. Disclaimer and Limitation of Liability</h3>

        <p class="terms-paragraph">12.1. EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN THIS AGREEMENT, THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE", WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO (I) ANY WARRANTY THAT THE SERVICE IS ERROR-FREE OR WILL OPERATE WITHOUT INTERRUPTION OR THAT ALL ERRORS WILL BE CORRECTED; (II) ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT; AND (III) ANY WARRANTY WITH RESPECT TO THIRD-PARTY PRODUCTS, SOFTWARE, OR SERVICES.</p>

        <p class="terms-paragraph">12.2. EXCEPT FOR A PARTY'S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT, AND REGARDLESS OF THE FORM OF ACTION, IN NO EVENT WILL EITHER PARTY (OR ITS OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS, MANAGERS, OR AGENTS) BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF REPUTATION, OR COSTS OF SUBSTITUTE SERVICES) ARISING OUT OF OR RELATING TO THIS AGREEMENT, THE SERVICE, OR THE DOCUMENTATION, EVEN IF SUCH PARTY IS ADVISED OF THE POSSIBILITY THEREOF OR IS NEGLIGENT.</p>

        <p class="terms-paragraph">12.3. TO THE MAXIMUM EXTENT PERMITTED BY LAW, CODEDD'S AGGREGATE LIABILITY ARISING OUT OF OR RELATING TO THIS AGREEMENT, THE SERVICE, OR THE DOCUMENTATION SHALL NOT TO EXCEED THE TOTAL AMOUNT PAID BY YOU TO CODEDD UNDER THIS AGREEMENT DURING THE TWELVE MONTHS PRIOR TO THE EVENT(S) GIVING RISE TO THE CLAIM.</p>

        <p class="terms-paragraph">12.4. NOTWITHSTANDING SECTION 12.3, CODEDD'S AGGREGATE LIABILITY FOR ANY IP LOSSES SHALL NOT EXCEED THREE TIMES (3X) THE FEES PAID BY YOU TO CODEDD UNDER THIS AGREEMENT DURING THE TWELVE MONTHS PRIOR TO THE EVENT(S) GIVING RISE TO THE IP CLAIM.</p>

        <h3 class="terms-section-title">13. Governing Law and Dispute Resolution</h3>

        <p class="terms-paragraph">This Agreement is governed by and construed in accordance with Swiss law. Any dispute, controversy, or claim arising under, out of, or relating to this Agreement shall be submitted to arbitration in accordance with the WIPO Expedited Arbitration Rules in effect at that date. The arbitral tribunal shall consist of a sole arbitrator. The seat of any mediation or arbitration shall be Geneva, Switzerland. The language to be used in any such proceedings shall be English. Notwithstanding the foregoing, any claim seeking solely preliminary, injunctive, or declaratory relief may be brought in a court of competent jurisdiction.</p>

        <h3 class="terms-section-title">14. Complimentary or Test Access</h3>

        <p class="terms-paragraph">You may receive access to the Service or certain features of the Service on a free, fully discounted, or trial basis, or as a beta or early access offering (collectively, "Complimentary or Test Access"). Use of Complimentary or Test Access is subject to this Agreement and any additional terms specified by CodeDD, such as an applicable scope and terms of use. At any time, CodeDD may terminate or modify your Complimentary or Test Access, the terms applicable to your Complimentary or Test Access, or the Service or certain features for which you have Complimentary or Test Access, all without any liability. Test features may be inoperable, incomplete or include errors and bugs or features that CodeDD may never release, and such features and their performance information are CodeDD's Confidential Information. NOTWITHSTANDING ANYTHING ELSE IN THIS AGREEMENT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, CODEDD PROVIDES NO WARRANTY, INDEMNITY, SERVICE LEVEL AGREEMENT, OR SUPPORT FOR COMPLIMENTARY OR TEST ACCESS, AND CODEDD'S AGGREGATE LIABILITY FOR COMPLIMENTARY OR TEST ACCESS IS LIMITED TO USD 250.</p>

        <h3 class="terms-section-title">15. Miscellaneous</h3>

        <p class="terms-paragraph">15.1. This Agreement constitutes the entire agreement between you and CodeDD relating to the Service and Documentation and supersedes all prior oral or written communications, proposals, conditions, representations, or warranties with respect to the subject matter hereof. Subject to the Data Processing Addendum, if other CodeDD terms or conditions conflict with this Agreement, this Agreement prevails and controls with respect to the Service and Documentation. Any and all additional or conflicting terms provided by you, whether in any price quotations, purchase orders, acknowledgments, or other communications between you and CodeDD shall be void and shall have no effect.</p>

        <p class="terms-paragraph">15.2. Except for an assignment pursuant to a merger, acquisition, or sale of a Party's business or assets, or any similar transaction regardless of form, neither Party may assign this Agreement without the other Party's written consent, such consent not to be unreasonably withheld, conditioned, or delayed. Any assignment in violation of this provision shall be void ab initio. This Agreement shall be binding upon each Party's successors and permitted assigns.</p>

        <p class="terms-paragraph">15.3. CodeDD may include your company name and/or logo in a list of its customers in marketing materials and on the Website, together with the names and logos of other CodeDD customers. You may revoke the foregoing right at any time by submitting a written request via e-mail to: contact@codedd.ai. CodeDD shall comply with such a termination or revocation request within twenty (20) business days from receipt of such notice.</p>

        <p class="terms-paragraph">15.4. You shall abide by all applicable export control laws, rules, and regulations applicable to the Service and Documentation. You agree that you are not located in or are not under the control of or a resident of any country, person, or entity prohibited from receiving the Service or Documentation due to export restrictions and that you will not export, re-export, transfer, or permit the use of the Service or Documentation, in whole or in part, to or in any of such countries or to any of such persons or entities.</p>

        <p class="terms-paragraph">15.5. Other than as expressly provided herein, this Agreement does not create any rights for any person who is not a Party to it, and no person not a Party to this Agreement may enforce any of its terms or rely on an exclusion or limitation contained in it.</p>

        <p class="terms-paragraph">15.6. The delay or failure of either Party to exercise any right provided in this Agreement shall not constitute its waiver of such right.</p>

        <p class="terms-paragraph">15.7. CodeDD will not be liable for any delay or failure to perform obligations under this Agreement due to any cause beyond its reasonable control, including labor disputes; industrial disturbances; systematic electrical, telecommunications, or other utility failures; earthquakes, storms, or other elements of nature; blockages; embargoes; riots; acts or orders of government; acts of terrorism; and war.</p>

        <p class="terms-paragraph">15.8. If any provision of this Agreement is held to be unenforceable, illegal, or void, that shall not affect the enforceability of the remaining provisions. The Parties further agree that the unenforceable provision(s) shall be deemed replaced by a provision(s) that is binding and enforceable and that differs as little as possible from the unenforceable provision(s), with considerations of the object and purpose of this Agreement.</p>

        <p class="terms-paragraph">15.9. Any notices to be provided under this Agreement should be sent by international courier service to the registered address of the Party, or to such other address as that Party may request in writing that notices be sent to. Notices may also be sent by e-mail if proof of receipt is obtained. E-mail notices to CodeDD must be sent to contact@codedd.ai.</p>

        <p class="terms-paragraph">15.10. CodeDD reserves the right to amend this Agreement at any time and will update this Agreement in the event of any such amendments. CodeDD will notify you of material changes to this Agreement at least 30 days prior to the change taking effect by posting a notice on the Website or sending an email to the primary email address specified in your Account. Your continued use of the Service after those 30 days constitutes agreement to the revisions of the Agreement.</p>

        <h3 class="terms-section-title">16. Large Language Model-Based Reports and Disclaimers</h3>

        <p class="terms-paragraph">16.1. You acknowledge and agree that the Service's audit reports and analyses are generated using state-of-the-art Large Language Models (LLMs). While these models represent cutting-edge technology, they may occasionally produce errors, inconsistencies, or inaccurate results. By using the Service, you explicitly acknowledge and accept:</p>

        <ul class="terms-list">
        <li>The inherent limitations and potential inaccuracies of LLM-based analysis</li>
        <li>That audit results should be reviewed critically and independently verified where appropriate</li>
        <li>That CodeDD makes no warranties regarding the absolute accuracy or completeness of LLM-generated analyses</li>
        </ul>

        <h3 class="terms-section-title">17. Investment Disclaimer</h3>

        <p class="terms-paragraph">17.1. The Service and its audit reports are strictly limited to technical analysis using Large Language Models. CodeDD explicitly disclaims:</p>

        <ul class="terms-list">
        <li>Any provision of investment advice or financial recommendations</li>
        <li>Any responsibility for investment decisions made based on audit results</li>
        <li>Any liability for financial losses or damages resulting from investments made in connection with audit findings</li>
        </ul>

        <p class="terms-paragraph">17.2. By using the Service, you expressly acknowledge and agree that:</p>

        <ul class="terms-list">
        <li>Audit reports are purely technical in nature and should not be construed as investment advice</li>
        <li>Any investment decisions made based on audit results are made at your own risk</li>
        <li>You waive any right to pursue legal action against CodeDD for financial losses or damages resulting from investment decisions made in connection with audit results</li>
        </ul>

        <h3 class="terms-section-title">18. Data Security and Source Code Protection</h3>

        <p class="terms-paragraph">18.1. CodeDD implements comprehensive security measures to protect Customer Data, particularly source code. These measures include:</p>

        <ul class="terms-list">
        <li>End-to-end encryption of source code</li>
        <li>Storage exclusively on European servers (IONOS)</li>
        <li>Implementation of industry-standard security practices and infrastructure</li>
        <li>Temporary storage limited to the duration of the audit process</li>
        <li>Permanent deletion of source code upon audit completion</li>
        </ul>

        <p class="terms-paragraph">18.2. Access to source code is strictly limited to:</p>

        <ul class="terms-list">
        <li>Essential CodeDD systems and processes</li>
        <li>Licensed Large Language Model providers (such as OpenAI or Anthropic) under commercial agreements</li>
        </ul>

        <p class="terms-paragraph">18.3. You acknowledge and agree that:</p>

        <ul class="terms-list">
        <li>CodeDD's security measures represent reasonable and appropriate protection of your source code</li>
        <li>In the event of a data breach at a Large Language Model provider, liability rests with that provider under their commercial license agreements</li>
        <li>You waive any right to pursue legal action against CodeDD for data loss or breach, provided CodeDD has maintained its standard security measures</li>
        </ul>

        <h3 class="terms-section-title">19. Additional Liability Limitations and Acknowledgments</h3>

        <p class="terms-paragraph">19.1. Smart Contract and Code Analysis Limitations:</p>
        
        <ul class="terms-list">
        <li>You acknowledge that no code analysis, including those performed by the Service, can guarantee the complete absence of vulnerabilities, bugs, or security issues</li>
        <li>The Service's analysis is limited to the code explicitly provided and cannot account for external dependencies, runtime conditions, or deployment environments</li>
        <li>CodeDD makes no guarantees about the security, functionality, or performance of any smart contracts or code analyzed by the Service</li>
        </ul>

        <p class="terms-paragraph">19.2. Third-Party Dependencies:</p>

        <ul class="terms-list">
        <li>The Service may utilize third-party services, APIs, or dependencies</li>
        <li>CodeDD is not liable for any damages or losses resulting from the failure, unavailability, or security breaches of these third-party services</li>
        <li>You acknowledge that third-party services may have their own terms of service and privacy policies</li>
        </ul>

        <p class="terms-paragraph">19.3. Time Sensitivity and Updates:</p>

        <ul class="terms-list">
        <li>Audit results and security analyses are time-sensitive and reflect the state of the code at the time of analysis</li>
        <li>You acknowledge that new vulnerabilities, attack vectors, or security issues may be discovered after the analysis is complete</li>
        <li>CodeDD is not responsible for notifying you of newly discovered vulnerabilities or security issues after the completion of an audit</li>
        </ul>

        <p class="terms-paragraph">19.4. Usage Restrictions:</p>

        <ul class="terms-list">
        <li>The Service is intended for use by qualified software developers and technical professionals</li>
        <li>You represent that you have the necessary technical expertise to evaluate and implement any recommendations provided by the Service</li>
        <li>CodeDD is not responsible for damages resulting from improper implementation of audit recommendations</li>
        </ul>

        <p class="terms-paragraph">19.5. Regulatory Compliance:</p>

        <ul class="terms-list">
        <li>You are solely responsible for ensuring that your use of the Service and implementation of its recommendations comply with all applicable laws and regulations</li>
        <li>CodeDD makes no representations about compliance with specific regulatory frameworks or standards</li>
        <li>You acknowledge that the Service does not provide legal or regulatory compliance advice</li>
        </ul>

        <p class="terms-paragraph">19.6. Force Majeure and Technical Limitations:</p>

        <ul class="terms-list">
        <li>CodeDD is not liable for any failure or delay in performing its obligations due to circumstances beyond its reasonable control</li>
        <li>You acknowledge that the Service may be subject to technical limitations, including but not limited to processing capacity, network latency, and model limitations</li>
        <li>CodeDD reserves the right to impose reasonable limitations on Service usage to maintain system stability and performance</li>
        </ul>

        <p class="terms-paragraph">19.7. Intellectual Property Rights:</p>

        <ul class="terms-list">
        <li>You warrant that you have all necessary rights and permissions to submit code for analysis</li>
        <li>You shall indemnify CodeDD against any claims arising from the violation of third-party intellectual property rights</li>
        <li>CodeDD retains all rights to its analysis methods, tools, and technologies</li>
        </ul>

        <p class="terms-paragraph">19.8. Modification of Service:</p>

        <ul class="terms-list">
        <li>CodeDD reserves the right to modify, suspend, or discontinue any aspect of the Service at any time</li>
        <li>Changes to the Service may affect the availability or accuracy of previous analyses</li>
        <li>CodeDD will make reasonable efforts to notify users of material changes but is not liable for any consequences of such changes</li>
        </ul>
            
    </div>
    </div>
  );
};

export default Terms;