import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosConfig';

// Add cache duration constant
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Async thunk for checking audit status
export const checkAuditStatus = createAsyncThunk(
    'audits/checkStatus',
    async (auditUuid, { getState }) => {
        const state = getState();
        const cachedAudit = state.audits[auditUuid];
        const accountUuid = state.user.accountUuid;  // Get from Redux state
        const now = new Date().getTime();

        if (cachedAudit && 
            cachedAudit.lastChecked && 
            (now - new Date(cachedAudit.lastChecked).getTime()) < CACHE_DURATION) {
            return cachedAudit;
        }

        const response = await axiosInstance.get(
            `is_uuid_path_public/`,
            {
                params: { 
                    audit_uuid: auditUuid,
                    account_uuid: accountUuid  // Include account_uuid in params
                }
            }
        );

        const result = {
            uuid: auditUuid,
            isPublic: response.data.publicity,
            auditStatus: response.data.auditStatus,
            hasAccess: response.data.hasAccess,
            isManager: response.data.isManager,
            lastChecked: new Date().toISOString()
        };

        return result;
    }
);

const auditsSlice = createSlice({
    name: 'audits',
    initialState: {},
    reducers: {
        clearAuditData: (state, action) => {
            if (action.payload) {
                delete state[action.payload];
            } else {
                return {};
            }
        },
        invalidateAuditCache: (state, action) => {
            if (action.payload && state[action.payload]) {
                delete state[action.payload].lastChecked;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkAuditStatus.fulfilled, (state, action) => {
                state[action.payload.uuid] = action.payload;
            })
            .addCase(checkAuditStatus.rejected, (state, action) => {
                console.error('Failed to check audit status:', action.error);
            });
    }
});

// Export actions
export const { clearAuditData, invalidateAuditCache } = auditsSlice.actions;

// Memoized selectors
export const selectAudit = (state, auditUuid) => state.audits[auditUuid];

const selectAuditForAccess = (state, auditUuid) => {
    const audit = selectAudit(state, auditUuid);
    return audit || {};
};

export const selectAuditAccess = createSelector(
    [selectAuditForAccess],
    (audit) => {
        const access = {
            hasAccess: Boolean(audit.hasAccess),
            isManager: Boolean(audit.isManager),
            isPublic: Boolean(audit.isPublic),
            canAccess: Boolean(audit.isPublic || audit.hasAccess)
        };
        return access;
    }
);

export default auditsSlice.reducer; 