import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import StonehedgeLogo from '../../images/frontpage/stonehedge_logo.png';
import AliconaLogo from '../../images/frontpage/alicona-logo.png';
import ESALogo from '../../images/frontpage/European_Space_Agency_logo.svg';
import TomorrowsLogo from '../../images/frontpage/tomorrows-logo.png';
import NFXLogo from '../../images/frontpage/nfx-logo.png';
import ScienceParkLogo from '../../images/frontpage/sciencepark-logo.png';
import BCGLogo from '../../images/frontpage/bcg-logo.png';
import IndexLogo from '../../images/frontpage/indexventures-logo.png';
import './WhyCodedd.css';

const BackgroundNumbers = () => {
  const numbers = Array.from({ length: 4 }, (_, i) => String(i + 1).padStart(2, '0'));
  
  return (
    <div className="background-numbers">
      {numbers.map((number) => (
        <div 
          key={number} 
          className="floating-number"
        >
          {number}
        </div>
      ))}
    </div>
  );
};

const reasons = [
  {
    id: '01',
    title: 'Faster software audits',
    description: 'Transform your technical due diligence process with AI-powered analysis that delivers comprehensive insights within minutes. What traditionally takes weeks of manual review is now automated, allowing teams to focus on strategic decision-making rather than getting lost in code reviews.',
    quote: {
      text: 'CodeDD reduced our technical assessment time here at Stonehedge Flemming from weeks to hours while providing deeper insights than our traditional process. This serves us and our clients for their investment decisions.',
      author: 'Ari Tatos',
      role: 'Managing Partner, Stonehedge Flemming',
      companyLogo: StonehedgeLogo
    }
  },
  {
    id: '02',
    title: 'AI-powered',
    description: 'Leverage the most advanced AI models to analyze and understand your codebase. Our platform combines the power of large language models with specialized code analysis to provide deep insights and actionable recommendations that go beyond surface-level metrics.',
    quote: {
      text: 'The AI-driven insights helped us identify critical areas we would have missed in a traditional code review. CodeDD is a game changer for ESA Space Solutions and our InCubed Programme.',
      author: 'Giuseppe Borghi',
      role: 'Head of Φ-lab division, ESA Earth Observation Programme',
      companyLogo: ESALogo
    }
  },
  {
    id: '03',
    title: 'IP Protection',
    description: 'Your code stays completely private and secure - we take your Intelectual Property very seriously. We employ state-of-the-art encryption, ensure no code is ever stored permanently nor ever disclosed within the reports or to any third party, and never use your data for model training. Our zero-retention policy and isolated environments guarantee your intellectual property remains protected.',
    quote: {
      text: 'Security was our primary concern. CodeDD\'s approach to IP protection gave us the confidence to proceed with the technical assessment. It also helped us assess our codebase better and improve our code quality.',
      author: 'Dr. Thomas Funke',
      role: 'Co-Founder & CO-CEO, Tomorrows University of Applied Sciences',
      companyLogo: TomorrowsLogo
    }
  },
  {
    id: '04',
    title: 'No DD Overheads',
    description: 'Eliminate the complexity from technical due diligence. With just a few clicks, you can initiate a comprehensive code audit that automatically analyzes your entire codebase. No more lengthy setup processes or back-and-forth communications - just instant, actionable insights.',
    quote: {
      text: 'The simplicity of CodeDD\'s platform allowed us to focus on evaluating results rather than managing the DD process.',
      author: 'James Mitchell',
      role: 'Principal, NFX',
      companyLogo: NFXLogo
    }
  },
  {
    id: '05',
    title: 'Full-Spectrum Review',
    description: 'Move beyond selective sampling to comprehensive analysis. Every line of code is reviewed and contextualized, providing a complete picture of your codebase. This thorough approach ensures no critical aspects are missed, unlike traditional manual reviews that can only cover a fraction of the code.',
    quote: {
      text: 'The comprehensive nature of CodeDD\'s analysis gave us confidence that we weren\'t missing any critical technical aspects.',
      author: 'Dr. Manfred Prantl',
      role: 'Business Angel, former CTO of Alicona',
      companyLogo: AliconaLogo
    }
  },
  {
    id: '06',
    title: 'Unbiased Analysis',
    description: 'Get objective insights free from human bias. Our AI-powered platform evaluates code based on established best practices and industry standards, not personal preferences. Whether you\'re using React or Vue, Python or Go, you\'ll receive fair, consistent, and actionable analysis.',
    quote: {
      text: 'The unbiased nature of CodeDD\'s analysis helped us evaluate multiple tech stacks objectively, regardless of our team\'s and external advisors personal preferences.',
      author: 'Jeremija Hranjec',
      role: 'Senior Project Manager, Science Park Graz',
      companyLogo: ScienceParkLogo
    }
  },
  {
    id: '07',
    title: 'Contextual Insights',
    description: 'Understand entire codebases in the context of modern technology landscape. Our platform doesn\'t just analyze code - it provides insights about how your technology choices align with current best practices, industry trends, and emerging standards.',
    quote: {
      text: 'CodeDD helped us understand not just the code quality, but how our technology choices positioned us in the market.',
      author: 'Hans-Michael Hauser',
      role: 'Ex.Partner and Managing Director, Boston Consulting Group',
      companyLogo: BCGLogo
    }
  },
  {
    id: '08',
    title: 'Seamless Sharing',
    description: 'Share insights effortlessly with all stakeholders. Make reports public or private, add specific reviewers, and ensure everyone has access to the insights they need. Unlike traditional audits, both investors and startups get full visibility into the results, fostering transparency and collaborative improvement.',
    quote: {
      text: 'The ability to share and collaborate on the audit results streamlined our entire investment process. ',
      author: 'Susanne Harari',
      role: 'Investment Manager, Index Ventures',
      companyLogo: IndexLogo
    }
  }
];

const ReasonsSection = () => {
  const [activeReason, setActiveReason] = useState('01');

  useEffect(() => {
    const handleScroll = () => {
      const reasonElements = document.querySelectorAll('.reason-item');
      const contentContainer = document.querySelector('.reasons-content');
      const scrollPosition = contentContainer.scrollTop + (contentContainer.clientHeight / 3);

      reasonElements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        const elementTop = element.offsetTop;
        const elementBottom = elementTop + rect.height;

        if (scrollPosition >= elementTop && scrollPosition < elementBottom) {
          setActiveReason(element.id);
        }
      });
    };

    const contentContainer = document.querySelector('.reasons-content');
    contentContainer.addEventListener('scroll', handleScroll);
    return () => contentContainer.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavClick = (e, reasonId) => {
    e.preventDefault();
    const contentContainer = document.querySelector('.reasons-content');
    const element = document.getElementById(reasonId);
    
    if (element && contentContainer) {
      contentContainer.scrollTo({
        top: element.offsetTop - 80, // Adjust for top padding
        behavior: 'smooth'
      });
      setActiveReason(reasonId);
    }
  };

  return (
    <section className="reasons-section">
      <div className="reasons-container">
        <div className="reasons-nav-wrapper">
          <nav className="reasons-nav">
            <ul className="reasons-nav-list">
              {reasons.map((reason) => (
                <li 
                  key={reason.id}
                  className={`reason-nav-item ${activeReason === reason.id ? 'active' : ''}`}
                >
                  <a 
                    href={`#${reason.id}`}
                    onClick={(e) => handleNavClick(e, reason.id)}
                  >
                    <span className="reason-number">{reason.id}</span>
                    <span>{reason.title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="reasons-content">
          {reasons.map((reason) => (
            <div 
              key={reason.id}
              id={reason.id}
              className="reason-item"
            >
              <div className="reason-number">{reason.id}</div>
              <h2>{reason.title}</h2>
              <p>{reason.description}</p>
              {reason.quote && (
                <div className="quote">
                  <div className="quote-company-logo">
                    <img src={reason.quote.companyLogo} alt="Company logo" />
                  </div>
                  <p className="quote-text" style={{ marginTop: '2rem' }}>{reason.quote.text}</p>
                  <p className="quote-author" style={{ marginTop: '1rem' }}>{reason.quote.author}</p>
                  <p className="quote-role" style={{ fontStyle: 'italic', fontSize: '0.9rem' }}>{reason.quote.role}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const WhyCodeddHero = () => {
  const navigate = useNavigate();

  const handleStartTrial = () => {
    navigate('/register');
  };

  return (
    <div className="why-codedd-hero">
      <div className="why-codedd-hero-content">
        <div className="why-codedd-hero-text">
          <div className="hero-title">
            <div className="gradient-wrapper">
              <span className="hero-gradient-text">10 reasons why</span>
            </div>
          </div>
          <h1>Investors and Startups choose CodeDD</h1>
          <h4 className="hero-description">
            CodeDD is the easiest, most comprehensive and safest code auditing platform
          </h4>
        </div>
        
        <div className="hero-cta">
          <button 
            className="cta-primary-button"
            onClick={handleStartTrial}
          >
            Start your free trial
          </button>
        </div>

        <BackgroundNumbers />
      </div>
    </div>
  );
};

const WhyCodedd = () => {
  return (
    <div className="why-codedd-page">
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Why Choose CodeDD | AI-powered Code Auditing Platform</title>
        <meta name="title" content="Why Choose CodeDD | AI-powered Code Auditing Platform" />
        <meta name="description" content="Discover why investors and startups choose CodeDD for their technical due diligence. Experience the most comprehensive AI-powered code auditing platform." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.codedd.ai/why-codedd" />
        <meta property="og:title" content="Why Choose CodeDD | AI-powered Code Auditing Platform" />
        <meta property="og:description" content="Discover why investors and startups choose CodeDD for their technical due diligence. Experience the most comprehensive AI-powered code auditing platform." />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://www.codedd.ai/why-codedd" />
        <meta name="twitter:title" content="Why Choose CodeDD | AI-powered Code Auditing Platform" />
        <meta name="twitter:description" content="Discover why investors and startups choose CodeDD for their technical due diligence. Experience the most comprehensive AI-powered code auditing platform." />
      </Helmet>

      <WhyCodeddHero />
      <ReasonsSection />
    </div>
  );
};

export default WhyCodedd;
