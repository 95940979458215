import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import axiosInstance from '../axiosConfig';
import { useSelector } from 'react-redux';
import { selectAuth } from '../features/user/userSlice';
import AdminTabMenu from './components/TabMenu';
import UserStatisticsGraph from './components/graphs/UserStatisticsGraph';

import './AdminDashboard.css';

const AdminPanel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [userStatistics, setUserStatistics] = useState(null);
    const [timePeriod, setTimePeriod] = useState('1M');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { accountUuid } = useSelector(selectAuth);

    useEffect(() => {
        fetchUserStatistics();
    }, [timePeriod, accountUuid]);

    const fetchUserStatistics = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosInstance.get(`get_users_statistics`, {
                params: { time_period: timePeriod },
            });
            setUserStatistics(response.data);
        } catch (error) {
            console.error('Error fetching user statistics:', error);
            setError('Failed to fetch user statistics. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const formatUserChartData = () => {
        if (!userStatistics) return null;
    
        const labels = userStatistics.new_users.map(item => item.date);
    
        return {
            labels: labels,
            datasets: [
                {
                    label: 'Total Users',
                    data: userStatistics.new_users.map(item => ({
                        date: item.date,
                        count: item.cumulative_count
                    })),
                    borderColor: '#36A2EB',
                },
                {
                    label: 'Active Users',
                    data: userStatistics.active_users.map(item => ({
                        date: item.date,
                        count: item.cumulative_count
                    })),
                    borderColor: '#FF6384',
                }
            ],
        };
    };

    const renderDashboard = () => (
        <div className="dashboard-grid">
            <div className="grid-item">
                <Card title="User Statistics">
                    <div className="time-period-buttons">
                        <Button label="1M" onClick={() => setTimePeriod('1M')} className={timePeriod === '1M' ? 'p-button-outlined' : ''} />
                        <Button label="3M" onClick={() => setTimePeriod('3M')} className={timePeriod === '3M' ? 'p-button-outlined' : ''} />
                        <Button label="6M" onClick={() => setTimePeriod('6M')} className={timePeriod === '6M' ? 'p-button-outlined' : ''} />
                        <Button label="1Y" onClick={() => setTimePeriod('1Y')} className={timePeriod === '1Y' ? 'p-button-outlined' : ''} />
                        <Button label="All" onClick={() => setTimePeriod('ALL')} className={timePeriod === 'ALL' ? 'p-button-outlined' : ''} />
                    </div>
                    {loading ? (
                        <ProgressSpinner />
                    ) : error ? (
                        <div className="error-message">{error}</div>
                    ) : (
                        <UserStatisticsGraph 
                            data={formatUserChartData()} 
                            timePeriod={timePeriod} 
                            aggregation={userStatistics?.aggregation} 
                        />
                    )}
                </Card>
            </div>
            {/* Add more dashboard items here */}
        </div>
    );

    const renderContent = () => {
        switch (activeIndex) {
            case 0:
                return renderDashboard();
            case 1:
                return <h2>Users Management</h2>;
            case 2:
                return <h2>Audits Management</h2>;
            case 3:
                return <h2>Operations Management</h2>;
            default:
                return null;
        }
    };

    return (
        <div className="admin-panel">
            <AdminTabMenu activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
            <div className="content-section">
                {renderContent()}
            </div>
        </div>
    );
};

export default AdminPanel;